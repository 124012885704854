import { flow, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { Bid, BidInstance } from './Bid';

const BidArray = t.array(Bid);

export const BidsStore = t
  .model('BidsStore', {
    next_page: t.maybeNull(t.string),
    bids: t.maybeNull(BidArray),
    bidsSearch: '',
  })
  .views((self) => ({
    get auctionsQuery(): URLSearchParams {
      return new URLSearchParams(self.bidsSearch);
    },
  }))
  .views((self) => ({
    get hasMore(): boolean {
      return self.next_page != null;
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchBids: flow(function* (id: string, nextPage = false) {
        nextPage = nextPage && self.next_page != null;

        const promise = request({
          method: 'GET',
          url: nextPage ? self.next_page! : `/api/auctions/${id}/bids`,
        });

        const { data } = yield promise;
        const bids: BidInstance[] = data.results;

        if (self.bids === null) {
          self.bids = BidArray.create();
        }

        self.next_page = data.next;

        if (nextPage) {
          bids.forEach((service_station) => {
            self.bids!.push(service_station);
          });
        } else {
          self.bids.replace(bids);
        }
      }),

      deleteBid: flow(function* (id: string, bidId: string) {
        const promise = request({
          method: 'DELETE',
          url: `/api/auctions/${id}/bids/${bidId}`,
        });

        yield promise;
      }),

      selectBidAsWinner: flow(function* (id: string, bidId: string) {
        const promise = request({
          method: 'POST',
          url: `/api/auctions/${id}/bids/${bidId}/select-winner`,
        });

        yield promise;
      }),
    };
  })
  .actions((self) => ({
    handleBidsSearchChange: (search: string) => {
      self.bidsSearch = search;
    },
  }));
