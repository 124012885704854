import { Instance, types as t } from 'mobx-state-tree';
import { IsoDate } from '../types';

export const Bid = t.model('Bid', {
  id: t.identifier,
  bid: t.number,
  created_at: IsoDate,
  service_station_name: t.string,
  is_winning_bid: t.boolean,
});

export type BidInstance = Instance<typeof Bid>;
