import React, { FC, memo, SVGProps } from 'react';

export const UserIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5138 16.3501C6.90376 16.3501 5.21976 15.8681 4.56676 15.6051C4.73476 14.2221 5.42576 10.7571 8.08376 9.46612C8.81476 10.0431 9.71676 10.4001 10.7068 10.4001C11.6218 10.4001 12.4688 10.1031 13.1688 9.60312C15.6258 10.9771 16.2958 14.2501 16.4598 15.5971C15.7768 15.8761 14.0908 16.3501 10.5138 16.3501ZM10.7068 3.50012C11.9928 3.50012 13.0378 4.59912 13.0378 5.95012C13.0378 7.30112 11.9928 8.40012 10.7068 8.40012C9.42176 8.40012 8.37576 7.30112 8.37576 5.95012C8.37576 4.59912 9.42176 3.50012 10.7068 3.50012ZM18.5128 16.1171C18.5048 15.8711 18.2778 10.4431 14.5068 8.04012C14.8348 7.41412 15.0378 6.70912 15.0378 5.95012C15.0378 3.49612 13.0948 1.50012 10.7068 1.50012C8.31976 1.50012 6.37576 3.49612 6.37576 5.95012C6.37576 6.64212 6.54376 7.29012 6.81976 7.87512C2.77076 10.1781 2.52276 15.8651 2.51476 16.1171L2.49976 16.5521L2.80876 16.8591C3.15976 17.2091 4.75576 18.3501 10.5138 18.3501C16.2718 18.3501 17.8678 17.2091 18.2188 16.8591L18.5278 16.5521L18.5128 16.1171Z"
      fill="inherit"
    />
  </svg>
));
