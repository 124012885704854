import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Breadcrumbs, Select } from '../components';
import { useStore } from '../hooks';

export const ContactPage = observer(() => {
  const {
    companyStore,
    userStore: { me },
  } = useStore();
  const { company, companyOptions, companies } = companyStore;

  const state = useLocalObservable<{
    company: any;
  }>(() => ({
    company: null,
  }));

  useEffect(() => {
    state.company = company;
  }, [company]);

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
  ];

  if (company === null || me === null || state.company === null) return null;

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} active="Kontakta oss" />
      <h1 className="display-2 mt-15">Kontakta oss</h1>
      <div className="pt-2">
        {me.is_superuser && (
          <div className="bg-white p-15 mb-2">
            <div className="row">
              <div className="col">
                {companyOptions.length > 0 && (
                  <Select
                    label="Bolag"
                    options={[...companyOptions]}
                    defaultValue={company.id}
                    onChange={(e) => {
                      state.company = companies.find(
                        (company) => company.id === e.currentTarget.value,
                      );
                    }}
                    withoutMargin
                  />
                )}
              </div>
              <div className="col"></div>
            </div>
          </div>
        )}
        <div className="col col-5 bg-white p-15 mb-15 mr-2">
          <h2 className="display-4 mb-15">{state.company.name}</h2>
          <div className="d-flex flex-column">
            <div className="contact-data">
              {state.company.email && (
                <div className="py-05">
                  <p className="font-weight-bolder font-sans-serif m-0">
                    E-postadress
                  </p>
                  <p className="m-0">{state.company.email}</p>
                </div>
              )}
              {state.company.phone && (
                <div className="py-05">
                  <p className="font-weight-bolder font-sans-serif m-0">
                    Telefon
                  </p>
                  <p className="m-0">{state.company.phone}</p>
                </div>
              )}
              {state.company.street &&
                state.company.postal &&
                state.company.city && (
                  <div className="w-100 py-05">
                    <p className="font-weight-bolder font-sans-serif m-0">
                      Postadress
                    </p>
                    <p className="m-0">{state.company.street}</p>
                    <p className="m-0">
                      {state.company.postal} {state.company.city}
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
