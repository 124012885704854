import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../../hooks';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, PrimaryButton, SecondaryButton } from '../../components';
import { LoginWrapper } from './components/LoginWrapper';
import { LoginWarning } from './components/LoginWarning';
import { isAxiosError } from 'axios';

type FormData = {
  email: string;
  password: string;
};

export const LoginPage = observer(() => {
  const navigate = useNavigate();

  const { state: locationState } = useLocation();
  const { authStore } = useStore();

  const state = useLocalObservable(() => ({
    isSubmiting: false,
    hasFormError: false,
    isRedirectingAdfs: false,
    hasAdfsRedirectError: false,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const redirectAdfsSignIn = async () => {
    if (state.isRedirectingAdfs) return;

    state.isRedirectingAdfs = true;
    state.hasAdfsRedirectError = false;

    try {
      await authStore.fetchAdfsUrl();
      if (authStore.adfs_url) {
        window.location.href = authStore.adfs_url;
      } else {
        state.hasAdfsRedirectError = true;
      }
    } catch (error) {
      state.hasAdfsRedirectError = true;
    } finally {
      state.isRedirectingAdfs = false;
    }
  };

  const onSubmit = handleSubmit(async ({ email, password }) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;
    state.hasFormError = false;

    try {
      await authStore.initializeTwoFactorLogin(email, password);
      navigate('/2fa');
    } catch (error) {
      if (isAxiosError(error) && error.response?.data.code === 'is_ad_user') {
        await redirectAdfsSignIn();
      } else {
        state.hasFormError = true;
      }
    } finally {
      state.isSubmiting = false;
    }
  });

  return (
    <LoginWrapper>
      <form onSubmit={onSubmit}>
        <div className="d-flex flex-column">
          <Input
            type="text"
            placeholder="E-postadress"
            {...register('email', {
              required: 'Fyll i detta fältet',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Ange en giltig e-post',
              },
            })}
            error={errors.email?.message}
          />
          <Input
            type="password"
            placeholder="Lösenord"
            {...register('password', { required: true })}
            error={errors.password?.message}
          />
          {locationState?.errorData?.detail && (
            <div className="alert alert-danger" role="alert">
            <p className="alert-content">
              {locationState.errorData.detail}
            </p>
          </div>
          )}
          {state.hasFormError && (
            <div className="alert alert-danger" role="alert">
              <p className="alert-content">
                Vi känner tyvärr inte igen den här kombinationen av e-post och
                lösenord. Var vänlig försök igen.
              </p>
            </div>
          )}
          <LoginWarning />
          <span className="login-terms pb-1">
            Genom att logga in på lfrest.se godkänner du dessa{' '}
            <Link to="/terms/terms-of-use">Användarvillkor</Link>. Genom att
            lägga bud i en auktion godkänner du också dessa{' '}
            <Link to="/terms/purchase-conditions/">Allmänna köpvillkor</Link>.
          </span>
          <PrimaryButton loading={state.isSubmiting}>Fortsätt</PrimaryButton>
        </div>
      </form>
      <SecondaryButton
        className="w-100 mt-1"
        onClick={redirectAdfsSignIn}
        loading={state.isRedirectingAdfs}
      >
        Inloggning handläggare
      </SecondaryButton>
      <Link to="/forgot-password" className="text-sm text-center mt-1 d-block">
        Jag har glömt mitt lösenord.
      </Link>
    </LoginWrapper>
  );
});
