import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumbs,
  Checkbox,
  Input,
  PrimaryButton,
  Select,
  Spinner,
} from '../components';
import { useStore } from '../hooks';

export const ServiceStationsPage = observer(() => {
  const navigate = useNavigate();
  const {
    companyStore: { companyOptions, categoryOptions },
    serviceStationStore,
    userStore: { me },
    uiStore,
  } = useStore();

  const searchParams = new URLSearchParams(location.search);
  const { handleSubmit, register, reset } = useForm({
    values: {
      number: searchParams.get('number'),
      city: searchParams.get('city'),
      name: searchParams.get('name'),
      categories: searchParams.get('categories'),
      is_staging_area: searchParams.get('is_staging_area'),
    },
  });

  const handleFilter = handleSubmit((filters: any) => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(filters).forEach((filter: any) => {
      if (searchParams.has(filter)) {
        searchParams.delete(filter);
      }
      if (filters[filter]) {
        searchParams.append(filter, filters[filter]);
      }
    });

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    serviceStationStore.fetchServiceStations();
  });

  const state = useLocalObservable<{
    isFetchingMore: boolean;
    resetFilter: () => void;
  }>(() => ({
    isFetchingMore: false,
    resetFilter() {
      navigate(`${location.pathname}`, { replace: true });
      reset();
      serviceStationStore.fetchServiceStations();
    },
  }));

  const fetchMore = () => {
    if (state.isFetchingMore) return;

    state.isFetchingMore = true;
    serviceStationStore
      .fetchServiceStations(true)
      .then(() => (state.isFetchingMore = false));
  };

  useEffect(() => {
    serviceStationStore.fetchServiceStations();
  }, []);

  const { service_stations, hasMore } = serviceStationStore;

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
  ];

  if (service_stations === null || me === null) return null;

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} active="Verkstäder" />
      <h1 className="display-2 mt-15">Verkstäder</h1>
      <div className="pt-2">
        <div className="bg-white p-15 mb-15">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="text-blue">Ny verkstad som väntar?</h2>
              <p className="m-0">Det löser du snabbt och enkelt.</p>
            </div>
            <PrimaryButton
              onClick={() => navigate('/service-stations/new')}
              arrow
            >
              Lägg till verkstad
            </PrimaryButton>
          </div>
        </div>
        {me.is_superuser && (
          <div className="bg-white p-15 mb-025">
            <div className="row">
              <div className="col">
                <Select
                  label="Bolag"
                  options={[
                    { value: '', title: 'Alla bolag' },
                    ...companyOptions,
                  ]}
                  onChange={(e) =>
                    serviceStationStore.setCompany(e.target.value)
                  }
                  withoutMargin
                />
              </div>
              <div className="col"></div>
            </div>
          </div>
        )}
        <div className="d-flex align-items-center bg-white px-15 py-08 mb-025">
          <div
            role="button"
            className="d-inline-flex align-items-center text-blue"
            onClick={() => uiStore.toggleFilterOpen()}
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-08"
            >
              <rect width="18" height="2" fill="#005AA0" />
              <rect x="3" y="6" width="12" height="2" fill="#005AA0" />
              <rect x="6" y="12" width="6" height="2" fill="#005AA0" />
            </svg>
            Filtrera
          </div>
        </div>
        {uiStore.isFilterOpen && (
          <form onSubmit={handleFilter} className="bg-white p-15 mb-025">
            <div className="row">
              <div className="col col-6">
                <Input
                  label="Verkstadsnummer"
                  type="text"
                  {...register('number')}
                />
              </div>
              <div className="col col-6">
                <Input label="Ort" type="text" {...register('city')} />
              </div>
              <div className="col col-6">
                <Input
                  label="Verkstadsnamn"
                  type="text"
                  {...register('name')}
                />
              </div>
              <div className="col col-6">
                <Select
                  label="Kategori"
                  placeholder="Välj kategori"
                  options={categoryOptions}
                  {...register('categories')}
                />
              </div>
              <div className="col col-6">
                <Checkbox
                  label="Uppställningsplats"
                  {...register('is_staging_area')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col d-flex align-items-center text-blue">
                <a
                  role="button"
                  className="text-decoration-underline mr-15"
                  onClick={() => uiStore.toggleFilterOpen()}
                >
                  Stäng
                </a>
                <a
                  role="button"
                  className="text-decoration-underline"
                  onClick={() => state.resetFilter()}
                >
                  Rensa
                </a>
                <PrimaryButton className="ml-auto">Filtrera</PrimaryButton>
              </div>
            </div>
          </form>
        )}
        {service_stations.length !== 0 ? (
          <table className="table table-clickable">
            <thead>
              <tr>
                <th scope="col">Verkstadsnr</th>
                <th scope="col">Verkstadsnamn</th>
                <th scope="col">Adress</th>
                <th scope="col">Kategorier</th>
              </tr>
            </thead>
            <tbody>
              {service_stations.map((service_station, index) => (
                <tr
                  className="js-tablelink"
                  key={index}
                  onClick={() =>
                    navigate(`/service-stations/${service_station.id}`)
                  }
                >
                  <td>{service_station.number}</td>
                  <td>{service_station.name}</td>
                  <td>{service_station.singleLineAddress}</td>
                  <td>
                    {service_station.categories
                      .map((category) => category.name)
                      .join(', ')}
                  </td>
                </tr>
              ))}
            </tbody>
            {hasMore && (
              <tfoot>
                <tr>
                  <td className="bg-white text-center" colSpan={7} scope="row">
                    {state.isFetchingMore ? (
                      <Spinner size="tiny" />
                    ) : (
                      <span
                        className="load-more-link"
                        role="button"
                        onClick={fetchMore}
                      >
                        Visa fler
                      </span>
                    )}
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        ) : (
          <div className="bg-white p-15">
            <h2 className="text-blue">Inga verkstäder</h2>
            <p>Det verkar inte finnas några verkstäder att visa här.</p>
          </div>
        )}
      </div>
    </div>
  );
});
