import { applySnapshot, flow, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { Category } from './Category';
import { Company } from './Company';
import { StagingAreas } from './StagingAreas';

export const CompanyStore = t
  .model('CompanyStore', {
    categories: t.array(Category),
    staging_areas: t.array(StagingAreas),
    companies: t.array(Company),
    company: t.maybeNull(Company),
  })
  .views((self) => ({
    get categoryOptions(): { value: string; title: string }[] {
      return self.categories.map((category) => ({
        value: category.id,
        title: `${category.name}${category.is_global ? ' (Global)' : ''}`,
      }));
    },
    get companyOptions(): { value: string; title: string }[] {
      return self.companies.map((company) => ({
        value: company.id,
        title: company.name,
      }));
    },
    get stagingAreaOptions(): { value: string; title: string }[] {
      return self.staging_areas.map((staging_area) => ({
        value: staging_area.id,
        title: staging_area.name,
      }));
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchCategories: flow(function* () {
        try {
          const promise = request({
            method: 'GET',
            url: '/api/categories',
          });

          const { data } = yield promise;

          self.categories.replace(data);
        } catch (error) {
          console.error('Failed to fetch categories', error);
        }
      }),
      fetchStagingAreas: flow(function* () {
        try {
          const promise = request({
            method: 'GET',
            url: '/api/staging-areas',
          });

          const { data } = yield promise;

          self.staging_areas.replace(data);
        } catch (error) {
          console.error('Failed to fetch staging areas', error);
        }
      }),
      fetchCompanies: flow(function* () {
        try {
          const promise = request({
            method: 'GET',
            url: '/api/companies',
          });

          const { data } = yield promise;

          self.companies.replace(data);
        } catch (error) {
          console.error('Failed to fetch companies', error);
        }
      }),
      fetchCompany: flow(function* () {
        try {
          const promise = request({
            method: 'GET',
            url: '/api/company',
          });

          const { data } = yield promise;

          self.company = data;
        } catch (error) {
          console.error('Failed to fetch company', error);
        }
      }),

      updateCompany: flow(function* (fields: {
        auction_end_dt_incrementation?: number;
        bid_increment_intervals?: {
          min_price: number;
          max_price: number;
          increment: number;
        }[];
      }) {
        const promise = request({
          method: 'PATCH',
          url: '/api/company',
          data: fields,
        });

        const { data } = yield promise;

        self.company = data;
      }),
    };
  })
  .actions((self) => ({
    reset() {
      applySnapshot(self, {
        categories: [],
        staging_areas: [],
        companies: [],
        company: null,
      });
    },
  }));
