import React, { FC, memo, SVGProps } from 'react';

export const GearIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.002 18.6793C14.827 18.6793 13.871 17.7233 13.871 16.5483C13.871 15.3733 14.827 14.4173 16.002 14.4173C17.177 14.4173 18.133 15.3733 18.133 16.5483C18.133 17.7233 17.177 18.6793 16.002 18.6793ZM16.002 12.4173C13.724 12.4173 11.871 14.2703 11.871 16.5483C11.871 18.8263 13.724 20.6793 16.002 20.6793C18.28 20.6793 20.133 18.8263 20.133 16.5483C20.133 14.2703 18.28 12.4173 16.002 12.4173Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7871 18.9557C23.3911 20.0117 22.7811 21.0437 22.0221 21.9407L21.5671 22.4777L23.0611 25.0587L19.9421 26.8817L18.4391 24.2847L17.7241 24.4437C17.0921 24.5837 16.5771 24.6427 16.0021 24.6427C15.4001 24.6427 14.8141 24.5767 14.3061 24.4497L13.5761 24.2667L12.0611 26.8817L8.94312 25.0587L10.4531 22.4497L9.95312 21.9087C9.08612 20.9697 8.54212 20.0527 8.24012 19.0247L8.02912 18.3067H5.00012V14.7157H7.97312L8.21712 14.0667C8.61312 13.0107 9.22312 11.9787 9.98212 11.0817L10.4361 10.5437L8.94312 7.96366L12.0611 6.14066L13.5661 8.73866L14.2811 8.57966C14.9101 8.43866 15.4241 8.37966 16.0021 8.37966C16.6051 8.37966 17.1911 8.44666 17.6981 8.57366L18.4291 8.75666L19.9421 6.14066L23.0611 7.96366L21.5511 10.5727L22.0511 11.1137C22.9171 12.0527 23.4621 12.9697 23.7641 13.9977L23.9751 14.7157H27.0041V18.3067H24.0311L23.7871 18.9557ZM29.0041 20.3067V12.7157H25.4371C25.1061 11.8747 24.6401 11.0927 24.0091 10.3187L25.7901 7.24266L19.2181 3.39966L17.4331 6.48466C16.5181 6.34666 15.5441 6.33966 14.5741 6.48866L12.7861 3.39966L6.21412 7.24266L8.01112 10.3467C7.45412 11.0867 6.98012 11.8897 6.61012 12.7157H3.00012V20.3067H6.56712C6.89812 21.1477 7.36512 21.9307 7.99512 22.7037L6.21412 25.7807L12.7861 29.6227L14.5721 26.5387C15.4871 26.6767 16.4581 26.6847 17.4301 26.5337L19.2181 29.6227L25.7901 25.7807L23.9931 22.6757C24.5491 21.9357 25.0231 21.1337 25.3931 20.3067H29.0041Z"
      fill="inherit"
    />
  </svg>
));
