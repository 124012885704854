import React, {
  ChangeEventHandler,
  createRef,
  forwardRef,
  SelectHTMLAttributes,
  useEffect,
  useId,
  useLayoutEffect,
} from 'react';
// @ts-ignore
import { select } from '@lansforsakringar/components/select';
import classNames from 'classnames';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string | false;
  placeholder?: string;
  error?: string | false;
  options: {
    value: string;
    title: string;
  }[];
  selectedOptions?: string[];
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  withoutMargin?: boolean;
}

export const Select = forwardRef<HTMLSelectElement, Props>(
  (
    {
      label,
      placeholder,
      error,
      options,
      selectedOptions,
      onChange,
      withoutMargin,
      defaultValue,
      multiple,
      ...rest
    },
    ref,
  ) => {
    const id = useId();
    const selectRef = createRef<HTMLDivElement>();

    useEffect(() => select(selectRef.current));

    useLayoutEffect(() => {
      // @ts-ignore
      document.getElementById(id)!.onchange = (e) => onChange && onChange(e);
    }, []);

    return (
      <fieldset className={classNames({ 'has-danger': error })}>
        <div
          ref={selectRef}
          className={classNames('select', {
            'mb-1': !withoutMargin,
          })}
        >
          {label && (
            <label htmlFor={id} className="select-label">
              {label}
            </label>
          )}
          <select
            id={id}
            ref={ref}
            className="select-options"
            defaultValue={
              selectedOptions
                ? placeholder
                  ? ['', ...selectedOptions]
                  : selectedOptions
                : placeholder
                ? multiple
                  ? ['']
                  : ''
                : defaultValue
            }
            multiple={multiple}
            {...rest}
          >
            {placeholder && (
              <option value="" disabled hidden>
                {placeholder}
              </option>
            )}
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.title}
              </option>
            ))}
          </select>
          {error && <span className="form-control-feedback">{error}</span>}
        </div>
      </fieldset>
    );
  },
);
