import { flow, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { ServiceStation, ServiceStationInstance } from './ServiceStation';
import { ServiceStationUser } from './User';

const ServiceStationArray = t.array(ServiceStation);

export const ServiceStationStore = t
  .model('ServiceStationStore', {
    next_page: t.maybeNull(t.string),
    company: t.maybeNull(t.string),
    service_stations: t.maybeNull(ServiceStationArray),
    my_service_station: t.maybeNull(ServiceStation),
  })
  .views((self) => ({
    get hasMore(): boolean {
      return self.next_page != null;
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchServiceStations: flow(function* (nextPage = false) {
        const searchParams = new URLSearchParams(location.search);
        nextPage = nextPage && self.next_page != null;

        const promise = request({
          method: 'GET',
          url: nextPage
            ? self.next_page!
            : `/api/service-stations?${searchParams.toString()}`,
          params: self.company && {
            company: self.company,
          },
        });

        const { data } = yield promise;

        const service_stations: ServiceStationInstance[] = data.results;

        if (self.service_stations === null) {
          self.service_stations = ServiceStationArray.create();
        }

        self.next_page = data.next;

        if (nextPage) {
          service_stations.forEach((service_station) => {
            self.service_stations!.push(service_station);
          });
        } else {
          self.service_stations.replace(service_stations);
        }
      }),

      fetchServiceStation: flow(function* (id: string) {
        const promise = request({
          method: 'GET',
          url: `/api/service-stations/${id}`,
        });

        const { data } = yield promise;

        return ServiceStation.create(data);
      }),

      createServiceStation: flow(function* (fields: {
        name: string;
        street: string;
        postal: string;
        city: string;
        is_staging_area: string;
        category_ids: string[];
      }) {
        const promise = request({
          method: 'POST',
          url: `/api/service-stations`,
          data: fields,
        });

        const { data } = yield promise;

        return ServiceStation.create(data);
      }),

      createServiceStationUser: flow(function* (
        id: string,
        fields: {
          email: string;
          first_name: string;
          last_name: string;
        },
      ) {
        const promise = request({
          method: 'PATCH',
          url: `/api/service-stations/${id}/user`,
          data: fields,
        });

        const { data } = yield promise;

        return ServiceStationUser.create(data);
      }),

      updateServiceStation: flow(function* (
        id: string,
        fields: {
          name: string;
          street: string;
          postal: string;
          city: string;
          is_staging_area: string;
          category_ids: string[];
          user: {
            email: string;
            first_name: string;
            last_name: string;
          };
        },
      ) {
        yield request({
          method: 'PATCH',
          url: `/api/service-stations/${id}/user`,
          data: fields.user,
        });

        const promise = request({
          method: 'PATCH',
          url: `/api/service-stations/${id}`,
          data: (({ user, ...fields }) => fields)(fields),
        });

        const { data } = yield promise;

        return ServiceStation.create(data);
      }),

      updateServiceStationSilentCategories: flow(function* (
        silent_category_ids: string[],
      ) {
        const promise = request({
          method: 'PATCH',
          url: '/api/service-station',
          data: { silent_category_ids },
        });

        const { data } = yield promise;

        return ServiceStation.create(data);
      }),

      sendPasswordResetEmail: flow(function* (id: string) {
        yield request({
          method: 'POST',
          url: `/api/service-stations/${id}/user/reset-password`,
        });
      }),

      fetchMyServiceStation: flow(function* () {
        const promise = request({
          method: 'GET',
          url: `/api/service-station`,
        });

        const { data } = yield promise;

        self.my_service_station = data;
      }),
    };
  })
  .actions((self) => ({
    setCompany: (company: string) => {
      self.company = company;
      self.fetchServiceStations();
    },
  }));
