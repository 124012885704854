import React, { FC, memo, SVGProps } from 'react';

export const LogoWhite: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 218 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.9503 29.301C93.9503 30.1687 94.0082 30.2555 95.3966 30.6318V30.9786H89.814V30.6318C91.2313 30.2555 91.2602 30.1979 91.2602 29.301V22.8505C91.2602 21.9541 91.2313 21.896 89.814 21.5202V21.1729H93.9503V29.301Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.4959 21.1738H95.7149C94.8182 21.1738 94.7314 21.2317 94.7314 22.157V22.9383C94.7314 23.835 94.7893 23.9218 95.7149 23.9218H96.4959C97.3926 23.9218 97.4794 23.8639 97.4794 22.9383V22.157C97.4505 21.2317 97.3926 21.1738 96.4959 21.1738Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.471 29.3016V21.1735H125.335V21.5209C126.752 21.8966 126.781 21.9547 126.781 22.8511V29.3016C126.781 29.9668 126.752 30.1693 126.202 30.3718C125.913 30.4586 125.595 30.5164 125.45 30.4878C124.959 30.4007 124.756 30.285 124.438 29.8511L120.851 24.6445C120.851 24.6445 122.471 22.7354 123.05 22.1859C123.426 21.8101 123.744 21.5784 124.611 21.4916V21.1445H121.083V21.4916C121.864 21.5209 122.211 21.5784 122.211 21.8101C122.211 21.8966 122.095 22.0702 121.892 22.244L118.537 25.7437L121.314 29.9093C121.401 30.0539 121.459 30.1404 121.459 30.2272C121.459 30.4297 121.083 30.4297 120.33 30.6032V30.9503H125.942H130.917V30.6032C129.529 30.2561 129.471 30.1693 129.471 29.3016Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.016 21.1738H131.235C130.339 21.1738 130.252 21.2317 130.252 22.157V22.9383C130.252 23.835 130.31 23.9218 131.235 23.9218H132.016C132.913 23.9218 133 23.8639 133 22.9383V22.157C132.971 21.2317 132.913 21.1738 132.016 21.1738Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.1155 30.0245H40.9874V19.3798C40.9874 18.4834 41.0163 18.4253 42.4337 18.0495V17.7021H36.7354V18.0495C38.1238 18.4253 38.1816 18.4834 38.1816 19.3798V29.3013C38.1816 30.1983 38.1527 30.2559 36.7354 30.6322V30.979H46.5411L46.7725 26.7559H46.4544C45.1238 30.0823 44.0246 30.0245 42.1155 30.0245Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.7315 29.6773V29.8219C52.3555 29.9376 51.9505 29.9958 51.5455 29.9958C50.5331 29.9958 50.186 29.7644 50.186 28.4627C50.186 26.698 50.2728 26.5533 52.7026 26.5533V29.6773H52.7315ZM66.153 29.3013V23.4875C66.153 21.4335 64.6778 20.9707 63.434 20.9707C62.3059 20.9707 60.6571 21.4914 59.7893 22.2724V21.1735H56.029V21.5206C57.4464 21.8966 57.4753 21.9545 57.4753 22.8509V29.3013C57.4753 29.8798 57.4464 30.1112 57.0703 30.285C56.81 30.4005 56.6075 30.4297 56.4918 30.4297C55.5662 30.4875 55.4216 30.0536 55.4216 29.2434V23.9214C55.4216 22.012 54.3224 20.9707 52.4133 20.9707C49.8968 20.9707 47.6406 21.6938 47.6406 23.574C47.6406 24.4131 48.0166 24.8757 48.9712 24.8757C49.9257 24.8757 50.3307 24.2971 50.3307 23.5162C50.3307 22.7644 50.0703 22.5616 49.0869 22.3883V22.3305C49.0869 22.3305 50.0993 22.2724 50.8224 22.2724C52.6158 22.2724 52.7315 22.3591 52.7315 24.2685V25.7434H50.4174C48.2191 25.7434 47.4092 26.4666 47.4092 28.4627C47.4092 30.5451 48.1612 31.1236 49.9546 31.1236C51.1117 31.1236 52.2687 30.7476 52.8761 30.3429C53.1365 30.9214 53.8017 31.1236 54.7274 31.1236C55.2191 31.1236 55.7976 31.066 56.434 30.979C56.5786 30.95 56.7811 30.9214 57.1282 30.9214H61.5827V30.5743C60.1943 30.1983 60.1365 30.1404 60.1365 29.2434V22.5038C60.8596 22.4459 61.5827 22.3883 62.2769 22.3883C63.2893 22.3883 63.405 22.6198 63.405 23.574V29.2434C63.405 30.1404 63.3761 30.1983 61.9588 30.5743V30.9214H67.5414V30.5743C66.2108 30.2558 66.153 30.1691 66.153 29.3013Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.6074 19.843H50.2727C51.0537 19.843 51.1115 19.7854 51.1115 19.0044V18.3391C51.1115 17.5581 51.0537 17.5 50.2727 17.5H49.6074C48.8264 17.5 48.7686 17.5581 48.7686 18.3391V19.0044C48.7686 19.7854 48.8264 19.843 49.6074 19.843Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.2812 19.843H53.9465C54.7275 19.843 54.7854 19.7854 54.7854 19.0044V18.3391C54.7854 17.5581 54.7275 17.5 53.9465 17.5H53.2812C52.5002 17.5 52.4424 17.5581 52.4424 18.3391V19.0044C52.4424 19.7854 52.5002 19.843 53.2812 19.843Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.6817 23.0247C69.6817 22.2148 70.347 21.8677 71.1569 21.8677C72.285 21.8677 73.0081 22.2727 73.7891 23.9504H74.1362L73.9916 21.1738C73.9916 21.1738 72.5453 20.9424 71.5329 20.9424C68.9296 20.9424 67.7437 21.8969 67.7437 23.9504C67.7437 27.9999 72.7189 26.7851 72.7189 28.9545C72.7189 29.7355 72.0536 30.1986 71.2147 30.1986C69.9999 30.1986 68.9585 29.7068 68.0908 27.7685H67.7437L67.8015 30.6325C68.3222 30.7771 69.8841 31.095 71.099 31.095C73.4709 31.095 74.7437 29.938 74.7437 27.9713C74.7437 24.1818 69.6817 25.1652 69.6817 23.0247Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.7066 29.3014V22.0992L80.8471 21.6072V21.1733H78.7066V19.4378C78.7066 18.3097 78.9669 18.2518 79.8347 18.2518H81.1652V18.0204C80.3264 17.2395 79.314 16.9502 78.4173 16.9502C77.0578 16.9502 76.0454 17.7023 76.0454 19.4959V21.1733V21.9833V29.3014C76.0454 30.1984 76.0165 30.256 74.5991 30.6323V30.9791H80.1818V30.6323C78.7644 30.256 78.7066 30.1692 78.7066 29.3014Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.2438 30.3426C83.4504 30.3426 83.2768 30.198 83.2768 26.1773C83.2768 22.2142 83.3636 21.8095 85.2438 21.8095C86.8636 21.8095 86.9504 22.2434 86.9504 26.1773C86.9504 30.1688 86.7768 30.3426 85.2438 30.3426ZM85.2438 20.9707C81.7727 20.9707 80.3843 21.6357 80.3843 26.1484C80.3843 30.5448 81.8016 31.1525 85.2438 31.1525C88.7149 31.1525 89.843 30.6608 89.843 26.1484C89.843 21.6649 88.8306 20.9707 85.2438 20.9707Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.6323 19.843H87.2976C88.0786 19.843 88.1364 19.7854 88.1364 19.0044V18.3391C88.1364 17.5581 88.0786 17.5 87.2976 17.5H86.6323C85.8513 17.5 85.7935 17.5581 85.7935 18.3391V19.0044C85.7935 19.7854 85.8513 19.843 86.6323 19.843Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.959 19.843H83.6242C84.4052 19.843 84.4631 19.7854 84.4631 19.0044V18.3391C84.4631 17.5581 84.4052 17.5 83.6242 17.5H82.959C82.178 17.5 82.1201 17.5581 82.1201 18.3391V19.0044C82.1201 19.7854 82.178 19.843 82.959 19.843Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.8223 23.0247C99.8223 22.2148 100.488 21.8677 101.298 21.8677C102.426 21.8677 103.149 22.2727 103.93 23.9504H104.277L104.132 21.1738C104.132 21.1738 102.686 20.9424 101.674 20.9424C99.0702 20.9424 97.8843 21.8969 97.8843 23.9504C97.8843 27.9999 102.859 26.7851 102.859 28.9545C102.859 29.7355 102.194 30.1986 101.355 30.1986C100.14 30.1986 99.0992 29.7068 98.2314 27.7685H97.8843L97.9421 30.6325C98.4628 30.7771 100.025 31.095 101.24 31.095C103.612 31.095 104.884 29.938 104.884 27.9713C104.884 24.1818 99.8223 25.1652 99.8223 23.0247Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.93 29.6775V29.8221C110.553 29.9378 110.149 29.9959 109.743 29.9959C108.731 29.9959 108.384 29.7645 108.384 28.4629C108.384 26.6981 108.471 26.5535 110.901 26.5535V29.6775H110.93ZM118.335 29.3014V17.124H114.198V17.4711C115.615 17.8472 115.645 17.905 115.645 18.8014V29.3014C115.645 29.7067 115.645 29.9378 115.5 30.1113C115.442 30.1692 115.384 30.227 115.298 30.2852C115.269 30.3138 115.239 30.3138 115.211 30.3138C115.153 30.343 115.124 30.343 115.066 30.3717C114.805 30.4584 114.516 30.4298 114.401 30.4298C113.764 30.4006 113.62 29.9378 113.62 29.2436V23.9215C113.62 22.0122 112.521 20.9708 110.611 20.9708C108.095 20.9708 105.839 21.694 105.839 23.5741C105.839 24.4133 106.215 24.8758 107.169 24.8758C108.124 24.8758 108.529 24.2973 108.529 23.5163C108.529 22.7645 108.269 22.5618 107.285 22.3885V22.3306C107.285 22.3306 108.297 22.2725 109.02 22.2725C110.814 22.2725 110.93 22.3593 110.93 24.2687V25.7436H108.616C106.417 25.7436 105.607 26.4667 105.607 28.4629C105.607 30.5452 106.359 31.1237 108.153 31.1237C109.31 31.1237 110.467 30.7477 111.074 30.343C111.334 30.9215 112 31.1237 112.925 31.1237C113.301 31.1237 113.707 31.0948 114.169 31.037C114.458 31.0083 114.748 30.9215 115.182 30.9215H119.781V30.5744C118.393 30.256 118.335 30.1692 118.335 29.3014Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.748 19.6984H136.413C137.194 19.6984 137.252 19.6409 137.252 18.8599V18.1946C137.252 17.4136 137.194 17.3555 136.413 17.3555H135.748C134.967 17.3555 134.909 17.4136 134.909 18.1946V18.8599C134.938 19.6409 134.996 19.6984 135.748 19.6984Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.475 29.3013V23.4872C148.475 21.4335 147 20.9707 145.756 20.9707C144.628 20.9707 142.979 21.4914 142.112 22.2724V21.1732H138.351V21.5206C139.768 21.8963 139.798 21.9545 139.798 22.8509V29.3013C139.798 30.0244 139.768 30.1983 139.045 30.4294C138.843 30.4875 138.525 30.4875 138.351 30.4583C137.57 30.2269 137.541 30.0244 137.541 29.3013V21.1732H133.405V21.5206C134.822 21.8963 134.851 21.9545 134.851 22.8509V29.3013C134.851 30.1983 134.822 30.2558 133.405 30.6321V30.979H138.351H138.988H143.934V30.6321C142.546 30.2558 142.488 30.1983 142.488 29.3013V22.5616C143.211 22.5038 143.934 22.4459 144.628 22.4459C145.641 22.4459 145.756 22.6776 145.756 23.6319V29.3013C145.756 30.1983 145.727 30.2558 144.31 30.6321V30.979H149.603V30.6321C148.533 30.3137 148.475 30.1691 148.475 29.3013Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.81 33.9878H153.19C151.715 33.9878 151.281 33.8721 151.281 32.773C151.281 32.2234 151.31 31.6738 151.31 31.1531C151.512 31.1821 151.744 31.211 152.004 31.211H154.723C156.285 31.211 156.632 31.3845 156.632 32.6573C156.661 33.8143 156.401 33.9878 154.81 33.9878ZM153.913 21.868C155.012 21.868 155.331 22.0994 155.331 24.2688C155.331 26.2936 155.186 26.4383 153.913 26.4383C152.785 26.4383 152.496 26.2936 152.496 24.2688C152.525 21.9548 152.843 21.868 153.913 21.868ZM155.735 28.6944H152.409C151.744 28.6944 151.512 28.4341 151.512 28.0581C151.512 27.7688 151.57 27.4217 151.657 27.1035C152.264 27.2482 152.93 27.306 153.942 27.306C157.124 27.306 158.223 26.7564 158.223 24.2978C158.223 23.1697 157.934 22.3887 157.355 21.868L159.264 21.492V21.1449C157.558 21.0581 155.331 20.9424 153.942 20.9424C151.686 20.9424 149.632 21.4341 149.632 24.2978C149.632 25.5994 149.979 26.4093 150.934 26.8432C149.979 27.8556 149.748 28.5498 149.806 29.3887C149.835 29.9383 150.124 30.5168 150.789 30.9217C150.211 31.7027 149.806 32.7151 149.806 33.3515C149.806 34.4506 150.384 34.8845 152.467 34.8845H155.475C157.76 34.8845 158.599 34.3349 158.599 31.5292C158.599 29.3308 157.963 28.6944 155.735 28.6944Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.479 19.843H112.144C112.925 19.843 112.983 19.7854 112.983 19.0044V18.3391C112.983 17.5581 112.925 17.5 112.144 17.5H111.479C110.698 17.5 110.641 17.5581 110.641 18.3391V19.0044C110.641 19.7854 110.698 19.843 111.479 19.843Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.806 19.843H108.471C109.252 19.843 109.31 19.7854 109.31 19.0044V18.3391C109.31 17.5581 109.252 17.5 108.471 17.5H107.806C107.025 17.5 106.967 17.5581 106.967 18.3391V19.0044C106.967 19.7854 107.025 19.843 107.806 19.843Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.326 29.6773V29.8219C163.95 29.9376 163.545 29.9958 163.14 29.9958C162.128 29.9958 161.781 29.7644 161.781 28.4627C161.781 26.698 161.868 26.5533 164.297 26.5533V29.6773H164.326ZM171.76 29.3013V21.1735H167.624V21.5206C169.041 21.8966 169.07 21.9545 169.07 22.8509V29.3013C169.07 29.9376 169.041 30.1691 168.52 30.3429C168.318 30.4005 167.913 30.4875 167.508 30.3429C167.103 30.1404 167.016 29.8219 167.016 29.2434V23.9214C167.016 22.012 165.917 20.9707 164.008 20.9707C161.492 20.9707 159.235 21.6938 159.235 23.574C159.235 24.4131 159.611 24.8757 160.566 24.8757C161.52 24.8757 161.925 24.2971 161.925 23.5162C161.925 22.7644 161.665 22.5616 160.682 22.3883V22.3305C160.682 22.3305 161.694 22.2724 162.417 22.2724C164.211 22.2724 164.326 22.3591 164.326 24.2685V25.7434H162.012C159.814 25.7434 159.004 26.4666 159.004 28.4627C159.004 30.5451 159.756 31.1236 161.549 31.1236C162.706 31.1236 163.863 30.7476 164.471 30.3429C164.731 30.9214 165.396 31.1236 166.322 31.1236C166.93 31.1236 167.942 30.9214 168.607 30.9214H173.206V30.5743C171.818 30.2558 171.76 30.1691 171.76 29.3013Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.277 21.1738H173.496C172.599 21.1738 172.512 21.2317 172.512 22.157V22.9383C172.512 23.835 172.57 23.9218 173.496 23.9218H174.277C175.174 23.9218 175.26 23.8639 175.26 22.9383V22.157C175.26 21.2317 175.202 21.1738 174.277 21.1738Z"
      fill="white"
    />
    <mask
      id="mask0_987_4501"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="0"
      width="20"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8928 0.289062H11.0498L11.0498 31.1527H30.8928V0.289062Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_987_4501)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0498 0.289062V8.87997H22.3019V31.1527H30.8928V0.289062H11.0498Z"
        fill="white"
      />
    </g>
    <mask
      id="mask1_987_4501"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8429 0.289062H0V31.1527H19.8429V0.289062Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_987_4501)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.289062V31.1527H19.843V22.5618H8.59091V0.289062H0Z"
        fill="white"
      />
    </g>
    <mask
      id="mask2_987_4501"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="176"
      height="35"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 34.8841H175.26V0.289062H0V34.8841Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_987_4501)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0498 20.103H19.8432V11.3096H11.0498V20.103Z"
        fill="white"
      />
    </g>
  </svg>
));

export const LogoSquare: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5164 0.00012207V9.73527H25.2649V35.0001H35V0.00012207H12.5164Z"
      fill="#005AA0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.00012207V35.0001H22.4832V25.2653H9.73481V0.00012207H0Z"
      fill="#005AA0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5164 22.4833H22.4832V12.5164H12.5164V22.4833Z"
      fill="#E30613"
    />
  </svg>
));
