import { Instance } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { RootStore } from '../models';

const StoresContext = createContext<Instance<typeof RootStore> | null>(null);

export const StoreProvider = StoresContext.Provider;

/**
 * Custom hook for getting the topmost store of the MST tree
 */
export function useStore() {
  const store = useContext(StoresContext);

  if (store === null) {
    throw new Error('StoreProvider needs to provide a RootStore instance');
  }

  return store;
}
