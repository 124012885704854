import React, { FC, memo, SVGProps } from 'react';

export const BellIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4V4.13055C22.584 4.76613 25 7.71236 25 12.7503V20.1281L28 26H20C20 28.2091 18.2091 30 16 30C13.7909 30 12 28.2091 12 26H4L7 20.1281V12.7503C7 7.71236 9.41597 4.76613 14 4.13055V4C14 2.89543 14.8954 2 16 2C17.1046 2 18 2.89543 18 4ZM15.6562 26H18C18 27.1046 17.1046 28 16 28C14.8954 28 14 27.1046 14 26H15.6562ZM8.76672 21L7.25 24H24.75L23 20.5386V12.7503C23 8.14295 20.788 6 16 6C11.212 6 9 8.14295 9 12.7503V19H21.0044V21H8.76672Z"
      fill="inherit"
    />
  </svg>
));
