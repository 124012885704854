import React, { createRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Footer, FooterImage, Header, Menu } from '../components';
import {
  AuctionsPage,
  HomePage,
  ServiceStationsPage,
  AddServiceStationPage,
  ServiceStationDetailPage,
  AddAuctionPage,
  CompanySettingsPage,
  AuctionsDetailPage,
  MyAccountPage,
  ContactPage,
  ServiceStationSettingsPage,
} from '../pages';
import { useStore } from '../hooks';
import { Role } from '../types';

export const LoggedInPage = observer(() => {
  const {
    uiStore: { headerHeight, setHeaderHeight },
    userStore: { me },
  } = useStore();

  const headerRef = createRef<HTMLDivElement>();

  useEffect(
    () => setHeaderHeight(headerRef.current?.clientHeight || 0),
    [headerRef],
  );

  if (me === null) return null;

  return (
    <>
      <div ref={headerRef} className="fixed-top">
        <Header />
        <Menu />
      </div>
      <div
        className="flex-grow-1 container"
        style={{ paddingTop: headerHeight }}
      >
        <Routes>
          <Route
            path="/"
            element={
              me.role >= Role.CaseManager ? (
                <HomePage />
              ) : (
                <Navigate to="/auctions" replace={true} />
              )
            }
          />
          <Route path="/auctions" element={<AuctionsPage />} />
          <Route path="/auctions/:id" element={<AuctionsDetailPage />} />
          <Route path="/my-account" element={<MyAccountPage />} />
          <Route path="/contact" element={<ContactPage />} />
          {(me.role >= Role.CompanyAdmin || me.is_superuser) && (
            <>
              <Route
                path="/service-stations"
                element={<ServiceStationsPage />}
              />
              <Route
                path="/service-stations/new"
                element={<AddServiceStationPage />}
              />
              <Route
                path="/service-stations/:id"
                element={<ServiceStationDetailPage />}
              />
              <Route path="/settings" element={<CompanySettingsPage />} />
            </>
          )}
          {(me.role >= Role.CaseManager || me.is_superuser) && (
            <>
              <Route path="/auctions/new" element={<AddAuctionPage />} />
              <Route path="/auctions/:id/change" element={<AddAuctionPage />} />
            </>
          )}
          {me.role == Role.ServiceStation && (
            <>
              <Route path="/my-auctions" element={<AuctionsPage page="my" />} />
              <Route
                path="/won-auctions"
                element={<AuctionsPage page="won" />}
              />
              <Route
                path="/settings"
                element={<ServiceStationSettingsPage />}
              />
            </>
          )}
        </Routes>
      </div>
      <FooterImage className="my-3 mx-auto w-100" />
      <Footer />
    </>
  );
});
