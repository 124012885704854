import React, { forwardRef, InputHTMLAttributes, useId } from 'react';
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string | false;
  error?: string | false;
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ label, error, ...rest }, ref) => {
    const id = useId();

    return (
      <fieldset className={classNames('mb-1', { 'has-danger': error })}>
        {label && <label htmlFor={id}>{label}</label>}
        <textarea
          className="form-control"
          id={id}
          rows={3}
          ref={ref}
          {...rest}
        ></textarea>
        {error && <span className="form-control-feedback">{error}</span>}
      </fieldset>
    );
  },
);
