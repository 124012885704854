import React, { forwardRef, InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | false;
  value: string | number | undefined;
  active: boolean;
}

export const RadioButton = forwardRef<HTMLInputElement, Props>(
  ({ label, value, active, ...rest }, ref) => {
    return (
      <label className={`btn ${active ? 'active' : ''}`}>
        <input type="radio" value={value} ref={ref} {...rest} />
        {label}
      </label>
    );
  },
);
