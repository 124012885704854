import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, PrimaryButton, SecondaryButton } from '../../components';
import axios from 'axios';
import { LoginWrapper } from './components/LoginWrapper';
import { errorMessageHandler } from '../../utils/errorMessageHandler';

type FormData = {
  password: string;
  confirmPassword: string;
};

export const CreatePasswordPage = observer(() => {
  const navigate = useNavigate();
  const params = useParams();

  const state = useLocalObservable<{
    isSubmiting: boolean;
    errorMessages: string[];
    passwordCreated: boolean;
  }>(() => ({
    isSubmiting: false,
    errorMessages: [],
    passwordCreated: false,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async ({ password, confirmPassword }) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;

    try {
      await axios.post(`/api/reset-password/${params.token}`, {
        password,
        confirm_password: confirmPassword,
      });
      state.passwordCreated = true;
    } catch (error: any) {
      state.errorMessages = errorMessageHandler(error.response.data);
    } finally {
      state.isSubmiting = false;
    }
  });

  return (
    <LoginWrapper>
      {state.passwordCreated ? (
        <>
          <h3 style={{ marginBottom: 164 }}>Lösenordet har återställts</h3>
          <PrimaryButton
            onClick={() => navigate('/', { replace: true })}
            className="w-100"
          >
            Logga in
          </PrimaryButton>
        </>
      ) : (
        <>
          <h3>Återställ ditt lösenord</h3>
          <p>Välj ett nytt lösenord för ditt konto</p>
          <form onSubmit={onSubmit}>
            <div className="d-flex flex-column">
              <Input
                type="password"
                placeholder="Ditt nya lösenord"
                {...register('password', { required: true })}
                error={errors.password?.message}
              />
              <Input
                type="password"
                placeholder="Bekräfta ditt nya lösenord"
                {...register('confirmPassword', { required: true })}
                error={
                  errors.confirmPassword && !errors.password
                    ? errors.confirmPassword.message
                    : ''
                }
              />
              {state.errorMessages.length > 0 && (
                <div className="alert alert-danger" role="alert">
                  <ul className="ul alert-content">
                    {state.errorMessages.map((error, i) => (
                      <li key={i}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
              <PrimaryButton loading={state.isSubmiting}>
                Återställ lösenordet
              </PrimaryButton>
            </div>
          </form>
          <SecondaryButton
            onClick={() => navigate('/', { replace: true })}
            className="w-100 mt-1"
          >
            Tillbaka till inloggning
          </SecondaryButton>
        </>
      )}
    </LoginWrapper>
  );
});
