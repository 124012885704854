import classNames from 'classnames';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

export const DropdownSelect = observer(
  ({ className, children }: { className?: string; children: ReactNode }) => {
    const state = useLocalObservable<{
      isOpen: boolean;
    }>(() => ({
      isOpen: false,
    }));
    const ref = useDetectClickOutside({
      onTriggered: () => (state.isOpen = false),
    });

    return (
      <div className={classNames('position-relative', className)} ref={ref}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="button"
          onClick={() => (state.isOpen = !state.isOpen)}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 27C9.935 27 5 22.065 5 16C5 9.935 9.935 5 16 5C22.065 5 27 9.935 27 16C27 22.065 22.065 27 16 27ZM16 3C8.832 3 3 8.832 3 16C3 23.168 8.832 29 16 29C23.168 29 29 23.168 29 16C29 8.832 23.168 3 16 3Z"
            fill="#005AA0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.149 13.9998C8.992 13.9998 8.182 14.8348 8.182 15.9088C8.182 16.9828 9.108 17.8178 10.149 17.8178C11.19 17.8178 12 16.9828 12 15.9088C12 14.8348 11.306 13.9998 10.149 13.9998Z"
            fill="#005AA0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9672 13.9998C20.8102 13.9998 20.0002 14.8348 20.0002 15.9088C20.0002 16.9828 20.9262 17.8178 21.9672 17.8178C23.0082 17.8178 23.8182 16.9828 23.8182 15.9088C23.8182 14.8348 23.1242 13.9998 21.9672 13.9998Z"
            fill="#005AA0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.058 14.0907C14.901 14.0907 14.091 14.9257 14.091 15.9997C14.091 17.0737 15.017 17.9087 16.058 17.9087C17.099 17.9087 17.909 17.0737 17.909 15.9997C17.909 14.9257 17.215 14.0907 16.058 14.0907Z"
            fill="#005AA0"
          />
        </svg>
        {state.isOpen && (
          <div className="d-flex flex-column bg-white position-absolute dropdown-select">
            {children}
          </div>
        )}
      </div>
    );
  },
);
