export enum AuctionsOrdering {
  Manufacturer = 'manufacturer',
  Model = 'model',
  StagingAreaName = 'staging_area_name',
  EndDT = 'end_dt',
  HighestBid = 'bid_highest',
}

export const AUCTIONS_ORDERING_TEXTS = {
  [AuctionsOrdering.Manufacturer]: 'Fabrikat',
  [AuctionsOrdering.Model]: 'Modell',
  [AuctionsOrdering.StagingAreaName]: 'Uppställningsplats',
  [AuctionsOrdering.EndDT]: 'Slutdatum',
  [AuctionsOrdering.HighestBid]: 'Högsta bud',
};
