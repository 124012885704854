import React, { useEffect, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';
import { useStore } from '../hooks';
import { Role } from '../types';
import { CustomerSupportIcon } from '../icons';

const ContactTab = observer(({ active }: { active: boolean }) => {
  return (
    <div
      className={classNames('contact-tab', {
        active: active,
      })}
    >
      <Link
        className={classNames('contact-tab-btn text-decoration-none', {
          'border-bottom': active,
          'border-secondary': active,
        })}
        to="/contact"
      >
        <div className="contact-tab-btn-wrapper">
          <CustomerSupportIcon height={32} width={32} />
          <span className="font-sans-serif text-navy text-lg ml-08">
            Kontakta oss
          </span>
        </div>
      </Link>
    </div>
  );
});

export const Menu = observer(() => {
  const location = useLocation();

  const {
    userStore: { me },
  } = useStore();

  const state = useLocalObservable(() => ({
    lastPosition: 0,
    isScrollingUp: true,
    pathName: location.pathname,
  }));

  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position < state.lastPosition) {
      state.isScrollingUp = true;
    } else if (position > 50) {
      state.isScrollingUp = false;
    }

    state.lastPosition = position;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    state.pathName = location.pathname;
  }, [location.pathname]);

  if (me === null) return null;

  const tabs = [
    {
      title: 'Hem',
      href: '/',
      active: state.pathName == '/',
      visible: me.role >= Role.CaseManager,
    },
    {
      title: 'Auktioner',
      href: '/auctions',
      active: state.pathName.startsWith('/auctions'),
      visible: true,
    },
    {
      title: 'Mina auktioner',
      href: '/my-auctions',
      active: state.pathName.startsWith('/my-auctions'),
      visible: me.role === Role.ServiceStation,
    },
    {
      title: 'Vunna auktioner',
      href: '/won-auctions',
      active: state.pathName.startsWith('/won-auctions'),
      visible: me.role === Role.ServiceStation,
    },
    {
      title: 'Verkstäder',
      href: '/service-stations',
      active: state.pathName.startsWith('/service-stations'),
      visible: me.role >= Role.CompanyAdmin || me.is_superuser,
    },
  ];

  return (
    <div
      className={classNames('bg-white menu', {
        'scrolled-up': state.isScrollingUp,
        'scrolled-down': !state.isScrollingUp,
      })}
    >
      <div className="d-flex justify-content-between container">
        <nav>
          <ul className="d-flex list-unstyled m-0">
            {tabs
              .filter((tab) => tab.visible)
              .map((tab, index) => (
                <li
                  key={index}
                  className={classNames('nav-item', {
                    active: tab.active,
                  })}
                >
                  <Link
                    to={tab.href}
                    className={classNames(
                      'd-block font-sans-serif text-navy text-lg text-decoration-none px-15 py-08',
                      {
                        'border-bottom': tab.active,
                        'border-secondary': tab.active,
                      },
                    )}
                  >
                    {tab.title}
                  </Link>
                </li>
              ))}
          </ul>
        </nav>
        <ContactTab active={state.pathName.startsWith('/contact')} />
      </div>
    </div>
  );
});
