import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { getRoot, IStateTreeNode } from 'mobx-state-tree';
import { RootStore } from '../models';

/**
 * This extension lets us extend models futher down the tree
 * with a request method that handles token refresh and logout
 * automatically under the hood.
 */
export const withRequest = (self: IStateTreeNode) => ({
  views: {
    get request(): (conf: AxiosRequestConfig) => AxiosPromise {
      const rootStore = getRoot<typeof RootStore>(self);

      if (rootStore.authStore) {
        return rootStore.authStore.authRequest;
      }
      // Fallback to an unauthorized request method if the model
      // using this extension happens to not be part of the main tree.
      return axios;
    },
  },
});
