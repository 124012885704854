import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { configure } from 'mobx';
import { onSnapshot } from 'mobx-state-tree';
import { RootStore } from './models';
import { StoreProvider } from './hooks';
import App from './App';

import './styles/globals.scss';

const emptyState = {
  auctionStore: {},
  bidStore: {},
  authStore: {},
  companyStore: {},
  serviceStationStore: {},
  statisticsStore: {},
  uiStore: {},
  userStore: {},
};
const initialState = {
  ...emptyState,
};

const savedAuthStore = localStorage.getItem('authStore');
if (savedAuthStore) {
  initialState.authStore = JSON.parse(savedAuthStore);
}

let rootStore;
try {
  rootStore = RootStore.create(initialState);
} catch (error) {
  // Creating the RootStore instance can fail if it is given
  // broken data. This can happen if the authStore snapshot in
  // localStorage is out of date. If this happens we fall back
  // to the empty state.
  rootStore = RootStore.create(emptyState);
}

onSnapshot(rootStore.authStore, (newSnapshot) => {
  localStorage.setItem('authStore', JSON.stringify(newSnapshot));
});

// The rootStore is initialized automatically on login, but
// if the user is already logged in we initialize it manually.
if (rootStore.authStore.isLoggedIn) {
  rootStore.init();
}

configure({
  enforceActions: 'never',
});

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <StoreProvider value={rootStore}>
      <Router>
        <App />
      </Router>
    </StoreProvider>
  </React.StrictMode>,
);
