import { AxiosPromise } from 'axios';
import { flow, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { Auction, AuctionInstance } from './Auction';

const AuctionArray = t.array(Auction);

export const AuctionStore = t
  .model('AuctionStore', {
    company: t.maybeNull(t.string),
    next_page: t.maybeNull(t.string),
    auctions: t.maybeNull(AuctionArray),
    myAuctions: t.maybeNull(AuctionArray),
    wonAuctions: t.maybeNull(AuctionArray),
    auctionsSearch: '',
  })
  .views((self) => ({
    get auctionsQuery(): URLSearchParams {
      return new URLSearchParams(self.auctionsSearch);
    },
  }))
  .views((self) => ({
    get hasMore(): boolean {
      return self.next_page != null;
    },
    get auctionsOrdering() {
      const orderingParam = self.auctionsQuery.get('ordering');

      if (!orderingParam) return '';

      return orderingParam.replace('-', '');
    },
    get auctionsIsAscending() {
      const orderingParam = self.auctionsQuery.get('ordering');

      return orderingParam !== null && orderingParam[0] !== '-';
    },
    get auctionCurrentOrder() {
      const orderingParam = self.auctionsQuery.get('ordering');

      return orderingParam ? orderingParam.replace('-', '') : '';
    },
  }))
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchAuctions: flow(function* (nextPage = false) {
        const searchParams = new URLSearchParams(location.search);
        nextPage = nextPage && self.next_page != null;

        const promise = request({
          method: 'GET',
          url: nextPage
            ? self.next_page!
            : `/api/auctions?${searchParams.toString()}`,
          params: self.company && {
            company: self.company,
          },
        });

        const { data } = yield promise;
        const auctions: AuctionInstance[] = data.results;

        if (self.auctions === null) {
          self.auctions = AuctionArray.create();
        }

        self.next_page = data.next;

        if (nextPage) {
          auctions.forEach((service_station) => {
            self.auctions!.push(service_station);
          });
        } else {
          self.auctions.replace(auctions);
        }
      }),

      fetchMyAuctions: flow(function* (nextPage = false) {
        const searchParams = new URLSearchParams(location.search);
        nextPage = nextPage && self.next_page != null;

        const promise = request({
          method: 'GET',
          url: nextPage
            ? self.next_page!
            : `/api/my-auctions?${searchParams.toString()}`,
          params: self.company && {
            company: self.company,
          },
        });

        const { data } = yield promise;
        const auctions: AuctionInstance[] = data.results;

        if (self.myAuctions === null) {
          self.myAuctions = AuctionArray.create();
        }

        self.next_page = data.next;

        if (nextPage) {
          auctions.forEach((auction) => {
            self.myAuctions!.push(auction);
          });
        } else {
          self.myAuctions.replace(auctions);
        }
      }),

      fetchWonAuctions: flow(function* (nextPage = false) {
        const searchParams = new URLSearchParams(location.search);
        nextPage = nextPage && self.next_page != null;

        const promise = request({
          method: 'GET',
          url: nextPage
            ? self.next_page!
            : `/api/won-auctions?${searchParams.toString()}`,
          params: self.company && {
            company: self.company,
          },
        });

        const { data } = yield promise;
        const auctions: AuctionInstance[] = data.results;

        if (self.wonAuctions === null) {
          self.wonAuctions = AuctionArray.create();
        }

        self.next_page = data.next;

        if (nextPage) {
          auctions.forEach((auction) => {
            self.wonAuctions!.push(auction);
          });
        } else {
          self.wonAuctions.replace(auctions);
        }
      }),

      fetchAuction: flow(function* (id: string) {
        const promise = request({
          method: 'GET',
          url: `/api/auctions/${id}`,
        });

        const { data } = yield promise;

        return Auction.create(data);
      }),

      createAuction: flow(function* (fields: FormData) {
        const promise = request({
          method: 'POST',
          url: `/api/auctions`,
          data: fields,
        });

        const { data } = yield promise;

        return data as AuctionInstance;
      }),

      updateAuction: flow(function* (fields: FormData, id: any) {
        const promise = request({
          method: 'PUT',
          url: `/api/auctions/${id}`,
          data: fields,
        });

        const { data } = yield promise;

        return data as AuctionInstance;
      }),

      createAuctionImages: flow(function* (id, images: FileList) {
        const promises: AxiosPromise[] = [];
        const data = new FormData();

        [...images].forEach((image) => {
          data.set('image', image);
          promises.push(
            request({
              method: 'POST',
              url: `/api/auctions/${id}/images`,
              data,
            }),
          );
        });

        yield Promise.all(promises);
      }),

      createAuctionFiles: flow(function* (id, files: FileList) {
        const promises: AxiosPromise[] = [];
        const data = new FormData();

        [...files].forEach((file) => {
          data.set('file', file);
          promises.push(
            request({
              method: 'POST',
              url: `/api/auctions/${id}/files`,
              data,
            }),
          );
        });

        yield Promise.all(promises);
      }),

      bidAuction: flow(function* (id: string, data) {
        const promise = request({
          method: 'POST',
          url: `/api/auctions/${id}/bids`,
          data,
        });

        yield promise;
      }),

      removeImage: flow(function* (id: string, image_id: string) {
        const promise = request({
          method: 'DELETE',
          url: `/api/auctions/${id}/images/${image_id}`,
        });

        yield promise;
      }),

      removeFile: flow(function* (id: string, file_id: string) {
        const promise = request({
          method: 'DELETE',
          url: `/api/auctions/${id}/files/${file_id}`,
        });

        yield promise;
      }),

      createAuctionAlert: flow(function* (id: string) {
        const promise = request({
          method: 'POST',
          url: `/api/auctions/${id}/alert`,
        });

        yield promise;
      }),

      deleteAuctionAlert: flow(function* (id: string) {
        const promise = request({
          method: 'DELETE',
          url: `/api/auctions/${id}/alert`,
        });

        yield promise;
      }),

      cancelAuction: flow(function* (id: string) {
        const promise = request({
          method: 'POST',
          url: `/api/auctions/${id}/cancel`,
        });

        yield promise;
      }),

      deleteAuction: flow(function* (id: string) {
        const promise = request({
          method: 'DELETE',
          url: `/api/auctions/${id}`,
        });

        yield promise;
      }),

      notifyWinner: flow(function* (id: string) {
        const promise = request({
          method: 'POST',
          url: `/api/auctions/${id}/notify-winner`,
        });

        yield promise;
      }),
    };
  })
  .actions((self) => ({
    setCompany: (company: string) => {
      self.company = company;
      self.fetchAuctions();
    },

    handleAuctionsSearchChange: (search: string) => {
      self.auctionsSearch = search;
    },

    handleNewBidMessage: (message: string) => {
      const data = JSON.parse(message);
      const { auction_id, bid, min_bid, end_dt } = data;

      if (!auction_id || !bid || !min_bid || !end_dt) return;

      const myAuction = self.myAuctions?.find((a) => a.id === auction_id);
      const auction = self.auctions?.find((a) => a.id === auction_id);

      if (myAuction) {
        myAuction.highest_bid = bid;
        myAuction.minimum_allowed_bid = min_bid;
        myAuction.end_dt = new Date(end_dt);
      }

      if (auction) {
        auction.highest_bid = bid;
        auction.minimum_allowed_bid = min_bid;
        auction.end_dt = new Date(end_dt);
      }
    },
  }));
