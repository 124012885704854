import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from './hooks';
import {
  AdfsCallbackPage,
  LoggedInPage,
  LoginPage,
  CreatePasswordPage,
  ForgotPasswordPage,
  TwoFactorConfirmPage,
  TermsPage,
} from './pages';
import { Routes, Route } from 'react-router-dom';

export default observer(function App() {
  const { authStore } = useStore();

  return (
    <Routes>
      <Route path="/terms/*" element={<TermsPage />} />
      {!authStore.isLoggedIn ? (
        <>
          <Route path="/adfs-callback" element={<AdfsCallbackPage />} />
          <Route path="/2fa" element={<TwoFactorConfirmPage />} />
          <Route
            path="/create-password/:token"
            element={<CreatePasswordPage />}
          />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="*" element={<LoginPage />} />
        </>
      ) : (
        <Route path="*" element={<LoggedInPage />} />
      )}
    </Routes>
  );
});
