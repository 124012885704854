import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Input, PrimaryButton, SecondaryButton } from '../../components';
import axios from 'axios';
import { LoginWrapper } from './components/LoginWrapper';

type FormData = {
  email: string;
};

export const ForgotPasswordPage = observer(() => {
  const navigate = useNavigate();

  const state = useLocalObservable(() => ({
    isSubmiting: false,
    hasFormError: false,
    hasSubmitted: false,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async ({ email }) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;
    state.hasFormError = false;

    try {
      await axios.post('/api/reset-password', { email });
      state.hasSubmitted = true;
    } catch (error) {
      state.hasFormError = true;
    } finally {
      state.isSubmiting = false;
    }
  });

  return (
    <LoginWrapper>
      {state.hasSubmitted ? (
        <div className="d-flex flex-column">
          <h3>Kontrollera din e-post</h3>
          <p>
            Vi har skickat ett meddelande med information om återställning av
            lösenordet till {getValues('email')}.
          </p>
          <p className="text-primary mt-2">
            Fick du inte e-postmeddelandet? Kontrollera mappen för skräppost
            eller kampanjer eller försök skicka det igen.
          </p>
          <form onSubmit={onSubmit}>
            <Input type="hidden" {...register('email')} />
            <PrimaryButton className="w-100">Skicka igen</PrimaryButton>
          </form>
          <SecondaryButton
            className="mt-1"
            onClick={() => navigate('/', { replace: true })}
          >
            Tillbaka till inloggning
          </SecondaryButton>
        </div>
      ) : (
        <>
          <h3>Har du glömt ditt lösenord?</h3>
          <p>
            Ange den e-postadress som du använde för att skapa ditt konto så att
            vi kan skicka instruktioner om hur du återställer ditt lösenord.
          </p>
          <form onSubmit={onSubmit}>
            <div className="d-flex flex-column">
              <Input
                type="email"
                placeholder="E-postadress"
                {...register('email', {
                  required: 'Fyll i detta fältet',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Ange en giltig e-post',
                  },
                })}
                error={errors.email?.message}
              />
              {state.hasFormError && (
                <div className="alert alert-danger" role="alert">
                  <p className="alert-content">Något gick fel försök igen.</p>
                </div>
              )}
              <PrimaryButton loading={state.isSubmiting}>Skicka</PrimaryButton>
            </div>
          </form>
          <SecondaryButton
            onClick={() => navigate('/', { replace: true })}
            className="w-100 mt-1"
          >
            Tillbaka till inloggning
          </SecondaryButton>
        </>
      )}
    </LoginWrapper>
  );
});
