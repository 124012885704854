import { types as t } from 'mobx-state-tree';

function validateDate(str: string): Date {
  const date = Date.parse(str);
  if (isNaN(date)) throw new Error('Invalid date');

  return new Date(date);
}

export const IsoDate = t.custom<string, Date>({
  name: 'IsoDate',
  fromSnapshot(value: string): Date {
    return validateDate(value);
  },
  toSnapshot(value: Date): string {
    return value.toISOString();
  },
  isTargetType(maybeDate) {
    return maybeDate instanceof Date;
  },
  getValidationMessage(snapshot) {
    // If we don't throw an error here when the snapshot is faulty (e.g. null),
    // things like types.maybeNull(IsoDate) will not work properly
    try {
      validateDate(snapshot);
      return '';
    } catch (error: any) {
      return error.message;
    }
  },
});
