import { types as t } from 'mobx-state-tree';
import { AuctionStore } from './AuctionStore';
import { AuthStore } from './AuthStore';
import { BidsStore } from './BidsStore';
import { CompanyStore } from './CompanyStore';
import { ServiceStationStore } from './ServiceStationStore';
import { StatisticsStore } from './StatisticsStore';
import { UIStore } from './UIStore';
import { UserStore } from './UserStore';

export const RootStore = t
  .model('RootStore', {
    auctionStore: AuctionStore,
    bidStore: BidsStore,
    authStore: AuthStore,
    companyStore: CompanyStore,
    serviceStationStore: ServiceStationStore,
    statisticsStore: StatisticsStore,
    uiStore: UIStore,
    userStore: UserStore,
  })
  .actions((self) => ({
    init() {
      self.companyStore.fetchCategories();
      self.companyStore.fetchStagingAreas();
      self.companyStore.fetchCompany();
      self.userStore.fetchMe();
    },
    reset() {
      self.authStore.reset();
      self.companyStore.reset();
      self.uiStore.reset();
      self.userStore.reset();
    },
  }));
