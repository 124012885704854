import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useId,
} from 'react';
import classNames from 'classnames';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { isImage } from '../utils/isImage';
import { UploadPlaceholder } from './UploadPlaceholder';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | false;
  description?: string | false;
  mutiple?: boolean;
  error?: string | false;
}

export const Upload = observer(
  forwardRef<HTMLInputElement, Props>(
    ({ label, description, multiple, error, ...rest }, ref) => {
      const id = useId();

      const state = useLocalObservable(() => ({
        files: [] as {
          name: string;
          data?: string;
          validation: string;
          type: string;
        }[],
      }));

      useEffect(() => {
        const handleChange = (e: Event) => {
          state.files = [];
          const target = e.target as HTMLInputElement;
          if (target.files && target.files[0]) {
            [...target.files].forEach((file: any) => {
              const fileIndex =
                state.files.push({
                  name: file.name,
                  validation: 'loading',
                  type: isImage(file) ? 'image' : 'doc',
                }) - 1;
              const reader = new FileReader();

              reader.addEventListener('load', () => {
                state.files[fileIndex].data = reader.result as string;
                state.files[fileIndex].validation = 'has-valid';
              });
              reader.readAsDataURL(file);
            });
          }
        };

        const inputEl = document.getElementById(id);
        inputEl!.addEventListener('change', handleChange, false);

        return () => {
          inputEl!.removeEventListener('change', handleChange);
        };
      }, []);

      return (
        <fieldset className={classNames('mb-1', { 'has-danger': error })}>
          {label && <label htmlFor={id}>{label}</label>}
          {description && <p className="mb-0">{description}</p>}
          <div className="upload w-100">
            {state.files.map((file, index) => (
              <UploadPlaceholder key={index} file={file} />
            ))}
            <input
              className="upload-input"
              type="file"
              name="filename"
              id={id}
              multiple={multiple}
              ref={ref}
              {...rest}
            />
            <label
              className="upload-label btn btn-secondary mt-05 px-1"
              htmlFor={id}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon mr-025"
              >
                <path
                  d="M10.6704 16.8123C11.9811 19.2613 13.5118 19.8728 15.6353 18.8826C17.7588 17.8924 18.2743 16.3267 17.2627 13.7991L14.282 7.40701L16.0946 6.56177L19.0972 13.0044C20.5079 16.5238 19.616 19.2331 16.4805 20.6952C13.3451 22.1573 10.6963 21.099 8.88244 17.7068L4.64745 8.62484L4.60666 8.52317C3.87566 6.36602 4.67291 4.61922 6.80699 3.62408C8.93725 2.63073 10.7923 3.13381 11.9971 5.06369L12.0551 5.17061L16.2901 14.2526L16.3343 14.3645C16.8321 15.8872 16.4317 17.1538 15.1154 17.7676C13.7996 18.3812 12.5718 17.8748 11.7234 16.5161L11.6654 16.4091L8.68471 10.0171L10.4973 9.17183L13.4511 15.5062C13.7846 16.0273 13.9689 16.0955 14.2701 15.955C14.5709 15.8147 14.6366 15.6308 14.4509 15.0409L10.2696 6.07399C9.61811 5.06148 8.86496 4.87119 7.65222 5.4367C6.4414 6.00131 6.11331 6.69073 6.48449 7.83202L10.6704 16.8123Z"
                  fill="#005AA0"
                />
              </svg>
              Bifoga fil
            </label>
          </div>
          {error && <span className="form-control-feedback">{error}</span>}
        </fieldset>
      );
    },
  ),
);
