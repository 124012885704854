import React, { createRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Footer, FooterImage, Header, Menu } from '../../components';
import { useStore } from '../../hooks';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PersonalData } from './PersonalData';
import { TermsOfUse } from './TermsOfUse';
import { PurchaseConditions } from './PurchaseConditions';

export const TermsPage = observer(() => {
  const {
    uiStore: { headerHeight, setHeaderHeight },
  } = useStore();

  const headerRef = createRef<HTMLDivElement>();

  useEffect(
    () => setHeaderHeight(headerRef.current?.clientHeight || 0),
    [headerRef],
  );

  return (
    <>
      <div ref={headerRef} className="fixed-top">
        <Header />
        <Menu />
      </div>
      <div
        className="flex-grow-1 container"
        style={{ paddingTop: headerHeight }}
      >
        <Routes>
          <Route path="/personal-data" element={<PersonalData />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/purchase-conditions" element={<PurchaseConditions />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <FooterImage className="my-3 mx-auto w-100" />
      <Footer />
    </>
  );
});
