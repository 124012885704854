import React from 'react';
import { Link } from 'react-router-dom';
import { LogoSquare } from '../components';

export const Footer = () => {
  return (
    <div className="bg-white py-1 text-sm mt-auto">
      <div className="d-flex align-items-center justify-content-between container">
        <div className="d-flex align-items-center">
          <a
            className="d-flex align-items-center text-decoration-none text-black"
            href="#"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 10.9141L6.70712 16.207L5.29291 14.7928L12 8.08569L18.7071 14.7928L17.2929 16.207L12 10.9141Z"
                fill="#222222"
              />
              <mask
                id="mask0_117_5571"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="5"
                y="8"
                width="14"
                height="9"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 10.9141L6.70712 16.207L5.29291 14.7928L12 8.08569L18.7071 14.7928L17.2929 16.207L12 10.9141Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_117_5571)"></g>
            </svg>
            <span className="pl-1">Till toppen</span>
          </a>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <Link
              target="_blank"
              className="text-decoration-none mr-1 text-black"
              to="/terms/personal-data"
            >
              Personuppgifter
            </Link>
            <Link
              target="_blank"
              className="text-decoration-none mr-1 text-black"
              to="/terms/terms-of-use"
            >
              Användarvillkor
            </Link>
            <Link
              target="_blank"
              className="text-decoration-none mr-1 text-black"
              to="/terms/purchase-conditions"
            >
              Allmänna köpvillkor
            </Link>
            <p className="d-inline-block text-black mb-0 mr-1">
              Vi använder inte kakor (cookies) på denna webbplats.
            </p>
          </div>
          <LogoSquare className="footer-logo" />
        </div>
      </div>
    </div>
  );
};
