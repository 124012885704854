import React, { FC, HTMLAttributes, useEffect } from 'react';
import 'bootstrap5/js/src/modal';

interface Props extends HTMLAttributes<HTMLDivElement> {
  id: string;
  title: string;
  footer: React.ReactNode;
  onClose?: (event: Event) => void;
}

export const Modal: FC<Props> = ({
  id,
  title,
  footer,
  onClose,
  children,
  ...rest
}) => {
  useEffect(() => {
    const modal = document.getElementById(id);

    if (modal && onClose) {
      modal.addEventListener('hidden.bs.modal', onClose);
    }

    return () => {
      if (modal && onClose) {
        modal.removeEventListener('hidden.bs.modal', onClose);
      }
    };
  }, []);

  return (
    <div
      className="modal fade"
      id={id}
      tabIndex={-1}
      aria-hidden="true"
      {...rest}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">{footer}</div>
        </div>
      </div>
    </div>
  );
};
