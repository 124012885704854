import React from 'react';
import { Breadcrumbs } from '../../components';

export const PurchaseConditions = () => (
  <>
    <Breadcrumbs
      items={[
        {
          title: 'Hem',
          href: '/',
        },
      ]}
      active="Allmänna köpvillkor"
    />
    <div className="col-8 mx-auto">
      <h1>Allmänna köpvillkor</h1>
      <h3>1. Inledning</h3>
      <p className="d-flex flex-row">
        <span>1.1.&nbsp;</span>
        <span>
          Dessa allmänna köpvillkor (”Villkoren”) är tillämpliga för företag
          eller annan juridisk person som deltar i budgivning(ar) (”Köparen”)
          avseende ett eller flera objekt på auktionssajten för restförsäljning
          www.LFrest.se (”Auktionssajten”) som förvaltas av Skaraborg
          Skadeförebygg AB, 559322-3596, (”Skadeförebygg”). Villkoren är även
          tillämpliga för det enskilda anslutna Länsförsäkringsbolaget som lagt
          upp det försålda Objektet för auktion (”Säljaren”). De anslutna
          Länsförsäkringsbolagen framgår av <a href="#attachment-1">Bilaga 1</a>
          .
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>1.2.&nbsp;</span>
        <span>
          Säljaren och Köparen är nedan var för sig benämnda (”Part”) samt
          gemensamt (”Parterna”).
        </span>
      </p>
      <div className="d-flex flex-row">
        <span>1.3.&nbsp;</span>
        <span>
          Bindande avtal ingås mellan Köparen och Säljaren enligt vad som anges
          nedan. De objekt som utbjuds till försäljning genom budgivning
          (”Objekten”) förmedlas och försäljs endast genom Skadeförebyggs
          Auktionssajt. Skadeförebygg är inte part till de avtal som ingås och
          har inget ansvar för de försålda Objekten.
          <p className="d-flex flex-row mt-1">
            <span>1.3.1.&nbsp;</span>
            <span>
              Försäljningen omfattar Objekt som försäljs för demontering av
              Köparen. I förekommande fall skall Objektet avregistreras vid
              försäljningen.
            </span>
          </p>
          <p className="d-flex flex-row">
            <span>1.3.2.&nbsp;</span>
            <span>
              Försäljningen omfattar även Objekt som försäljs för reparation.
              Reparationen ska utföras fackmässigt. Objektet får ej
              vidareförsäljas av Köparen i skadat eller felaktigt skick. Vid
              försäljning av reparerat Objekt ska Objektsbeskrivningen, se
              nedan, samt en beskrivning av utförd reparation medfölja.
            </span>
          </p>
          <p className="d-flex flex-row">
            <span>1.3.3.&nbsp;</span>
            <span>
              Varken Objekt som försäljs för reparation eller demontering får
              försäljas till utlandet (export).
            </span>
          </p>
        </span>
      </div>
      <p className="d-flex flex-row">
        <span>1.4.&nbsp;</span>
        <span>
          Utgångspriset på Objekt inför auktionen utgör ett grovt uppskattat
          utgångspris som väsentligen kan komma att under- eller överstiga det
          faktiska marknadsvärdet. Utgångspris samt bud presenteras exklusive
          moms.
        </span>
      </p>
      <h3>2. Objekt samt undersökningsplikt</h3>
      <p className="d-flex flex-row">
        <span>2.1.&nbsp;</span>
        <span>
          Information avseende Objekt som finns tillgängliga för försäljning på
          Auktionssajtenen återges i (”Objektsbeskrivningen”).
          Objektsbeskrivningen beskriver Objektets huvudsakliga egenskaper.
          Uppgifter i en Objektsbeskrivning kan ändras på grund av tillkommande
          omständigheter.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>2.2.&nbsp;</span>
        <span>
          Skadeförebygg eller Säljaren lämnar inte några garantier avseende
          Objektets överensstämmelse med Objektsbeskrivningen. Informationen om
          Objekten i Objektsbeskrivningen har utformats för att vara
          lättillgänglig och översiktlig. Skadeförebygg eller Säljaren genomför
          inte några tester avseende Objekten exempelvis men inte uteslutande
          vad avser säkerhetsdetaljer eller teknisk funktion innan eller efter
          auktionen.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>2.3.&nbsp;</span>
        <span>
          Objektet försäljs i befintligt skick och Köparen åtar sig att själv
          undersöka Objektets skick i samband med förvärvet. Skadeförebygg eller
          Säljaren erbjuder inte visning av Objektet innan auktionsstart. Det är
          Köparen som ska kontrollera och undersöka att Objektet motsvarar det
          Köparen förväntar sig med hänsyn till Objektsbeskrivningen. Köparen
          godkänner således det av Köparen besiktigade skicket samt frånsäger
          sig genom förvärvet all rätt till reklamation av Objektet, med
          undantag för vad som anges under punkt 3 (Anbud och accept) nedan.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>2.4.&nbsp;</span>
        <span>
          Köparen har således uppmanats att undersöka Objektet. Säljaren har
          inte, vare sig explicit eller implicit, lämnat och Köparen har inte
          förlitat sig på, någon garanti eller utfästelse beträffande Objektet.
          Köparen friskriver Säljaren och Skadeförebygg från allt felansvar,
          produktskador, indirekta skador eller följdskador som Säljaren och
          Skadeförebygg eventuellt skulle kunnat ha på grund av lag eller annan
          grund, inklusive Köplagen (1990:931) m.m. med anledning av
          tillhandahållandet av Auktionssajtenen och försäljningen av Objektet.
          Köparen friskriver således Säljaren och Skadeförebygg från allt ansvar
          för köprättsliga fel, faktiska och rättsliga fel och brister,
          rådighetsfel, samt eventuellt ansvar för dolda fel.
        </span>
      </p>
      <h3>3. Anbud och accept</h3>
      <p className="d-flex flex-row">
        <span>3.1.&nbsp;</span>
        <span>
          När en Köpare vinner en budgivning ska denna inom skälig tid erhålla
          ett meddelande (accept) att denne vunnit budgivningen samt att
          Säljaren och Köparen därmed har ingått avtal (”Auktionsavslut”).
          Köparen är genom inledandet av budgivning bunden av sitt bud (anbud)
          och efter Säljarens accept av det bindande avtalet, med undantag för
          det fall att Objektet väsentligen avviker från Objektsbeskrivningen.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>3.2.&nbsp;</span>
        <span>
          Säljaren har vid varje given tidpunkt rätt att, utan angivande av
          skäl, avböja anbudet samt anvisa en annan budgivare som vinnare av
          budgivningen.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>3.3.&nbsp;</span>
        <span>
          Köparen ska senast inom 10 dagar från att Säljaren meddelat att
          Köparen erhållit vinnande bud besiktiga/undersöka Objektet. Köparen
          ska inom ovan angivna tidsfrist meddela huruvida Köparen anser att
          Objektet väsentligen avviker från Objektsbeskrivningen. Därefter
          förfaller Köparens möjlighet att göra gällande en väsentlig avvikelse
          och därigenom häva köpet enligt punkt 3.1. Är Parterna eniga om att
          Objektet väsentligen avviker från Objektsbeskrivningen hävs Parternas
          avtal med den enda påföljden att Parternas samtliga prestationer
          återgår.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>3.4.&nbsp;</span>
        <span>
          Är Parterna oeniga angående huruvida Objektet väsentligen avviker från
          Objektsbeskrivningen ska frågan hänskjutas till en oberoende
          auktoriserad besiktningsman med sakkunskap avseende Objektet.
          Besiktningsmannen ska besiktiga Objektet inom 15 dagar från Köparens
          meddelande enligt punkt 3.3 och därefter lämna ett slutligt skriftligt
          och för Parterna bindande ställningstagande.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>3.5.&nbsp;</span>
        <span>
          Meddelar besiktningsmannen att Objektet väsentligen avviker från
          Objektsbeskrivningen hävs Parternas avtal med den enda påföljden att
          Parternas samtliga prestationer återgår. Lämnar Köparen ej sådant
          meddelande som nämnts ovan i punkt 3.3 eller godkänner den oberoende
          besiktningsmannen Objektet förfaller, som nämnts ovan, Köparens rätt
          till hävning. Parterna delar, oavsett besiktningsmannens
          ställningstagande, lika på kostnaden för besiktningsmannen.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>3.6.&nbsp;</span>
        <span>
          Köparen äger ej rätt att överlåta möjligheten att förvärva ett Objekt
          eller att anvisa annan budgivare att förvärva Objektet.
        </span>
      </p>
      <h3>4. Skadestånd</h3>
      <p className="d-flex flex-row">
        <span>4.1.&nbsp;</span>
        <span>
          En Köparen som väljer att avstå från att fullfölja köpet eller häver
          ett ingånget avtal utan giltig grund (punkt 3) skall ersätta Säljaren
          och Skadeförebygg för den skada som uppkommit till följd av att
          Köparen avstått från att fullfölja köpet/hävt avtalet utan giltig
          grund. Skadeståndet omfattar även indirekt skada samt eventuell
          mellanskillnad mellan den avböjande Köparens bud samt det pris som
          Säljaren slutligen försäljer Objektet för. Skadestånd enligt denna
          punkt ska alltid utgå med minst 15 procent av Objektets värde i
          enlighet med avstående/hävande Köpares vinnande bud.
        </span>
      </p>
      <h3>5. Betalning</h3>
      <p className="d-flex flex-row">
        <span>5.1.&nbsp;</span>
        <span>
          Avtalad Köpeskilling ska erläggas inom 30 dagar från Auktionsavslut
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>5.2.&nbsp;</span>
        <span>
          Först när Köpeskillingen till fullo erlagts övergår äganderätten till
          Köparen. Säljaren har såldes äganderättsförbehåll i Objektet till dess
          full betalning erlagts.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>5.3.&nbsp;</span>
        <span>
          Om betalningen inte erläggs enligt punkt 5.1 har Säljaren rätt att,
          utan att först underrätta Köparen, häva avtalet, återta Objektet samt
          försälja Objektet till annan budgivare till det utropspris som
          Säljaren finner lämpligt
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>5.4.&nbsp;</span>
        <span>
          Vid omförsäljning, enligt punkt 5.3, har Säljaren och Skadeförebygg
          rätt att från Köparen erhålla skadestånd i enlighet med punkt 4.1.
        </span>
      </p>
      <h3>6. Avhämtning och transport</h3>
      <p className="d-flex flex-row">
        <span>6.1.&nbsp;</span>
        <span>
          Avhämtning ska ske i samband med köpet av Objekt, det vill säga inom
          10 dagar från Auktionsavslutet, alternativt inom 10 dagar efter att
          besiktningsmannen lämnat sitt slutliga ställningstagande enligt punkt
          3.4, om inte Köparen och Säljaren skriftligen överenskommit annan
          avhämtningsdag. Köparen står risken för varan, såsom, men inte
          uteslutande, angående försämring, brand eller stöld, mellan
          Auktionsavslutet och avhämtningsdagen, med undantag för om Säljaren
          förfarit grovt vårdslöst.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>6.2.&nbsp;</span>
        <span>
          Säljaren eller Skadeförebygg ansvarar inte för transport såvida inte
          annat skriftligen överenskommits, och frakt ingår inte i förvärvet av
          Objektet.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>6.3.&nbsp;</span>
        <span>
          För Objekt som inte avhämtats inom 10 dagar från Auktionsavslutet,
          alternativt inom 10 dagar efter att besiktningsmannen lämnat sitt
          slutliga ställningstagande enligt punkt 3.4, utgår en förvaringshyra
          om 1 000 kr per Objekt och dag om inget annat har avtalats Parterna
          emellan. Om Köparen är förhindrad att hämta Objektet på utsatt datum
          och tid, måste Köparen kontakta Säljaren och ange skäl samt nytt datum
          för avhämtning. Avhämtas inte Objektet inom ovan stipulerad tid äger
          Säljaren rätt att häva köpet. Därutöver äger Säljaren och
          Skadeförebygg rätt till skadestånd i enlighet med punkt 4.1.
        </span>
      </p>
      <h3>7. Omregistrering och försäkring av Objekt</h3>
      <p className="d-flex flex-row">
        <span>7.1.&nbsp;</span>
        <span>
          Alla Objekt som är registrerade i svenskt bilregister eller annat
          register skall omregistreras på Köparen av Säljaren snarast, dock
          senast 14 kalenderdagar efter Auktionsavslut.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>7.2.&nbsp;</span>
        <span>
          Köparen ansvarar för att försäkra Objektet för det fall krav på
          försäkring finns enligt lag, förordning eller annan reglering.
        </span>
      </p>
      <h3>8. Force Majeure</h3>
      <p className="d-flex flex-row">
        <span>8.1.&nbsp;</span>
        <span>
          Om Parternas fullgörande av sina åtaganden enligt Villkoren
          väsentligen försvåras eller förhindras på grund av omständighet som
          Parterna inte kunnat råda över, såsom exempelvis allmän
          arbetskonflikt, krig, eldsvåda, blixtnedslag, terroristattack, ändrad
          myndighetsbestämmelse eller myndighetsingripande, ska detta utgöra
          befrielsegrund som medför befrielse från skadestånd och andra
          påföljder.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>8.2.&nbsp;</span>
        <span>
          Om Parternas fullgörande av Villkoren förhindras för längre tid än sex
          månader av befrielsegrund enligt denna punkt, får Part häva köpet, med
          den exklusiva påföljden att Parternas samtliga prestationer återgår.
        </span>
      </p>
      <h3>9. Ändring av Villkoren</h3>
      <p className="d-flex flex-row">
        <span>9.1.&nbsp;</span>
        <span>
          Skadeförebygg äger rätt att ändra dessa Villkor. Eventuella ändringar
          ska meddelas Parterna senast dagen innan budgivningen avseende
          Objektet påbörjats.
        </span>
      </p>
      <h3>10. Tillämplig lag och tvist</h3>
      <p className="d-flex flex-row">
        <span>10.1.&nbsp;</span>
        <span>
          Tvist om tolkningen eller tillämpningen av detta avtal skall i första
          hand avgöras genom medling enligt Stockholms Handelskammares
          Skiljedomsinstitut regler om medling i affärstvister. Om en sålunda
          utsedd medlare inte förmår lösa tvisten inom tre (3) månader efter det
          att medlingsförfarande inletts ska tvisten i stället på Parts begäran
          avgöras genom förenklat skiljeförfarande enligt lagen om
          skiljeförfarande. Antalet skiljemän ska vara en. Kan Parterna inte
          enas om vem som ska utses till skiljeman ska denne utses av
          Västsvenska Handelskammaren. Platsen för skiljeförfarandet ska vara
          Säljarens hemvist. Språket för förfarandet ska vara svenska.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>10.2.&nbsp;</span>
        <span>
          Det antecknas att tvist huruvida hävningsrätt föreligger för Köparen
          slutligen regleras enligt punkt 3.4.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>10.3.&nbsp;</span>
        <span>För tolkning av Villkoren ska svensk lag gälla.</span>
      </p>
      <p className="d-flex flex-row">
        <span>10.4.&nbsp;</span>
        <span>
          Medlings- och eller Skiljeförfaranden som äger rum enligt denna punkt,
          all information som avslöjas och all dokumentation som inges eller
          utfärdas av eller på uppdrag av Part eller skiljemännen under sådant
          förfarande samt alla överenskommelser, beslut och skiljedomar som
          fattas eller meddelas i anledning av sådant förfarande ska behandlas
          strikt konfidentiellt och inte användas för annat ändamål än för
          förfarandet i fråga eller verkställandet av sådant beslut eller sådan
          skiljedom och heller inte annars avslöjas för någon tredje man utan
          föregående skriftligt samtycke av den Part som informationen rör
          eller, vad gäller beslut och skiljedomar, de tvistande Parterna.
        </span>
      </p>
      <p>
        Dessa Villkor har accepterats av anslutna Länsförsäkringsbolag i
        enlighet med <a href="#attachment-1">Bilaga 1</a>.
      </p>
      <hr />
      <h1 id="attachment-1">Bilaga 1</h1>
      <h2>Anslutna Länsförsäkringsbolag</h2>
      <table className="table mt-1">
        <tbody>
          <tr>
            <td>
              Länsförsäkringar Skåne – ömsesidigt
              <br />
              Org. nr. 543001-0685
              <br />
              Stora Varvsgatan 11
              <br />
              211 74 Malmö
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
);
