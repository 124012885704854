import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks';

export const AdfsCallbackPage = observer(() => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const rootStore = useStore();

  const { authStore } = rootStore;

  const state = useLocalObservable(() => ({
    isSubmiting: false,
  }));

  const login = async () => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;

    try {
      await authStore.loginAdfs(searchParams.get('code'));
      navigate('/');
    } catch (error: any) {
      const { data } = error.response;
      navigate('/', { state: { errorData: data } });
    } finally {
      state.isSubmiting = false;
    }
  };

  useEffect(() => {
    if (searchParams.has('code')) {
      login();
    } else {
      navigate('/');
    }
  }, []);

  return <></>;
});
