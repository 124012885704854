import React, { forwardRef, InputHTMLAttributes, useEffect } from 'react';
import classNames from 'classnames';
// @ts-ignore
import { datepicker } from '@lansforsakringar/components/datepicker';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | false;
  id: string;
  setValue: any;
  defaultValue?: string;
  error?: string | false;
}

export const Datepicker = forwardRef<HTMLInputElement, Props>(
  ({ label, id, defaultValue, setValue, error, ...rest }, ref) => {
    useEffect(() => {
      // Programatically set the value for datepicker input
      const handleChange = (e: CustomEvent) => {
        setValue(id, e.detail.value, { shouldValidate: true });
      };

      const picker = datepicker(document.getElementById(id));
      picker.addEventListener('duetChange', handleChange, false);

      return () => {
        picker.removeEventListener('duetChange', handleChange);
      };
    }, []);

    useEffect(() => {
      if (defaultValue) {
        const pickerInput = document.getElementById(id);
        pickerInput?.setAttribute('value', defaultValue);
      }
    }, [defaultValue]);

    return (
      <fieldset className={classNames('mb-1', { 'has-danger': error })}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className="input-group">
          <input type="hidden" ref={ref} {...rest} />
          <input type="date" id={id} name={id} className="form-control" />
          <span className="input-group-append">
            <button
              className="btn"
              type="button"
              aria-controls={id}
              title="Välj datum"
            >
              <svg className="icon" width="26" height="26">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M19 7H5V5.33c0-.181.161-.326.345-.33H8v1h2V5h4v1h2V5h2.634c.205.004.366.149.366.33V7zm0 11.662a.331.331 0 01-.346.338H5.345A.33.33 0 015 18.662V9h14v9.662zM16 3V2h-2v1h-4V2H8v1H5.325C4.021 3.026 3 4.05 3 5.33v13.332A2.317 2.317 0 005.345 21h13.309A2.317 2.317 0 0021 18.662V5.33c0-1.28-1.021-2.304-2.346-2.33H16z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </span>
        </div>
        {error && <span className="form-control-feedback">{error}</span>}
      </fieldset>
    );
  },
);
