import React from 'react';
import { Breadcrumbs } from '../../components';

export const PersonalData = () => (
  <>
    <Breadcrumbs
      items={[
        {
          title: 'Hem',
          href: '/',
        },
      ]}
      active="Personuppgifter"
    />
    <div className="col-8 mx-auto">
      <h1>Personuppgifter</h1>
      <div className="lead mt-1">
        <p>
          Skaraborg Skadeförebygg värnar om din personliga integritet och
          skyddar alltid dina personuppgifter på bästa sätt. All vår behandling
          av dina personuppgifter sker i enlighet med tillämplig
          dataskyddslagstiftning. Som företrädare för verkstäder behandlar
          Skaraborg Skadeförebygg personuppgifter om dig. Denna information om
          vår personuppgiftsbehandling avser att bland annat förklara vilka
          personuppgifter som vi behandlar, för vilka ändamål samt vilka
          rättigheter du har.
        </p>
      </div>
      <h3>Ändamålsbeskrivning</h3>
      <p>
        Vi behandlar dina personuppgifter för att skapa och administrera ditt
        användarkonto på auktionssajten för restförsäljning som gör det möjligt
        för dig att ta del av de tjänster som erbjuds där. Vi behandlar även
        dina personuppgifter för att kommunicera med dig innan, under och efter
        en budgivning. Vi kan även komma att behandla dina personuppgifter för
        att fullgöra det uppdrag som åligger oss som motpart vid en försäljning.
      </p>
      <h3>Vilka uppgifter behandlas och hur?</h3>
      <p>
        För att kunna skapa och administrera ditt användarkonto på
        auktionssajten för restförsäljning behandlar vi ditt namn, personnummer
        och kontaktuppgifter såsom telefonnummer och e-postadress. Dessa
        uppgifter används även för att kunna kommunicera med dig innan, under
        och efter en budgivning samt för att verifiera dig som användare vid
        inloggningen.
      </p>
      <p>
        För att fullgöra det uppdrag som åligger oss som motpart vid en
        försäljning kan vi komma att behandla ditt namn och kontaktuppgifter
        såsom adress, telefonnummer och e-postadress. Vi kan även komma att
        behandla dit namn, personnummer och kontaktuppgifter såsom adress,
        telefonnummer och e-postadress för att hantera betalning och
        fakturering. Vi behandlar även ditt namn och kontaktuppgifter för att
        kunna hantera köp- och säljhistorik inklusive budhistorik.
      </p>
      <p>
        Vi hämtar dina personuppgifter från samarbetsparter inom
        försäkringsverksamheten inom Länsförsäkringsgruppen.
      </p>
      <h3>Laglig grund</h3>
      <p>
        Behandlingen av dina personuppgifter för ändamålet att skapa och
        administrera användarkonto på auktionssajt för restförsäljning och
        behandling av dina personuppgifter för ändamålet att fullgöra det
        uppdrag som åligger oss som motpart vid fullgörande av en försäljning är
        i utgångspunkt nödvändig för att vi ska kunna fullgöra avtalet med dig.
      </p>
      <h3>Hur länge sparar vi uppgifterna?</h3>
      <p>
        Behandlingen av dina personuppgifter för ändamålet att skapa och
        administrera användarkonto på auktionssajt för restförsäljning sker till
        dess att du inte längre är användare. Tre månader efter att avtalet
        upphör kommer dina personuppgifter att raderas.
      </p>
      <p>
        Dina personuppgifter för ändamålet att fullgöra det uppdrag som åligger
        oss som motpart vid fullgörande av en försäljning kommer att sparas i 10
        år (allmän preskription) efter att ett köp har genomförts.
      </p>
      <p>
        När tiden för lagring bestäms kommer hänsyn tas till vårt behov av att
        kunna visa att vi har följt gällande lagstiftning.
      </p>
      <h3>Dina rättigheter</h3>
      <p>
        Informationen om dina rättigheter följer av nedan. Du är välkommen att
        kontakta oss för att utöva någon av dina rättigheter. Våra
        kontaktuppgifter hittar du längre ner på sidan. När du vill utöva någon
        av dina rättigheter kan du behöva styrka din identitet.
      </p>
      <h3>Rätten till tillgång (registerutdrag)</h3>
      <p>
        Du har rätt att begära ett så kallat registerutdrag från oss. Om du gör
        det kommer du att få ett utdrag över de personuppgifter om dig som du
        har rätt att få tillgång till, tillsammans med information om hur vi
        behandlar dem. Ange gärna vilken/vilka behandlingar din begäran gäller.
      </p>
      <h3>Rätten till rättelse</h3>
      <p>
        Om vi har felaktiga personuppgifter om dig har du rätt att begära att vi
        rättar dem. Du har även rätt att begära att vi kompletterar dina
        personuppgifter om de är ofullständiga.
      </p>
      <h3>Rätten att invända</h3>
      <p>
        Du har rätt att när som helst invända mot behandling av dina
        personuppgifter som vi använder utifrån vårt (eller en
        samarbetspartners) berättigade intresse. Efter din invändning får vi
        bara fortsätta behandla dina uppgifter om vi kan visa på avgörande
        berättigade skäl som väger tyngre än dina intressen eller om vi behöver
        behandla uppgifterna för att fastställa, utöva eller försvara rättsliga
        anspråk.
      </p>
      <p>
        Du har också rätt att invända mot behandling av dina personuppgifter som
        vi, i förekommande fall, använder för statistiska ändamål.
      </p>
      <p>
        Du har också rätt att när som helst invända mot behandling av dina
        personuppgifter som vi, i förekommande fall, använder för direkt
        marknadsföring, inklusive profilering som har samband med sådan
        marknadsföring. Om du har invänt mot behandling för direkt
        marknadsföring får vi inte längre behandla dina uppgifter för sådana
        ändamål.
      </p>
      <h3>Rätten till radering (rätten att bli bortglömd)</h3>
      <p>Du har rätt att få dina personuppgifter raderade:</p>
      <ul>
        <li>
          Om personuppgifterna inte längre är nödvändiga för de ändamål som vi
          har behandlat dem för.
        </li>
        <li>
          Om du tar tillbaka ditt samtycke som du tidigare gett oss och vi inte
          har någon annan grund för behandlingen.
        </li>
        <li>
          Om du invänder mot en behandling som vi utför med stöd av ett
          berättigat intresse och vi saknar skäl som väger tyngre än ditt
          intresse.
        </li>
        <li>
          Om behandlingen sker för direktmarknadsföringsändamål och du invänder
          mot att dina uppgifter behandlas för det ändamålet.
        </li>
        <li>Om vi har behandlat personuppgifterna på ett olagligt sätt.</li>
        <li>Om radering krävs för att uppfylla en rättslig förpliktelse.</li>
      </ul>
      <p>
        Du har inte rätt till radering om vår behandling är nödvändig för att
        uppfylla en rättslig förpliktelse eller för att kunna fastställa, göra
        gällande eller försvara rättsliga anspråk.
      </p>
      <h3>Rätten till begränsning</h3>
      <p>
        Du har rätt att begära att vi begränsar vår behandling av dina
        uppgifter:
      </p>
      <ul>
        <li>
          Om du säger att dina personuppgifter inte stämmer kan du begära att vi
          begränsar vår behandling under tiden som vi kontrollerar om
          uppgifterna är korrekta.
        </li>
        <li>
          Om behandlingen är olaglig men du inte vill att uppgifterna raderas
          utan i stället begär att vi begränsar användningen av dem.
        </li>
        <li>
          Om vi inte längre behöver personuppgifterna för ändamålen med
          behandlingen men du behöver dem för att kunna fastställa, göra
          gällande eller försvara ett rättsligt anspråk.
        </li>
        <li>
          Om du har invänt mot en behandling som vi utför med stöd av ett
          berättigat intresse kan du begära att vi begränsar vår behandling
          under tiden som vi arbetar med att kontrollera om vårt berättigade
          intresse väger tyngre än ditt.
        </li>
      </ul>
      <p>
        De uppgifter som omfattas av en begränsning får vi – utöver att lagra
        dem – endast behandla för att fastställa, göra gällande eller försvara
        rättsliga anspråk, för att skydda någon annans rättigheter eller med
        stöd av ditt samtycke.
      </p>
      <h3>Rätten till dataportabilitet</h3>
      <p>
        Du har rätt att få ut de personuppgifter som du gett oss i ett
        strukturerat, allmänt använt och maskinläsbart format förutsatt att
        behandlingen grundar sig på samtycke eller fullgörande av avtal och att
        den sker automatiserat. Du har även rätt att få uppgifterna överförda
        direkt till en annan personuppgiftsansvarig om det är tekniskt möjligt.
      </p>
      <h3>Rätten att återkalla samtycke</h3>
      <p>
        Du har rätt att närsomhelst ta tillbaka ditt samtycke i de fall vi
        grundar vår behandling av dina uppgifter på ditt samtycke.
      </p>
      <h3>Rätten att klaga</h3>
      <p>
        Om du är missnöjd med hur vi behandlar dina personuppgifter är du
        välkommen att använda kontaktuppgifterna längre ner på sidan för att
        berätta för oss vad du är missnöjd med.
      </p>
      <p>
        Du har också rätt att klaga på vår behandling av dina personuppgifter
        till <a href="http://imy.se">Integritetsskyddsmyndigheten</a> (IMY). Om
        du bor eller arbetar i ett annat land än Sverige kan du vända dig till
        tillsynsmyndigheten i det landet.
      </p>
      <h3>Personuppgiftsansvar, biträden och övriga mottagare</h3>
      <p>
        Skaraborg Skadeförebygg AB, org. nr. 559322-3596 med adress Rådhusgatan
        8, 541 30 Skövde är personuppgiftsansvarig för behandlingen av dina
        personuppgifter.
      </p>
      <p>
        Vi anlitar ett personuppgiftsbiträde som erbjuder ett webbhotell för
        serverdrift och webbhosting av auktionssajten. Personuppgiftsbiträdet
        får endast behandla personuppgifter i enlighet med de ändamål och
        instruktioner som Skaraborg Skadeförebygg har lämnat för behandlingen.
        Personuppgiftsbiträdet får aldrig ta del av fler uppgifter än vad som
        krävs för utförande av den tjänst som avtalet med Skaraborg
        Skadeförebygg omfattar. Ett personuppgiftsbiträdesavtal finns upprättat
        med personuppgiftsbiträdet.
      </p>
      <p>Dina personuppgifter överförs inte till något land utanför EU/EES.</p>
      <p>
        Vi lämnar inte ut uppgifter om dig om det inte krävs enligt gällande
        lagstiftning eller om vi inte fått ditt uttryckliga samtycke.
      </p>
      <h3>Kontakta oss vid frågor</h3>
      <p>
        Har du frågor om dataskydd eller vill utöva någon av dina rättigheter är
        du välkommen att kontakta oss genom att skicka post till oss till nedan
        adress.
      </p>
      <p>
        Skaraborg Skadeförebygg
        <br />
        Rådhusgatan 8
        <br />
        541 30 Skövde
      </p>
      <h3>Ändringar i informationen om personuppgiftsbehandling</h3>
      <p>
        Skaraborg Skadeförebygg förbehåller sig rätten att göra ändringar i
        informationen om personuppgiftsbehandling när som helst i tiden i den
        utsträckning ändringarna är nödvändiga för att åtgärda störningar eller
        för att uppfylla nya legala eller tekniska krav. Alla ändringar kommer
        att publiceras på denna webbplats.
      </p>
    </div>
  </>
);
