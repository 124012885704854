import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
  Breadcrumbs,
  Checkbox,
  Input,
  PrimaryButton,
  RadioButton,
  RadioGroup,
} from '../components';
import { useStore } from '../hooks';

type FormData = {
  number: string;
  name: string;
  street: string;
  postal: string;
  city: string;
  is_staging_area: string;
  category_ids: string[];
  user: {
    first_name: string;
    last_name: string;
    email: string;
  };
};

export const AddServiceStationPage = observer(() => {
  const navigate = useNavigate();
  const {
    companyStore: { categoryOptions },
    serviceStationStore,
  } = useStore();

  const state = useLocalObservable<{
    isSubmiting: boolean;
    hasFormError: boolean;
    createdServiceStationId: string | null;
  }>(() => ({
    isSubmiting: false,
    hasFormError: false,
    createdServiceStationId: null,
  }));

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      is_staging_area: '0',
      category_ids: [],
    },
  });

  const onSubmit = handleSubmit(async ({ ...data }) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;
    state.hasFormError = false;

    try {
      data.category_ids = data.category_ids.filter((id) => id !== '');

      if (state.createdServiceStationId === null) {
        const serviceStation = await serviceStationStore.createServiceStation(
          data,
        );
        state.createdServiceStationId = serviceStation.id;
      }

      await serviceStationStore.createServiceStationUser(
        state.createdServiceStationId,
        data.user,
      );

      navigate('/service-stations');
    } catch (error: any) {
      state.hasFormError = true;

      const {
        data: { email },
      } = error.response;

      if (email) {
        setError('user.email', { message: email[0] });
      }
    } finally {
      state.isSubmiting = false;
    }
  });

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
    {
      title: 'Verkstäder',
      href: '/service-stations',
    },
  ];

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} active="Ny verkstad" />
      <h1 className="display-2 mt-15">Ny verkstad</h1>
      <div className="pt-2">
        <form onSubmit={onSubmit}>
          <div className="bg-white p-2">
            {state.hasFormError && (
              <div className="alert alert-danger" role="alert">
                <div className="alert-content">Ett fel inträffade.</div>
              </div>
            )}
            <div className="row">
              <div className="col col-6">
                <Input
                  type="text"
                  label="Verkstadsnummer *"
                  error={errors.number?.message}
                  {...register('number', {
                    required: 'Du måste ange verkstadsnummer',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Verkstadsnamn *"
                  error={errors.name?.message}
                  {...register('name', {
                    required: 'Du måste ange verkstadens namn',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input type="text" label="Gatuadress" {...register('street')} />
              </div>
              <div className="col col-3">
                <Input
                  type="number"
                  label="Postnummer"
                  error={errors.postal?.message}
                  {...register('postal', {
                    pattern: {
                      value: /^[0-9]{5}$/i,
                      message: 'Ange i formatet 12345',
                    },
                  })}
                />
              </div>
              <div className="col col-3">
                <Input type="text" label="Postort" {...register('city')} />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Kontaktperson förnamn *"
                  error={errors.user?.first_name?.message}
                  {...register('user.first_name', {
                    required: 'Du måste ange förnamn',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Kontaktperson efternamn *"
                  error={errors.user?.last_name?.message}
                  {...register('user.last_name', {
                    required: 'Du måste ange efternamn',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Kontaktperson e-post *"
                  error={errors.user?.email?.message}
                  {...register('user.email', {
                    required: 'Du måste ange e-postadress',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Ange en giltig e-post',
                    },
                  })}
                />
              </div>
              <div className="col col-6">
                <RadioGroup
                  label="Uppställningsplats *"
                  error={errors.is_staging_area?.message}
                >
                  <RadioButton
                    label="Ja"
                    value="1"
                    active={watch('is_staging_area') === '1'}
                    {...register('is_staging_area', {
                      required:
                        'Du måste välja om verkstaden är uppställningsplats',
                    })}
                  />
                  <RadioButton
                    label="Nej"
                    value="0"
                    active={watch('is_staging_area') === '0'}
                    {...register('is_staging_area', {
                      required:
                        'Du måste välja om verkstaden är uppställningsplats',
                    })}
                  />
                </RadioGroup>
              </div>
            </div>
            <span className="d-block label mb-05">Kategorier</span>
            <div className="category-container">
              {categoryOptions.map((category, index) => (
                <Checkbox
                  key={index}
                  label={category.title}
                  onChange={(e) => {
                    let category_ids = getValues('category_ids');

                    if (e.target.checked) {
                      category_ids.push(category.value);
                    } else {
                      category_ids = category_ids.filter(
                        (category_id) => category_id !== category.value,
                      );
                    }

                    setValue('category_ids', category_ids);
                  }}
                />
              ))}
            </div>
          </div>
          <div className="d-flex justify-content-between bg-white p-15 mt-025">
            <Link to="/service-stations">Tillbaka</Link>
            <PrimaryButton loading={state.isSubmiting} arrow>
              Spara
              <span className="d-block text-sm font-weight-normal font-base">
                och gå vidare
              </span>
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
});
