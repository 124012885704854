import React, { FC } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks';
import { SecondaryButton } from '../components';
import { ChevronDownIcon, GearIcon, UserHappy, UserIcon } from '../icons';
import { UserInstance } from '../models';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Link, useNavigate } from 'react-router-dom';
import { Role } from '../types';

interface UserDropdownProps {
  user: UserInstance;
}

const UserDropdown: FC<UserDropdownProps> = observer(({ user }) => {
  const {
    uiStore: { userMenuIsOpen, setUserMenuIsOpen },
    userStore: { me },
  } = useStore();
  const ref = useDetectClickOutside({
    onTriggered: () => setUserMenuIsOpen(false),
  });

  return (
    <div ref={ref} className="user-menu">
      <div
        className="user-menu-dropdown"
        onClick={() => setUserMenuIsOpen(!userMenuIsOpen)}
      >
        <UserIcon height={20} width={20} />
        <span className="font-sans-serif font-weight-bolder text-md pl-025 pr-05">
          {user.fullName}
        </span>
        <ChevronDownIcon
          className={classNames('ml-auto', {
            open: userMenuIsOpen,
          })}
          height={24}
          width={24}
        />
      </div>
      {userMenuIsOpen && (
        <div className="user-menu-content">
          <div className="bg-white border-top border-primary">
            <h2 className="popover-title mb-0 flex-grow-1">Personlig meny</h2>
            <div className="d-flex flex-column">
              <Link
                className="d-flex text-decoration-none"
                onClick={() => {
                  setUserMenuIsOpen(false);
                }}
                to="/my-account"
              >
                <div className="user-menu-item">
                  <UserHappy height={32} width={32} />
                  <span className="font-sans-serif text-lg text-decoration-none px-08">
                    Mitt konto
                  </span>
                </div>
              </Link>
              {(me!.role >= Role.CompanyAdmin ||
                me!.role == Role.ServiceStation ||
                me!.is_superuser) && (
                <Link
                  className="d-flex text-decoration-none"
                  onClick={() => {
                    setUserMenuIsOpen(false);
                  }}
                  to="/settings"
                >
                  <div className="user-menu-item">
                    <GearIcon height={32} width={32} />
                    <span className="font-sans-serif text-lg text-decoration-none px-08">
                      Inställningar
                    </span>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export const Header = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { logOut },
    companyStore: { company },
    userStore: { me },
  } = useStore();

  return (
    <div className="bg-white header">
      <div className="d-flex align-items-center justify-content-between container">
        <div className="d-flex align-items-center">
          <Link to="/">
            <img
              className="logo"
              src={company?.logo ?? require('../assets/logo-default.png')}
              alt={company?.name ?? 'Länsförsäkringar Restförsäljning'}
            />
          </Link>
        </div>
        <div className="d-flex align-items-center">
          {me ? (
            <>
              <UserDropdown user={me} />
              <SecondaryButton onClick={logOut} size="small">
                Logga ut
              </SecondaryButton>
            </>
          ) : (
            <SecondaryButton onClick={() => navigate('/')} size="small">
              Logga in
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
});
