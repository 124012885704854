import React, { FC, memo, SVGProps } from 'react';

export const FooterImage: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    width="1210"
    height="190"
    viewBox="0 0 1370 190"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M229.32 111.31C245.07 101.05 263.87 95.0898 284.07 95.0898C302.13 95.0898 319.07 99.8598 333.71 108.2"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M198.16 48.6006L209.63 40.3906L221.23 48.7306"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M214.26 52.791L221.23 57.811"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M198.16 57.671L204.98 52.791"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M198.16 66.751L209.63 58.541L221.23 66.891"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M209.63 58.541V73.671"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M153.45 56.5909L164.92 48.3809L176.53 56.7309"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M169.55 60.7812L176.53 65.8013"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M153.45 65.6712L160.28 60.7812"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M153.45 74.7412L164.92 66.5312L176.53 74.8812"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M164.92 66.5312V81.6613"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M238.12 88.6406H246.53"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M215.31 127.85H223.72"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1017.63 97.8597L1046 76.2197C1061.37 64.4997 1086.3 64.4997 1101.67 76.2197L1139.97 105.55H1266.48"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M995.62 80.9204L1006.77 72.4204C1012.81 67.8104 1022.6 67.8104 1028.64 72.4204L1039.31 80.5604"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M395.37 53.7098C396.89 51.9798 399.12 50.8898 401.61 50.8898C401.61 46.2998 405.33 42.5898 409.91 42.5898C414.49 42.5898 418.21 46.3098 418.21 50.8898C422.8 50.8898 426.51 54.6098 426.51 59.1898C426.51 63.7698 422.79 67.4898 418.21 67.4898H408.48"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M398.53 62.0402C404.82 62.0402 409.91 67.1402 409.91 73.4202C409.91 79.7002 404.81 84.8002 398.53 84.8002H375.77C369.48 84.8002 364.39 79.7002 364.39 73.4202C364.39 67.1402 369.49 62.0402 375.77 62.0402C375.77 55.7502 380.87 50.6602 387.15 50.6602C393.43 50.6602 398.53 55.7602 398.53 62.0402Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M909.2 151.66C901.07 151.66 894.48 158.25 894.48 166.38C894.48 174.51 901.07 181.1 909.2 181.1L1266.48 181.16"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M954.39 79.6797C974.27 79.6797 990.38 95.7897 990.38 115.67C990.38 135.55 974.27 151.66 954.39 151.66H909.2"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M62.21 181.16C38.62 181.16 19.5 162.04 19.5 138.45C19.5 114.86 38.62 95.7402 62.21 95.7402H115.85"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M669.44 97.5298C664.54 97.5298 660.57 93.5598 660.57 88.6598C660.57 83.7598 664.54 79.7898 669.44 79.7898L762.49 79.7598"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M676.24 133.539C686.18 133.539 694.24 125.479 694.24 115.539C694.24 105.599 686.18 97.5391 676.24 97.5391H669.4"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1309.86 105.551C1330.74 105.551 1347.67 122.481 1347.67 143.361C1347.67 164.241 1330.74 181.171 1309.86 181.171H1266.49"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M745.96 46.6484H753.12"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M742.07 71.5094H725.51L723.44 64.5594L727.03 46.6094H788.33L796.69 64.5594L791.11 71.5094H787.13"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M779.44 60.4H788.25L784.22 51.75H779.44V60.4Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M754.36 71.5093C754.36 72.1793 754.2 72.8093 753.93 73.3793C753.24 74.7793 751.8 75.7393 750.13 75.7393C747.79 75.7393 745.9 73.8393 745.9 71.5093C745.9 69.1693 747.79 67.2793 750.13 67.2793C752.47 67.2793 754.36 69.1793 754.36 71.5093V71.5093Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M783.8 71.5093C783.8 72.1793 783.64 72.8093 783.37 73.3793C782.68 74.7793 781.24 75.7393 779.57 75.7393C777.23 75.7393 775.34 73.8393 775.34 71.5093C775.34 69.1693 777.23 67.2793 779.57 67.2793C781.91 67.2793 783.8 69.1793 783.8 71.5093V71.5093Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M745.89 52.75H732.02V58.67H745.89V52.75Z"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M759.67 72.3492V51.6992H769.47V72.3492"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M181.94 165.99C181.94 171.26 177.67 175.53 172.4 175.53C167.13 175.53 162.86 171.26 162.86 165.99H181.93H181.94Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M162.87 165.54C162.87 161.14 166.44 157.57 170.84 157.57V165.54"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M181.64 165.991L186.09 161.541"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M170.49 177.461C170.49 178.981 169.26 180.211 167.74 180.211C166.22 180.211 164.99 178.981 164.99 177.461C164.99 175.941 166.22 174.711 167.74 174.711C169.26 174.711 170.49 175.941 170.49 177.461Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M179.98 177.461C179.98 178.981 178.75 180.211 177.23 180.211C175.71 180.211 174.48 178.981 174.48 177.461C174.48 175.941 175.71 174.711 177.23 174.711C178.75 174.711 179.98 175.941 179.98 177.461Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M198.96 153.021C198.64 155.611 196.29 157.441 193.7 157.131C191.11 156.811 189.28 154.461 189.59 151.871C189.91 149.281 192.26 147.451 194.85 147.761C197.44 148.081 199.27 150.431 198.96 153.021Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M198.65 155.131V179.371H195.77"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M193.3 157.16V179.37H190.48"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M198.62 155.34C198.62 155.34 204.22 158.03 204.22 161.54C204.22 165.05 199 168.66 199 168.66"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M184.43 161.241C184.43 161.241 191.11 165.221 193.3 157.961"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1310.11 105.551H1266.48"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M959.16 79.6797H690.6"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M99.83 161.51H138.81"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M99.8301 167.289H138.81"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M96.46 172.65H142.19"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M105.76 161.51V172.65"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M133.67 161.51V172.65"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M100.46 173.75V180.34"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M138.81 173.75V180.34"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M80.4299 95.7393H86.6599H94.2899H117.9H137.94C158.93 82.0593 184 73.7793 210.93 73.7793C232.43 73.7793 252.74 78.8493 270.74 87.8493"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M557.62 87.4391C558.75 84.7991 557.52 81.7391 554.88 80.6191"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M564.44 84.6992C561.8 83.5692 558.74 84.7992 557.62 87.4392"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M583.16 65.6998C583.55 61.2898 580.3 57.3998 575.9 57.0098"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M591.86 58.4399C587.45 58.0499 583.56 61.2999 583.17 65.6999"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1304.73 37.7617C1302.69 33.8417 1297.85 32.3117 1293.93 34.3617"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1308.14 26.9609C1304.22 29.0009 1302.69 33.8409 1304.74 37.7609"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1154.33 46.1107C1159.38 46.1107 1163.47 50.2007 1163.47 55.2507C1163.47 60.3007 1159.38 64.3907 1154.33 64.3907H1136.05C1131 64.3907 1126.91 60.3007 1126.91 55.2507C1126.91 50.2007 1131 46.1107 1136.05 46.1107C1136.05 41.0607 1140.14 36.9707 1145.19 36.9707C1150.24 36.9707 1154.33 41.0607 1154.33 46.1107V46.1107Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1209.2 26.6804C1215.84 26.6804 1221.23 32.0704 1221.23 38.7104C1221.23 45.3504 1215.84 50.7404 1209.2 50.7404H1185.14C1178.5 50.7404 1173.11 45.3504 1173.11 38.7104C1173.11 32.0704 1178.5 26.6804 1185.14 26.6804C1185.14 20.0404 1190.53 14.6504 1197.17 14.6504C1203.81 14.6504 1209.2 20.0404 1209.2 26.6804Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1019.05 130.939L1029.87 123.189L1040.81 131.069"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1034.23 134.889L1040.81 139.629"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1019.05 139.499L1025.49 134.889"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1019.05 148.059L1029.87 140.309L1040.81 148.189"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1029.87 140.309V154.589"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1137.61 137.98L1148.43 130.24L1159.38 138.11"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1152.8 141.939L1159.38 146.669"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1137.61 146.54L1144.05 141.93"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1137.61 155.109L1148.43 147.359L1159.38 155.229"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1148.43 147.359V161.629"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1101.6 120.72L1112.42 112.98L1123.37 120.85"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1116.79 124.68L1123.37 129.41"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1101.6 129.28L1108.04 124.67"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1101.6 137.85L1112.42 130.1L1123.37 137.97"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1112.42 130.1V144.37"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M902.09 102.861L898.09 106.861L902.09 110.861H923.43V102.861H902.09Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M920.65 97.3906V102.701"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M920.65 110.861V117.471"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M920.65 126.221V152.061"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M938.69 126.471L942.69 122.471L938.69 118.471H917.35V126.471H938.69Z"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1053.72 179.83L1057.04 172.28L1067.26 166.07L1078.05 169.74L1084.61 180.38"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M635.86 29.0712L628.22 23.2812"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M664.58 50.8113L656.95 45.0312"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M638.42 47.5918L632.64 55.2318"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M660.17 18.8711L654.38 26.5011"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M643.28 14.4609L644.59 23.9509"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M648.21 50.1504L649.52 59.6404"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M668.99 33.9219L659.5 35.2419"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M633.3 38.8613L623.81 40.1713"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M654.92 39.2751C656.146 34.5779 653.332 29.7761 648.635 28.55C643.938 27.3238 639.136 30.1376 637.91 34.8348C636.683 39.532 639.497 44.3338 644.194 45.56C648.892 46.7861 653.693 43.9723 654.92 39.2751Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M940.46 145.08C943.27 145.73 945.35 148.09 945.35 150.91"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M949.1 150.9C949.1 148.83 950.22 147.01 951.93 145.93"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M947.23 150.9V143.26"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1038.57 174.041C1035.76 174.691 1033.68 177.051 1033.68 179.871"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1029.93 179.861C1029.93 177.791 1028.81 175.971 1027.1 174.891"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1031.81 179.861V172.221"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M84.2401 180.471C85.8701 178.841 86.8901 176.581 86.8901 174.081C86.8901 169.091 82.8501 165.051 77.8601 165.051C72.8701 165.051 68.8301 169.091 68.8301 174.081C68.8301 176.211 69.5701 178.181 70.8101 179.721"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M239.12 168.85V181.2"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M227.81 157.541C227.81 163.791 232.88 168.861 239.13 168.861C242.12 168.861 244.85 167.701 246.87 165.801C249.07 163.731 250.45 160.801 250.45 157.541C250.45 151.291 245.38 146.221 239.13 146.221C232.88 146.221 227.81 151.291 227.81 157.541V157.541Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M250.55 172.01V181.2"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M243.39 168.03C244.88 170.42 247.53 172.02 250.55 172.02C252.78 172.02 254.8 171.16 256.31 169.74C257.95 168.2 258.97 166.02 258.97 163.59C258.97 158.94 255.2 155.17 250.55 155.17"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M242.48 67.6406V76.9006"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M233.99 59.1499C233.99 63.8399 237.79 67.6399 242.48 67.6399C244.73 67.6399 246.77 66.7699 248.28 65.3499C249.93 63.7999 250.96 61.5999 250.96 59.1599C250.96 54.4699 247.16 50.6699 242.47 50.6699C237.78 50.6699 233.98 54.4699 233.98 59.1599L233.99 59.1499Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M251.05 70.0098V78.8998"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M245.68 67.0191C246.79 68.8091 248.78 70.0091 251.05 70.0091C252.72 70.0091 254.24 69.3591 255.37 68.2991C256.6 67.1491 257.37 65.5091 257.37 63.6891C257.37 60.1991 254.54 57.3691 251.05 57.3691"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M289.26 158.59V170.94"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M277.94 147.269C277.94 153.519 283.01 158.589 289.26 158.589C292.25 158.589 294.98 157.429 297 155.529C299.2 153.459 300.58 150.529 300.58 147.269C300.58 141.019 295.51 135.949 289.26 135.949C283.01 135.949 277.94 141.019 277.94 147.269Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1066.77 68.0897V57.8097L1074.96 51.1797L1083.16 57.8097V68.0897"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1074.96 60.6094V68.0894"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M468.67 59.4105C468.67 59.4105 475.67 57.3105 489.8 57.3105C494.11 57.3105 497.75 57.5105 500.73 57.7805"
      stroke="#E30613"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M511.27 134.37V31.9902V32.3502"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M467.93 121.77H511.27"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M505.45 110.16H511.27"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M468.31 134.37V31.9902V32.3502"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M468.67 32.9496C468.67 32.9496 475.67 30.8496 489.8 30.8496C503.93 30.8496 510.93 32.9496 510.93 32.9496"
      stroke="#005AA0"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M502.17 50.9492C508.01 51.5692 510.92 52.4392 510.92 52.4392"
      stroke="#005AA0"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M468.67 45.0102C468.67 45.0102 475.67 42.9102 489.8 42.9102C495.53 42.9102 500.09 43.2602 503.48 43.6702"
      stroke="#005AA0"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M468.67 67.1195C468.67 67.1195 475.67 65.0195 489.8 65.0195C492.17 65.0195 494.34 65.0795 496.31 65.1795"
      stroke="#005AA0"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M478.29 132.63V127.83"
      stroke="#005AA0"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M487.55 132.63V127.83"
      stroke="#E30613"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M118.92 94.8094L113.08 53.4394C110.62 35.9994 85.4299 35.9994 82.9699 53.4394L77.1299 94.8094"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M101.22 40.1895L99.5799 29.5195H96.2899L94.6499 40.1895"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M94.3098 95.0499V75.4199H101.74V95.0499"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M107.26 95.0488V76.2188L111.86 78.0687L113.64 95.0488"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M88.86 95.0488V76.2188L84.26 78.0687L82.49 95.0488"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M748.44 136.219V122.609H825.46V136.219"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M836.2 134.609V118.279H845.2V134.609"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M803.57 122.299V108.369H809.11V122.299"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M799.94 137V128.67H805.49V137"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M756.2 130.158H760.54"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M766.95 130.158H771.3"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M777.7 130.158H782.05"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M788.45 130.158H792.8"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M813.36 130.158H817.7"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M733.77 137.43H811.12C851.64 137.43 878.14 128.67 878.14 128.67"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M733.77 143.649H811.12C851.64 143.649 878.14 134.889 878.14 134.889"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M738.91 150.84V144.43"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M874.23 137.229L872.7 150.799"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M736.58 136.39L726.5 116.6"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M722.54 118.85C715.83 122.06 713.47 120.54 709.99 124.14L712.52 131.95C716.5 128.55 719.53 129.57 725.9 126.31"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M476.81 149.869C470.37 149.869 470.37 154.119 463.93 154.119C457.49 154.119 457.49 149.869 451.05 149.869C444.61 149.869 444.61 154.119 438.17 154.119C431.73 154.119 431.73 149.869 425.29 149.869C418.85 149.869 418.85 154.119 412.41 154.119C405.97 154.119 405.97 149.869 399.53 149.869C393.09 149.869 393.09 154.119 386.65 154.119C380.21 154.119 380.21 149.869 373.77 149.869"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M901.79 154.119C895.35 154.119 895.35 149.869 888.91 149.869C882.47 149.869 882.47 154.119 876.03 154.119C869.59 154.119 869.59 149.869 863.15 149.869C856.71 149.869 856.71 154.119 850.27 154.119C843.83 154.119 843.83 149.869 837.39 149.869C830.95 149.869 830.95 154.119 824.51 154.119C818.07 154.119 818.07 149.869 811.63 149.869C805.19 149.869 805.19 154.119 798.75 154.119C792.31 154.119 792.31 149.869 785.87 149.869C779.43 149.869 779.43 154.119 772.99 154.119C766.55 154.119 766.55 149.869 760.11 149.869C753.67 149.869 753.67 154.119 747.23 154.119C740.79 154.119 740.79 149.869 734.35 149.869C727.91 149.869 727.91 154.119 721.46 154.119"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M721.54 154.119C715.1 154.119 715.1 149.869 708.66 149.869C702.22 149.869 702.22 154.119 695.78 154.119C689.34 154.119 689.34 149.869 682.9 149.869C676.46 149.869 676.46 154.119 670.02 154.119C663.58 154.119 663.58 149.869 657.14 149.869C650.7 149.869 650.7 154.119 644.26 154.119C637.82 154.119 637.82 149.869 631.38 149.869C624.94 149.869 624.94 154.119 618.5 154.119C612.06 154.119 612.06 149.869 605.62 149.869C599.18 149.869 599.18 154.119 592.74 154.119C586.3 154.119 586.3 149.869 579.86 149.869C573.42 149.869 573.42 154.119 566.98 154.119C560.54 154.119 560.54 149.869 554.1 149.869C547.66 149.869 547.66 154.119 541.21 154.119"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M541.2 154.119C534.76 154.119 534.76 149.869 528.32 149.869C521.88 149.869 521.88 154.119 515.44 154.119C509 154.119 509 149.869 502.56 149.869C496.12 149.869 496.12 154.119 489.68 154.119C483.24 154.119 483.24 149.869 476.8 149.869"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1299.64 94.1289L1268.77 93.0489L1249.45 74.3789L1284.22 81.2689L1299.64 94.1289Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1230.48 104.97V92.4498L1249.87 68.0098L1287.84 77.1898L1284.22 81.2698"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1230.48 94.9093L1219.68 85.0293L1234.05 87.9393"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1205.64 104.971V94.9307L1219.09 78.9707L1237.27 83.3607"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1299.64 104.639V94.1289"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1269.32 104.64V97.2402"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M858.72 78.8909V64.1109H855.76V50.1309L898.37 37.4609L906.31 41.3909H926.58"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M877.77 51.6007L898.37 45.4707L906.31 49.4007H926.58"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M892.32 38.8006V26.0906L898.37 24.1706L896.06 18.6406H912.05"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M905.92 14.9206L918.54 9.14062L919.75 14.7106L924.99 11.0806V18.6406H930.2L939.02 31.1306V47.5606H943.82V53.3606H949.65V58.9606"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M898.01 46.6602V62.0202H926.33L933.77 65.5002H949.65V78.0502"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M905.84 25.8711H912.38"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M915.65 25.8711H922.19"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M901.72 33.0801H908.26"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M911.53 33.0801H918.07"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M919.92 55.6309H926.46"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M448.64 133.539H676.46"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M574.24 133.27L559.47 122.42L544.7 133.27"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M559.47 118.35V133.82"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M559.47 138.32V151.86"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M559.39 115.13C561.29 115.13 562.83 113.59 562.83 111.69C562.83 109.79 561.29 108.25 559.39 108.25C557.49 108.25 555.95 109.79 555.95 111.69C555.95 113.59 557.49 115.13 559.39 115.13Z"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M615.8 133.27L601.03 122.42L586.26 133.27"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M601.03 118.35V133.82"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M601.03 138.32V151.86"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M600.95 115.13C602.85 115.13 604.39 113.59 604.39 111.69C604.39 109.79 602.85 108.25 600.95 108.25C599.05 108.25 597.51 109.79 597.51 111.69C597.51 113.59 599.05 115.13 600.95 115.13Z"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M657.32 133.27L642.55 122.42L627.78 133.27"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M642.55 118.35V133.82"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M642.55 138.32V153.86"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M642.47 115.13C644.37 115.13 645.91 113.59 645.91 111.69C645.91 109.79 644.37 108.25 642.47 108.25C640.57 108.25 639.03 109.79 639.03 111.69C639.03 113.59 640.57 115.13 642.47 115.13Z"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1053.91 103.65H1066.7"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1074.31 83.1895H1087.1"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1205.64 127.85H1218.43"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1183.64 134.039H1196.43"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1069.67 149.369H1082.46"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1177.24 164.279H1190.03"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1009.22 108.5H1022.01"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M983.99 159.51H996.78"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M924.43 168.66H937.22"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M646.4 173.75H659.19"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M597.04 165.99H609.83"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M461.92 174.891H474.71"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M435.7 168.85H448.49"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M249.58 115.131H262.37"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M349.58 124.039H362.37"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M959.66 118.35H972.45"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M291.26 110.48H304.05"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M322.61 153.039H335.4"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M46.75 115.67H59.54"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M187.08 89.9707H199.87"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M187.88 135.949H200.67"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M674.46 167.641H687.25"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M818.35 167.641H831.14"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M768.22 164.91H781.01"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M786.41 175.891H799.2"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M43.4099 157.16H56.1999"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M541.15 168.66H553.94"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M61.04 181.16H335.19"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M390.74 124.65C375.82 124.65 363.57 134.37 363.72 151.67V154.9C363.72 165.94 354.06 172.12 345.47 172.12H268.54"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M389.11 133.6C380.52 133.6 373.55 140.56 373.55 149.16V154.06C373.55 168.98 361.45 181.08 346.53 181.08H334.59"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M482.29 133.6H389.11"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M448.79 124.65H389.92"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1298.01 147.001C1298.01 150.021 1295.57 152.461 1292.55 152.461C1289.53 152.461 1287.09 150.021 1287.09 147.001C1287.09 143.981 1289.53 141.541 1292.55 141.541C1295.57 141.541 1298.01 143.981 1298.01 147.001Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1303.52 147.11C1303.52 141.11 1298.65 136.24 1292.65 136.24C1286.65 136.24 1281.45 141.11 1281.45 147.11H1275.91"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1273.55 151.952C1275.64 151.09 1276.62 148.703 1275.76 146.621C1274.9 144.54 1272.51 143.551 1270.43 144.413C1268.35 145.276 1267.36 147.662 1268.22 149.744C1269.09 151.826 1271.47 152.814 1273.55 151.952Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1269.55 144.531V135.281H1278.89L1283.38 125.271H1298.04V137.671"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1300.47 117.711C1299.13 117.711 1298.04 118.801 1298.04 120.141V130.371"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1289.2 126.781V132.101"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1277.29 156.031L1280.31 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1283.33 156.031L1286.35 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1289.37 156.031L1292.38 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1295.4 156.031L1298.42 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1301.44 156.031L1304.46 162.331"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1307.48 156.031L1310.5 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1313.51 156.031L1316.53 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1228.99 156.031L1232.01 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1235.03 156.031L1238.05 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1241.07 156.031L1244.08 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1247.1 156.031L1250.12 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1253.14 156.031L1256.16 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1259.18 156.031L1262.2 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1265.22 156.031L1268.23 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1271.25 156.031L1274.27 162.331"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1228.99 146.74L1232.01 153.04"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1235.03 146.74L1238.05 153.04"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1241.07 146.74L1244.08 153.04"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1247.1 146.74L1250.12 153.04"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1253.14 146.74L1256.16 153.04"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1259.18 146.74L1262.2 153.04"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M96.2901 140.8V138.28L90.9301 125.32L77.8001 120.85L68.3001 128.42H64.5901L60.3601 137.62V140.8"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M161.51 111.42L156.74 141.36"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M129.87 111.42L134.64 141.36"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M124.73 141.36L129.51 111.42H161.72L166.49 141.36"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M141.26 113.51V132.55"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M149.93 113.51V132.55"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M139.36 133.75C141.23 134.73 143.36 135.28 145.61 135.28C147.86 135.28 149.99 134.73 151.87 133.75"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1190.06 98.7598V105.46"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M1183.93 92.6302C1183.93 96.0202 1186.68 98.7702 1190.07 98.7702C1191.69 98.7702 1193.17 98.1402 1194.27 97.1102C1195.46 95.9902 1196.21 94.4002 1196.21 92.6302C1196.21 89.2402 1193.46 86.4902 1190.07 86.4902C1186.68 86.4902 1183.93 89.2402 1183.93 92.6302Z"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M271.31 95.6397H271.24V53.9197L278.1 43.9297L284.93 53.9197V69.3797H315.77V99.6397"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M278.14 38.2402C279.935 38.2402 281.39 36.7852 281.39 34.9902C281.39 33.1953 279.935 31.7402 278.14 31.7402C276.345 31.7402 274.89 33.1953 274.89 34.9902C274.89 36.7852 276.345 38.2402 278.14 38.2402Z"
      stroke="#E30613"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M277.75 54.4297V59.5997"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M293.51 77.5996V85.6296"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M299.94 77.5996V85.6296"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M306.35 77.5996V85.6296"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M278.14 95.6209V84.1109C278.14 82.2009 279.71 80.6309 281.62 80.6309C283.53 80.6309 285.1 82.2009 285.1 84.1109V95.6209"
      stroke="#005AA0"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </svg>
));
