import React, { FC, memo, SVGProps } from 'react';

export const UserHappy: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27 16.5C27 22.5753 22.0751 27.5 15.9997 27.5C9.92484 27.5 5 22.5752 5 16.5C5 10.4248 9.92484 5.5 15.9997 5.5C19.2477 5.5 22.2633 6.91399 24.3413 9.3287L25.8572 8.02413C23.4032 5.17246 19.8364 3.5 15.9997 3.5C8.82027 3.5 3 9.32021 3 16.5C3 23.6798 8.82027 29.5 15.9997 29.5C23.1796 29.5 29 23.6799 29 16.5C29 15.0385 28.7584 13.6086 28.2904 12.2543L27.8575 11.1134H18.7693C16.3392 11.1134 14.6683 10.4754 13.5754 9.4351C13.2083 9.08558 12.9351 8.7149 12.7421 8.34929C12.6341 8.14462 12.5797 8.00073 12.5656 7.948L10.633 8.46286C10.8072 9.11649 11.2789 10.0103 12.1965 10.8837C13.6696 12.286 15.8366 13.1134 18.7693 13.1134H26.4698C26.8167 14.18 27 15.3269 27 16.5Z"
      fill="#005AA0"
    />
    <path
      d="M20.2929 20.7929C19.1407 21.9451 17.6022 22.568 16 22.568C14.3978 22.568 12.8593 21.9451 11.7071 20.7929L10.2929 22.2071C11.8243 23.7385 13.873 24.568 16 24.568C18.127 24.568 20.1757 23.7385 21.7071 22.2071L20.2929 20.7929Z"
      fill="#005AA0"
    />
    <path
      d="M14.3921 16.763H12.3921C12.3921 16.63 12.2621 16.5 12.1291 16.5C11.9961 16.5 11.8661 16.63 11.8661 16.763H9.8661C9.8661 15.536 10.9021 14.5 12.1291 14.5C13.3551 14.5 14.3921 15.536 14.3921 16.763Z"
      fill="#005AA0"
    />
    <path
      d="M21.7246 16.763H19.7246C19.7246 16.63 19.5946 16.5 19.4616 16.5C19.3286 16.5 19.1986 16.63 19.1986 16.763H17.1986C17.1986 15.536 18.2346 14.5 19.4616 14.5C20.6876 14.5 21.7246 15.536 21.7246 16.763Z"
      fill="#005AA0"
    />
  </svg>
));
