import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { LogoWhite } from '../../../components';

interface Props {
  children: React.ReactNode;
}

export const LoginWrapper: FC<Props> = ({ children }) => {
  return (
    <div className="d-flex flex-column justify-content-between flex-grow-1 bg-primary p-2">
      <LogoWhite className="logo align-self-start" />
      <div className="login-wrapper d-flex align-self-center bg-white">
        <div className="w-50 p-1">{children}</div>
        <div className="login-image w-50"></div>
      </div>
      <div className="align-self-center text-sm">
        <Link
          target="_blank"
          className="text-decoration-none mr-1 text-white"
          to="/terms/personal-data"
        >
          Personuppgifter
        </Link>
        <Link
          target="_blank"
          className="text-decoration-none mr-1 text-white"
          to="/terms/terms-of-use"
        >
          Användarvillkor
        </Link>
        <Link
          target="_blank"
          className="text-decoration-none mr-1 text-white"
          to="/terms/purchase-conditions"
        >
          Allmänna köpvillkor
        </Link>
        <p className="d-inline-block text-white m-0">
          Vi använder inte kakor (cookies) på denna webbplats.
        </p>
      </div>
    </div>
  );
};
