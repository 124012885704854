import React, { FC, memo, SVGProps } from 'react';

export const CustomerSupportIcon: FC<SVGProps<SVGSVGElement>> = memo(
  (props) => (
    <svg
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9942 29.2797C14.0769 29.2797 12.5286 27.7089 12.5286 25.7783C12.5286 23.8478 14.0769 22.277 15.9942 22.277C17.9114 22.277 19.4598 23.8478 19.4598 25.7783C19.4598 27.7089 17.9114 29.2797 15.9942 29.2797ZM15.9942 27.2797C16.8003 27.2797 17.4598 26.6107 17.4598 25.7783C17.4598 24.946 16.8003 24.277 15.9942 24.277C15.188 24.277 14.5286 24.946 14.5286 25.7783C14.5286 26.6107 15.188 27.2797 15.9942 27.2797Z"
        fill="inherit"
      />
      <path
        d="M15.9942 3.70459C19.3496 3.70459 22.4895 5.20572 24.6465 7.74694L23.1217 9.04118C21.3389 6.94089 18.7531 5.70459 15.9942 5.70459C12.1127 5.70459 8.67038 8.12348 7.27922 11.6668L7.13786 12.162C6.81043 13.1031 6.63862 14.178 6.63862 15.2727C6.63862 16.6092 6.90773 17.9167 7.42276 19.1359L8.0096 20.525H6.31666C3.54209 20.525 1.31006 18.2606 1.31006 15.4603C1.31006 12.8937 3.1852 10.7772 5.63454 10.4419C7.4842 6.37753 11.4925 3.70459 15.9942 3.70459ZM3.31006 15.4603C3.31006 16.683 4.00311 17.7305 5.01014 18.2236C4.7648 17.2653 4.63862 16.2756 4.63862 15.2727C4.63862 14.4093 4.72829 13.5527 4.89921 12.7543C3.95257 13.2682 3.31006 14.2831 3.31006 15.4603Z"
        fill="inherit"
      />
      <path
        d="M24.7091 11.6668L26.6342 11.1246L26.7394 11.5048C27.1424 12.6632 27.3497 13.9599 27.3497 15.2727C27.3497 19.5705 24.9921 23.5121 21.3431 25.4678C20.473 25.9431 19.5228 26.2937 18.5489 26.5054L18.124 24.551C18.9136 24.3794 19.6839 24.0952 20.3913 23.7088C23.3973 22.0977 25.3497 18.8336 25.3497 15.2727C25.3497 14.178 25.1779 13.1031 24.8324 12.1045L24.7091 11.6668Z"
        fill="inherit"
      />
      <path
        d="M11.2068 8.21594L13.1382 7.69671C13.21 7.96402 13.4657 8.45024 13.9907 8.95534C14.9181 9.84748 16.3328 10.3957 18.3981 10.3957H25.7501V12.3957H18.3981C15.825 12.3957 13.9112 11.6541 12.6041 10.3967C11.7885 9.612 11.366 8.80847 11.2068 8.21594Z"
        fill="inherit"
      />
      <path
        d="M18.7955 17.8225L20.2198 19.2265C19.0702 20.3927 17.5309 21.0253 15.9325 21.0253C14.3341 21.0253 12.7948 20.3927 11.6452 19.2265L13.0696 17.8225C13.84 18.6041 14.865 19.0253 15.9325 19.0253C17 19.0253 18.025 18.6041 18.7955 17.8225Z"
        fill="inherit"
      />
      <path
        d="M25.6716 12.3957V10.3957C28.4462 10.3957 30.6782 12.66 30.6782 15.4603C30.6782 18.2606 28.4462 20.525 25.6716 20.525V18.525C27.3352 18.525 28.6782 17.1624 28.6782 15.4603C28.6782 13.7582 27.3352 12.3957 25.6716 12.3957Z"
        fill="inherit"
      />
    </svg>
  ),
);
