import React from 'react';
import { observer } from 'mobx-react-lite';

export const UploadPlaceholder = observer(
  ({
    file,
    removeable,
    onRemoveClick,
  }: {
    file: {
      name: string;
      data?: string;
      validation: string;
      type: string;
    };
    removeable?: boolean;
    onRemoveClick?: () => void;
  }) => {
    return (
      <div className="upload-placeholder mt-05">
        <div className={`upload-preview mb-05 ${file.validation ?? null}`}>
          <div className="upload-body">
            <span
              className="popover-tooltip-trigger p-0 b-0 mr-05"
              aria-controls="popover"
            >
              {file.type === 'image' ? (
                <img
                  className="upload-image"
                  src={file.data}
                  width="35"
                  height="35"
                />
              ) : (
                <div className="upload-image">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      fillRule="evenodd"
                    >
                      <path d="M6 3v26h20l-.007-20.914L20.915 3H6zm2 2h12.085l3.908 3.915L24 27H8V5z" />
                      <path d="M11 13h10v-2H11zm0 4h10v-2H11zm0 4h10v-2H11z" />
                    </g>
                  </svg>
                </div>
              )}
              <div
                role="alert"
                id="popover"
                className="popover-tooltip popover-bottom popover-attach"
              >
                <p className="popover-title">Preview</p>
                <div className="popover-content">
                  <div className="card">
                    <div className="card-block">
                      {file.type === 'image' ? (
                        <img className="img-fluid w-100" src={file.data} />
                      ) : (
                        <div className="img-fluid w-100">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              fillRule="evenodd"
                            >
                              <path d="M6 3v26h20l-.007-20.914L20.915 3H6zm2 2h12.085l3.908 3.915L24 27H8V5z" />
                              <path d="M11 13h10v-2H11zm0 4h10v-2H11zm0 4h10v-2H11z" />
                            </g>
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span className="upload-name">{file.name}</span>
            {removeable && (
              <button
                type="button"
                className="btn-close ml-auto upload-remove"
                aria-label="Remove"
                onClick={onRemoveClick}
              ></button>
            )}
          </div>
        </div>
      </div>
    );
  },
);
