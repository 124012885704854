import React, { FC, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | false;
  error?: string | false;
}

export const RadioGroup: FC<Props> = ({ label, children, error }) => {
  return (
    <fieldset className={classNames('mb-1', { 'has-danger': error })}>
      {label && <label>{label}</label>}
      <div
        className="btn-group btn-group-stretch"
        role="group"
        data-bs-toggle="buttons"
      >
        {children}
      </div>
      {error && <span className="form-control-feedback">{error}</span>}
    </fieldset>
  );
};
