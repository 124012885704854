import React from 'react';

export const LoginWarning = () => {
  return (
    <div className="login-warning text-sm d-flex align-items-center bg-cloud text-primary rounded mb-1">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0"
      >
        <path
          d="M16 8.5C16 6.29086 14.2091 4.5 12 4.5C9.79086 4.5 8 6.29086 8 8.5V10.5H6V8.5C6 5.18629 8.68629 2.5 12 2.5C15.3137 2.5 18 5.18629 18 8.5V10.5H20V22.4739H4V10.5H16V8.5ZM18 12.5H6V20.4739H18V12.5ZM12 13.5C12.8284 13.5 13.5 14.1716 13.5 15C13.5 15.3631 13.371 15.6961 13.1562 15.9556L14.5436 19.552H9.54361L10.8505 15.9637C10.6317 15.703 10.5 15.3669 10.5 15C10.5 14.1716 11.1716 13.5 12 13.5Z"
          fill="#005AA0"
        />
      </svg>
      <p className="mb-0">
        <strong className="text-md alert-header">Skydda din kod.</strong> Logga
        inte in om någon ringt och bett dig göra det.{' '}
        <a
          href="https://www.lansforsakringar.se/privat/om-oss/kontakta-oss/sidfot/juridik--sakerhet/sakerhet-pa-internet/bedragerier-pa-internet/"
          target="_blank"
          rel="noreferrer"
        >
          Läs mer
        </a>
      </p>
    </div>
  );
};
