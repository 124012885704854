import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumbs,
  Checkbox,
  Datepicker,
  PrimaryButton,
  Select,
  ValueBox,
} from '../components';
import { useStore } from '../hooks';
import { formatCurrency } from '../utils/formatCurrency';

export const HomePage = observer(() => {
  const navigate = useNavigate();

  const {
    companyStore: { companyOptions, categoryOptions },
    statisticsStore: { statistics, fetchStatistics, setCompany },
    userStore: { me },
    uiStore,
  } = useStore();

  const searchParams = new URLSearchParams(location.search);
  const { handleSubmit, register, getValues, setValue, reset } = useForm({
    values: {
      start_dt: searchParams.get('start_dt'),
      end_dt: searchParams.get('end_dt'),
      is_active: searchParams.get('is_active') || '',
      categories: searchParams.get('categories'),
    },
  });

  const state = useLocalObservable<{
    resetFilter: () => void;
  }>(() => ({
    resetFilter() {
      navigate(`${location.pathname}`, { replace: true });
      reset();
      fetchStatistics();
    },
  }));

  const handleFilter = handleSubmit((filters: any) => {
    const searchParams = new URLSearchParams();

    Object.keys(filters).forEach((filter: any) => {
      if (searchParams.has(filter)) {
        searchParams.delete(filter);
      }
      if (filters[filter]) {
        searchParams.append(filter, filters[filter]);
      }
    });

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    fetchStatistics();
  });

  useEffect(() => {
    fetchStatistics();
  }, []);

  if (me === null) return null;

  return (
    <div>
      <Breadcrumbs active="Hem" />
      <h1 className="display-2 mt-15">Hem</h1>
      <div className="pt-2">
        {me.is_superuser && (
          <div className="bg-white p-15 mb-025">
            <div className="row">
              <div className="col">
                {companyOptions.length > 0 && (
                  <Select
                    label="Bolag"
                    options={[
                      { value: '', title: 'Alla bolag' },
                      ...companyOptions,
                    ]}
                    onChange={(e) => setCompany(e.target.value)}
                    withoutMargin
                  />
                )}
              </div>
              <div className="col"></div>
            </div>
          </div>
        )}
        <div className="d-flex align-items-center bg-white px-15 py-08 mb-025">
          <div
            role="button"
            className="d-inline-flex align-items-center text-blue"
            onClick={() => uiStore.toggleFilterOpen()}
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-08"
            >
              <rect width="18" height="2" fill="#005AA0" />
              <rect x="3" y="6" width="12" height="2" fill="#005AA0" />
              <rect x="6" y="12" width="6" height="2" fill="#005AA0" />
            </svg>
            Filtrera
          </div>
        </div>
        {uiStore.isFilterOpen && (
          <form onSubmit={handleFilter} className="bg-white p-15 mb-025">
            <div className="row">
              <div className="col col-6">
                <Select
                  label="Status"
                  placeholder="Alla"
                  options={[
                    { value: '', title: 'Alla' },
                    { value: '1', title: 'Aktiv' },
                    { value: '0', title: 'Avslutad' },
                  ]}
                  {...register('is_active')}
                />
              </div>
              <div className="col col-6"></div>
              <div className="col col-6">
                <Datepicker
                  label="Startdatum"
                  id="start_dt"
                  setValue={setValue}
                  {...register('start_dt')}
                />
              </div>
              <div className="col col-6">
                <Datepicker
                  label="Slutdatum"
                  id="end_dt"
                  setValue={setValue}
                  {...register('end_dt')}
                />
              </div>
              <div className="col col-12">
                <span className="d-block label mb-05">Kategorier</span>
                <div className="category-container">
                  {categoryOptions.map((category, index) => (
                    <Checkbox
                      key={index}
                      label={category.title}
                      defaultChecked={
                        (getValues('categories') ?? '')
                          .split(',')
                          .filter((c) => c == category.value).length > 0 ??
                        false
                      }
                      onChange={(e) => {
                        const categories =
                          getValues('categories')?.split(',') ?? [];

                        if (e.target.checked) {
                          categories.push(category.value);
                        } else {
                          categories.splice(
                            categories.lastIndexOf(category.value),
                            1,
                          );
                        }

                        setValue('categories', categories.join(','));
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col d-flex align-items-center text-blue">
                <a
                  role="button"
                  className="text-decoration-underline mr-15"
                  onClick={() => uiStore.toggleFilterOpen()}
                >
                  Stäng
                </a>
                <a
                  role="button"
                  className="text-decoration-underline"
                  onClick={() => state.resetFilter()}
                >
                  Rensa
                </a>
                <PrimaryButton className="ml-auto">Filtrera</PrimaryButton>
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="row">
        <div className="col-4 pr-025">
          <ValueBox
            heading="Pågående auktioner"
            value={`${statistics?.ongoing_auctions ?? 0} st`}
          />
        </div>
        <div className="col-4 px-0">
          <ValueBox
            heading="Totalt utgångspris"
            value={formatCurrency(statistics?.start_bid_total ?? 0)}
          />
        </div>
        <div className="col-4 pl-025">
          <ValueBox
            heading="Total summa"
            value={formatCurrency(statistics?.winning_bid_total ?? 0)}
          />
        </div>
      </div>
    </div>
  );
});
