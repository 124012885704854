import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../../hooks';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Input, LoginLogoutButton } from '../../components';
import { LoginWrapper } from './components/LoginWrapper';
import { LoginWarning } from './components/LoginWarning';

type FormData = {
  code: number;
};

export const TwoFactorConfirmPage = observer(() => {
  const navigate = useNavigate();
  const rootStore = useStore();

  const { authStore } = rootStore;

  const state = useLocalObservable(() => ({
    isSubmiting: false,
    hasFormError: false,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async ({ code }) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;
    state.hasFormError = false;

    try {
      await authStore.loginTwoFactor(code);
      navigate('/');
    } catch (error) {
      state.hasFormError = true;
    } finally {
      state.isSubmiting = false;
    }
  });

  useEffect(() => {
    if (!authStore.two_factor_uuid) {
      navigate('/');
    }
  }, []);

  return (
    <LoginWrapper>
      <form onSubmit={onSubmit}>
        <div className="d-flex flex-column">
          <Input
            type="number"
            placeholder="Kod skickad till din e-post"
            {...register('code', {
              required: 'Fyll i detta fältet',
              pattern: {
                value: /^[0-9]{6,6}$/,
                message: 'Ange en giltig kod',
              },
            })}
            error={errors.code?.message}
          />
          {state.hasFormError && (
            <div className="alert alert-danger" role="alert">
              <p className="alert-content">
                Koden är inkorrekt eller gått ut. Var vänlig försök igen.
              </p>
            </div>
          )}
          <LoginWarning />
          <LoginLogoutButton className="text-lg">Logga in</LoginLogoutButton>
        </div>
      </form>
    </LoginWrapper>
  );
});
