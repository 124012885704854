import React, { ButtonHTMLAttributes, forwardRef, memo } from 'react';
import classNames from 'classnames';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'large';
  arrow?: boolean;
  loading?: boolean;
}

const ButtonSpinner = memo(() => (
  <svg viewBox="0 0 105 105" x="0" y="0" role="animation">
    <circle className="static-circle" r="45%" cx="50%" cy="50%"></circle>
    <circle className="lf-spinner-dash" r="45%" cx="50%" cy="50%"></circle>
  </svg>
));

export const PrimaryButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, size, arrow, loading, children, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          'btn btn-primary',
          {
            'btn-sm': size === 'small',
            'btn-lg': size === 'large',
            'btn-arrow': arrow,
            loading: loading,
          },
          className,
        )}
        {...rest}
      >
        {children}
        {loading && (
          <div className="lf-spinner-btn-primary">
            <ButtonSpinner />
          </div>
        )}
      </button>
    );
  },
);

export const SecondaryButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, size, arrow, loading, children, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          'btn btn-secondary',
          {
            'btn-sm': size === 'small',
            'btn-lg': size === 'large',
            'btn-arrow': arrow,
            loading: loading,
          },
          className,
        )}
        {...rest}
      >
        {children}
        {loading && (
          <div className="lf-spinner-btn-secondary">
            <ButtonSpinner />
          </div>
        )}
      </button>
    );
  },
);

export const LoginLogoutButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, children, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames('btn btn-login-logout', className)}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

export const LinkButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, children, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames('btn btn-link', className)}
        {...rest}
      >
        {children}
      </button>
    );
  },
);
