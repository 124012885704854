import React, { forwardRef, InputHTMLAttributes, useId } from 'react';
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | false;
  suffix?: string | false;
  error?: string | false;
  reg?: boolean;
}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ label, suffix, reg, error, ...rest }, ref) => {
    const id = useId();

    return (
      <fieldset className={classNames('mb-1', { 'has-danger': error })}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className="input-group">
          {reg && <span className="input-group-prepend-reg"></span>}
          <input id={id} className="form-control" ref={ref} {...rest} />
          {suffix && (
            <div className="input-group-append">
              <span className="input-group-text">{suffix}</span>
            </div>
          )}
        </div>
        {error && <span className="form-control-feedback">{error}</span>}
      </fieldset>
    );
  },
);
