export const errorMessageHandler = (data: { [key: string]: string }) => {
  let errorMessages: string[] = [];

  Object.keys(data).forEach((key: string) => {
    if (Array.isArray(data[key])) {
      errorMessages = [...data[key], ...errorMessages];
    } else {
      errorMessages.push(data[key]);
    }
  });

  return errorMessages;
};
