import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  items?: {
    title: string;
    href: string;
  }[];
  active: string;
}

export const Breadcrumbs: FC<Props> = ({ items, active }) => {
  return (
    <nav className="pt-1" aria-label="Breadcrumb">
      <ol className="breadcrumb breadcrumb-transparent">
        <>
          {items &&
            items.map((item, index) => (
              <li key={index} className="breadcrumb-item">
                <Link to={item.href}>{item.title}</Link>
              </li>
            ))}
          <li className="breadcrumb-item active" aria-current="page">
            {active}
          </li>
        </>
      </ol>
    </nav>
  );
};
