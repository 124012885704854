import { flow, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { Statistics, StatisticsInstance } from './Statistics';

export const StatisticsStore = t
  .model('StatisticsStore', {
    company: t.maybeNull(t.string),
    statistics: t.maybeNull(Statistics),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;

    return {
      fetchStatistics: flow(function* (nextPage = false) {
        const searchParams = new URLSearchParams(location.search);

        const promise = request({
          method: 'GET',
          url: `/api/statistics?${searchParams.toString()}`,
          params: self.company && {
            company: self.company,
          },
        });

        const { data } = yield promise;
        const statistics: StatisticsInstance = data;

        self.statistics = statistics;
      }),
    };
  })
  .actions((self) => ({
    setCompany: (company: string) => {
      self.company = company;
      self.fetchStatistics();
    },
  }));
