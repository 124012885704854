import React, { FC, memo, SVGProps } from 'react';

export const ChevronDownIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0999 10.514L16.6859 9.09998L12.6869 13.1L8.68585 9.09998L7.27185 10.514L11.2719 14.515L12.6869 15.928L14.0999 14.515L18.0999 10.514Z"
      fill="inherit"
    />
  </svg>
));
