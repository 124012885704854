import React, { createRef, FC, useEffect } from 'react';

interface Props {
  heading: string;
  value: string;
}

export const ValueBox: FC<Props> = ({ heading, value }) => {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    const box = ref?.current;
    const svg = box?.getElementsByTagName('svg')[0];
    const text = svg?.getElementsByTagName('text')[0];
    const width = text?.getBBox().width || text?.getComputedTextLength();
    const height = 18;

    svg?.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }, [ref]);

  return (
    <div ref={ref} className="value-box bg-white mb-1">
      <div className="value-box-heading">{heading}</div>
      <svg className="h1 value-box-text" fill="currentColor">
        <text x="50%" y="16" fontSize="16" style={{ textAnchor: 'middle' }}>
          {value}
        </text>
      </svg>
    </div>
  );
};
