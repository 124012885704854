import React from 'react';
import { Breadcrumbs } from '../../components';

export const TermsOfUse = () => (
  <>
    <Breadcrumbs
      items={[
        {
          title: 'Hem',
          href: '/',
        },
      ]}
      active="Användarvillkor"
    />
    <div className="col-8 mx-auto">
      <h1>Användarvillkor</h1>
      <h3>1. Inledning</h3>
      <p className="d-flex flex-row">
        <span>1.1.&nbsp;</span>
        <span>
          Dessa användarvillkor (”Villkoren”) är tillämpliga för företag eller
          annan juridisk person (”Kunden”) avseende användning av auktionssajten
          för restförsäljning www.LFrest.se (”Auktionssajten”) samt för de
          tjänster, exempelvis budgivningsmöjlighet, (”Tjänsterna”) på
          Auktionssajten som förvaltas av Skaraborg Skadeförebygg AB,
          559322-3596, (”Leverantören”).
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>1.2.&nbsp;</span>
        <span>
          Kunden och Leverantören är nedan var för sig benämnda (”Part”) samt
          gemensamt (”Parterna”).
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>1.3.&nbsp;</span>
        <span>
          Om Kunden inte accepterar att bli bunden av dessa Villkor kan inte
          Kunden använda eller få tillgång till Tjänsterna eller Auktionssajten
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>1.4.&nbsp;</span>
        <span>
          Genom att acceptera Villkoren förbinder sig Kunden samt dess
          företrädare att följa Villkoren, och bekräftar att denne tagit del av
          informationen om behandling av personuppgifter. Vid var tid uppdaterad
          information om Leverantörens behandling av personuppgifter finns på
          Auktionssajten.
        </span>
      </p>
      <h3>2. Registrering</h3>
      <p className="d-flex flex-row">
        <span>2.1.&nbsp;</span>
        <span>
          Kunden registreras och loggar in för att kunna nyttja Auktionssajten
          och Tjänsterna. Det krävs att en fysisk person med behörighet att
          binda företaget till Villkoren anges som kontaktperson.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>2.2.&nbsp;</span>
        <span>
          Att registreras som Kund på Auktionssajten är gratis. Efter att Kunden
          har registrerats på Auktionssajten erhåller Kunden ett användarkonto.
          En aktiveringskod skickas till Kunden via mejl. Kunden ska ange
          korrekta företagsuppgifter och kontaktuppgifter samt hålla
          informationen uppdaterad. Kunden ansvarar för felaktigt ifyllda
          uppgifter.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>2.3.&nbsp;</span>
        <span>
          Auktionssajten är inte tillgänglig för, och får inte användas av,
          personer under 16 år eller som av annan orsak har begränsad
          rättshandlingsförmåga eller av Leverantören temporärt eller definitivt
          avstängda Kunder.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>2.4.&nbsp;</span>
        <span>
          Kunden är ansvarig för alla vidtagna handlingar som utförs under
          inloggning i Kundens namn och lösenord. Kunden har endast rätt att
          använda Auktionssajten genom att använda eget login-namn eller
          e-postadress och lösenord. Kunden ska på bästa sätt tillse att
          lösenordet hålls hemligt och att det inte kommer till utomstående
          persons kännedom. Kunden får inte heller tillåta, direkt eller
          indirekt, att utomstående använder dennes login-namn, e-postadress
          eller lösenord. Lösenordet måste omedelbart ändras om det finns skäl
          att misstänka att informationen har avslöjats eller på annat sätt
          missbrukats. Kunden ska även omgående anmäla till Leverantören om det
          kan misstänkas att obehörig känner till Kundens lösenord.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>2.5.&nbsp;</span>
        <span>
          Leverantören är inte ansvarig för förluster orsakade till följd av
          otillåten användning av Kundens inloggningsuppgifter eller
          användarkonto om inte Leverantören gjort sig skyldig till försummelse.
        </span>
      </p>
      <h3>3. Auktionerna och budgivning</h3>
      <p className="d-flex flex-row">
        <span>3.1.&nbsp;</span>
        <span>
          En Kund kan logga in och lägga ett bud på en auktion på Auktionssajten
          när som helst fram tills auktionen stänger. Det kostar ingenting för
          en Kund att lägga ett bud.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>3.2.&nbsp;</span>
        <span>
          För varje objekt finns ett angivet startdatum samt starttid och
          slutdatum samt sluttid för när aktuell auktion börjar samt slutar. Om
          ett nytt bud läggs inom angivet slutdatum och sluttid så förlängs
          tiden för auktionen med av Säljaren angiven ny sluttid och därefter
          fortsätter auktionen. Har inget bud inkommit efter senast förlängda
          sluttid så är auktionen avslutad. Auktionen är alltså rörlig och kan
          inte förutses.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>3.3.&nbsp;</span>
        <span>
          För vissa Auktionsobjekt finns det ibland ett lägsta pris som Säljaren
          är villig att sälja Auktionsobjektet för. Kunden ska lägga ett bud som
          motsvarar det högsta belopp som Kunden är villig att betala för
          objektet.
        </span>
      </p>
      <h3>4. Missbruk</h3>
      <p className="d-flex flex-row">
        <span>4.1.&nbsp;</span>
        <span>
          Om Leverantören har anledning att misstänka att Kunden missbrukar sitt
          användarkonto eller sina inloggningsuppgifter eller annars bryter mot
          bestämmelserna i dessa Villkor har Leverantören rätt att efter eget
          tycke och utan att i förväg meddela Kunden; (i) säga upp Kundens
          användarkonto; (ii) begränsa Kundens rätt att utnyttja Auktionssajten
          genom att förhindra inloggning och/eller (iii) omöjliggöra tillgång
          till Auktionssajten.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>4.2.&nbsp;</span>
        <span>
          Leverantören förbehåller sig även rätten att begränsa eller förbjuda
          tillgången till och användningen av Tjänsterna, stoppa bud, fördröja
          eller ta bort innehåll som finns på Auktionssajten och vidta tekniska
          samt rättsliga åtgärder för att hindra Kunden från att använda
          Tjänsterna om Leverantören misstänker att Kunden missbrukar
          Tjänsterna, exempelvis genom att lägga bud på Auktionsobjekt som
          Kunden inte avser köpa, manipulerar budgivningen eller på egen hand
          söker upp uppdragsgivaren i försök att avsluta affären.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>4.3.&nbsp;</span>
        <span>
          Leverantören har även rätt att oavsett anledning skicka ut begäran
          till Kunden om att byta inloggningsuppgifter.
        </span>
      </p>
      <h3>5. Tryck och skrivfel samt eventuella tekniska problem</h3>
      <p className="d-flex flex-row">
        <span>5.1.&nbsp;</span>
        <span>
          Leverantören har rätt att bestämma om budgivningen ska göras om på
          grund av oklarheter om sista bud, tekniska problem, felskrivningar
          eller andra orsaker. Vid tekniska problem och missöden på
          Auktionssajten, som medför att rättvis budgivning inte kan genomföras,
          förbehåller sig Leverantören rätten att ensidigt besluta om att
          auktionen ska avbrytas och återupptas vid senare tillfälle. Aktuella
          budgivare meddelas i förekommande fall.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>5.2.&nbsp;</span>
        <span>
          Leverantören kan inte garantera att Auktionssajten kommer att vara
          felfri eller ostörd, att fel kommer åtgärdas eller att Auktionssajten
          eller de servrar som tillgängliggör Auktionssajten är fria från
          skadliga komponenter. Leverantören ansvarar inte för skada eller
          förlust eller följdskador som kan åsamkas Kunden, på grund av tekniska
          fel, kommunikationsproblem eller liknande omständigheter.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>5.3.&nbsp;</span>
        <span>
          Auktionssajten kan komma att drabbas av driftstörningar enligt ovan.
          Leverantören förbehåller sig rätten att senarelägga datum och tid för
          auktionsavslut efter oväntade driftstörningar, inkluderat men inte
          begränsat till fel i internetuppkoppling till server, dataintrång
          eller serviceavbrott hos leverantör. Leverantören ansvarar således
          inte för eventuell överbelastning på Auktionssajten och dess
          konsekvenser. Leverantören kan därför inte ansvara för om ett bud inte
          registreras, kontakt inte etableras efter budgivningen, fel budgivare
          kontaktas eller om Auktionsobjekt sålts till annan än högste
          budgivare. Vidare kan Leverantören inte ansvara för fel eller skador
          som uppstår till följd av felaktigt eller obehörigt nyttjande av
          användarkonto eller lösenord.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>5.4.&nbsp;</span>
        <span>
          Ovanstående gäller dock inte om grov vårdslöshet eller uppsåt
          föreligger. Denna friskrivning begränsar inte Leverantörens ansvar
          enligt tvingande lagstiftning.
        </span>
      </p>
      <h3>6. Särskild ansvarsbegränsning</h3>
      <p className="d-flex flex-row">
        <span>6.1.&nbsp;</span>
        <span>
          Leverantören ansvarar inte för direkt eller indirekt skada som kan
          drabba en Kund. Denna begränsning gäller dock inte om grov vårdslöshet
          eller uppsåt föreligger. Leverantörens ansvar är vidare under alla
          omständigheter begränsat till en summa motsvarande vad Kunden har
          betalt eller skulle ha betalat för de Tjänster som Leverantören
          tillhandahållit till Kunden.
        </span>
      </p>
      <h3>7. Personuppgiftsbehandling</h3>
      <p className="d-flex flex-row">
        <span>7.1.&nbsp;</span>
        <span>
          Leverantören åtar sig att behandla personuppgifter i enlighet med vid
          var tid tillämplig Dataskyddslagstiftning vilket inkluderar GDPR
          (General Data Protection Regulation ((Dataskyddsförordningen)),
          tillämplig svensk lag samt till dessa hörande förordningar och
          förskrifter och riktlinjer som utfärdats av tillsynsmyndighet.
        </span>
      </p>
      <h3>8. Immateriella rättigheter</h3>
      <p className="d-flex flex-row">
        <span>8.1.&nbsp;</span>
        <span>
          Auktionssajten och Tjänsterna, dess innehåll och samtliga immateriella
          rättigheter som skyddar dessa ägs av Leverantören. Auktionssajten och
          dess innehåll skyddas av upphovsrätt och databasskydd (katalogskydd).
          Otillåten användning eller kopiering av hela eller delar av
          Auktionssajten samt Tjänsterna kan utgöra olovligt immaterialrättsligt
          intrång och resultera i skadeståndsansvar.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>8.2.&nbsp;</span>
        <span>
          Länsförsäkringar, LF samt därtill relaterade logotyper och ordmärken
          utgör Leverantörens varumärken. Användning av varumärkena får endast
          ske efter Leverantörens skriftliga godkännande och efter sådant
          godkännande endast i enlighet med Leverantörens instruktioner.
        </span>
      </p>
      <h3>9. Force Majeure</h3>
      <p className="d-flex flex-row">
        <span>9.1.&nbsp;</span>
        <span>
          Om Parternas fullgörande av sina åtaganden enligt Villkoren
          väsentligen försvåras eller förhindras på grund av omständighet som
          Parterna inte kunnat råda över, såsom exempelvis allmän
          arbetskonflikt, krig, eldsvåda, blixtnedslag, terroristattack, ändrad
          myndighetsbestämmelse eller myndighetsingripande, ska detta utgöra
          befrielsegrund som medför befrielse från skadestånd och andra
          påföljder.
        </span>
      </p>
      <h3>10. Avtalstid</h3>
      <p className="d-flex flex-row">
        <span>10.1.&nbsp;</span>
        <span>
          Dessa Villkor träder i kraft när Kunden har registrerats och loggat in
          på Auktionssajten och gäller tills vidare. En Kund som har avstängts
          från Auktionssajten till följd av brott mot Villkoren har inte rätt
          att återregistrera sig eller använda Auktionssajten respektive
          Tjänsterna via en annan Kunds inloggning, utan Leverantörens
          föregående samtycke. Leverantören har rätt att, efter eget
          gottfinnande, besluta om att upphöra med att tillhandahålla
          Auktionssajten och Tjänsterna eller del av dessa.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>10.2.&nbsp;</span>
        <span>
          Kunden har rätt att när som helst sluta använda tjänsten på
          Auktionssajten genom att meddela Leverantören. Vid uppsägning
          fortsätter Villkoren att gälla för sådana handlingar, exempelvis
          budgivning, som Kunden genomfört innan uppsägningen.
        </span>
      </p>
      <h3>11. Överlåtelse</h3>
      <p className="d-flex flex-row">
        <span>11.1.&nbsp;</span>
        <span>
          Leverantören har rätt att överlåta det avtal som regleras av dessa
          Villkor mellan Leverantören och Kunden inklusive, men inte begränsat
          till, samtliga eller delar av därmed sammanhängande rättigheter
          och/eller skyldigheter, till tredje man. I händelse av överlåtelse
          kommer Leverantören att meddela vem som har tagit över Villkoren,
          rättigheterna och/eller skyldigheterna efter överlåtelsen genom
          meddelande på Auktionssajten. Om Leverantören överlåter Villkoren till
          tredje man har denne rätt att tillhandahålla samma eller liknande
          tjänster på en annan webbsida. En Kund kan inte överlåta sina
          rättigheter och skyldigheter enligt Villkoren till tredje man.
        </span>
      </p>
      <h3>12. Ändring av Villkoren</h3>
      <p className="d-flex flex-row">
        <span>12.1.&nbsp;</span>
        <span>
          Leverantören kan komma att ändra Villkoren, Tjänsterna samt innehållet
          på Auktionssajten från tid till annan. Vid betydande förändringar av
          dessa Villkor kommer Kunden informeras innan denne loggar in på
          Auktionssajten. Detta gäller dock inte om ändringen beror på
          förändringar i lag, författning eller myndighetsbeslut, om ändringen
          måste gälla omgående.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>12.2.&nbsp;</span>
        <span>
          Leverantören rekommenderar att Kunden håller sig uppdaterad på
          Auktionssajten regelbundet för att bli varse om eventuella ändringar
          av Villkoren.
        </span>
      </p>
      <h3>13. Tillämplig lag och tvist</h3>
      <p className="d-flex flex-row">
        <span>13.1.&nbsp;</span>
        <span>
          Tvist rörande tolkningen eller tillämpningen av dessa Villkor och
          Auktionssajten i övrigt ska tolkas i enlighet med svensk lag och i
          första hand lösas genom förhandlingar mellan Parterna.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>13.2.&nbsp;</span>
        <span>
          Tvist om tolkningen eller tillämpningen av dessa Villkor skall i
          första hand avgöras genom medling enligt Stockholms Handelskammares
          Skiljedomsinstitut regler om medling i affärstvister. Om en sålunda
          utsedd medlare inte förmår lösa tvisten inom tre (3) månader efter det
          att medlingsförfarande inletts ska tvisten i stället på Parts begäran
          avgöras genom förenklat skiljeförfarande enligt lagen om
          skiljeförfarande. Antalet skiljemän ska vara en. Kan Parterna inte
          enas om vem som ska utses till skiljeman ska denne utses av
          Västsvenska Handelskammaren. Platsen för skiljeförfarandet ska vara
          Skövde. Språket för förfarandet ska vara svenska.
        </span>
      </p>
      <p className="d-flex flex-row">
        <span>13.3.&nbsp;</span>
        <span>För tolkning av Villkoren ska svensk lag gälla.</span>
      </p>
      <p className="d-flex flex-row">
        <span>13.4.&nbsp;</span>
        <span>
          Medlings- och eller Skiljeförfaranden som äger rum enligt denna punkt,
          all information som avslöjas och all dokumentation som inges eller
          utfärdas av eller på uppdrag av Part eller skiljemännen under sådant
          förfarande samt alla överenskommelser, beslut och skiljedomar som
          fattas eller meddelas i anledning av sådant förfarande ska behandlas
          strikt konfidentiellt och inte användas för annat ändamål än för
          förfarandet i fråga eller verkställandet av sådant beslut eller sådan
          skiljedom och heller inte annars avslöjas för någon tredje man utan
          föregående skriftligt samtycke av den Part som informationen rör
          eller, vad gäller beslut och skiljedomar, de tvistande Parterna.
        </span>
      </p>
      <p>
        Dessa Villkor har fastställts av Skaraborg Skadeförebygg AB den 6 april
        2023.
      </p>
    </div>
  </>
);
