import { observer } from 'mobx-react-lite';
import React from 'react';
import { Breadcrumbs, Checkbox } from '../components';
import { useStore } from '../hooks';
import { Role } from '../types';

export const MyAccountPage = observer(() => {
  const {
    serviceStationStore: { my_service_station },
    companyStore: { company },
    userStore: { me },
  } = useStore();

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
  ];

  if (me === null || company === null) return null;
  if (me.role === Role.ServiceStation && my_service_station === null)
    return null;

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} active="Mitt konto" />
      <h1 className="display-2 mt-15">Mitt konto</h1>
      <div className="row no-gutters pt-2">
        {me.role >= Role.CaseManager ? (
          <>
            <div className="col col-5 bg-white p-15 mb-15 mr-2">
              <h2 className="display-4 mb-1">Mitt bolag</h2>
              <div className="d-flex flex-column">
                <h3 className="text-black mb-1">{company.name}</h3>
                <div className="contact-data">
                  {company.email && (
                    <div className="py-05">
                      <p className="font-weight-bolder font-sans-serif m-0">
                        E-postadress
                      </p>
                      <p className="m-0">{company.email}</p>
                    </div>
                  )}
                  {company.street && company.postal && company.city && (
                    <div className="w-100 py-05">
                      <p className="font-weight-bolder font-sans-serif m-0">
                        Postadress
                      </p>
                      <p className="m-0">{company.street}</p>
                      <p className="m-0">
                        {company.postal} {company.city}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col col-5 bg-white p-15 mb-15">
              <h2 className="display-4 mb-1">Min användare</h2>
              <div className="d-flex flex-column">
                <h3 className="text-black mb-1">
                  {me.first_name || '-'} {me.last_name || '-'}
                </h3>
                <div className="contact-data">
                  <div className="py-05">
                    <p className="font-weight-bolder font-sans-serif m-0">
                      E-postadress
                    </p>
                    <p className="m-0">{me.email || '-'}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="col col-5 bg-white p-15 mb-15">
            <h2 className="display-4 mb-1">Min verkstad</h2>
            <div className="d-flex flex-column mb-1">
              <h3 className="text-black mb-1">{my_service_station!.name}</h3>
              <div className="contact-data">
                <div className="py-05">
                  <p className="font-weight-bolder font-sans-serif m-0">
                    E-postadress
                  </p>
                  <p className="m-0">
                    {my_service_station!.user?.email || '-'}
                  </p>
                </div>
                <div className="w-100 py-05">
                  <p className="font-weight-bolder font-sans-serif m-0">
                    Postadress
                  </p>
                  <p className="m-0">{my_service_station!.street}</p>
                  <p className="m-0">
                    {my_service_station!.postal} {my_service_station!.city}
                  </p>
                </div>
              </div>
            </div>
            <Checkbox
              label="Uppställningsplats"
              checked={my_service_station!.is_staging_area}
              readOnly
            />
          </div>
        )}
      </div>
    </div>
  );
});
