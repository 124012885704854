import { Instance, types as t } from 'mobx-state-tree';
import { DamageConditionType, IsoDate } from '../types';
import { Bid } from './Bid';

const Files = t.model('Files', {
  id: t.identifier,
  name: t.string,
  file: t.string,
});

const Images = t.model('Images', {
  id: t.identifier,
  image: t.string,
  thumb: t.string,
});

export const Auction = t
  .model('Auction', {
    id: t.identifier,
    number: t.string,
    start_dt: t.maybeNull(IsoDate),
    end_dt: t.maybeNull(IsoDate),
    description: t.maybeNull(t.string),
    manufacturer: t.string,
    model: t.maybeNull(t.string),
    model_year: t.maybeNull(t.string),
    mileage: t.maybeNull(t.number),
    registration_number: t.maybeNull(t.string),
    chassis_number: t.maybeNull(t.string),
    reservation_price: t.maybeNull(t.number),
    hidden_reservation_price: t.boolean,
    start_bid: t.maybeNull(t.number),
    hidden_bids: t.boolean,
    is_global: t.boolean,
    damage_condition: t.enumeration<DamageConditionType>(
      Object.values(DamageConditionType),
    ),
    category: t.maybeNull(t.string),
    category_name: t.maybeNull(t.string),
    staging_area: t.maybeNull(t.string),
    staging_area_name: t.maybeNull(t.string),
    company_name: t.maybeNull(t.string),
    bid_count: t.number,
    highest_bid: t.maybeNull(t.number),
    minimum_allowed_bid: t.maybeNull(t.number),
    image: t.maybeNull(t.string),
    thumb: t.maybeNull(t.string),
    files: t.array(Files),
    additional_images: t.maybeNull(t.array(Images)),
    has_active_alert: t.maybeNull(t.boolean),
    is_active: t.boolean,
    has_started: t.boolean,
    winning_bid: t.maybeNull(Bid),
    my_bid: t.maybeNull(t.number),
    has_notified_winner: t.maybeNull(t.boolean),
  })
  .views((self) => ({
    get pickup_date(): Date | null {
      return self.end_dt
        ? new Date(self.end_dt.getTime() + 1000 * 60 * 60 * 24 * 10)
        : null;
    },
  }))
  .actions((self) => ({
    handleNewBidMessage: (message: string) => {
      const data = JSON.parse(message);
      const { bid, min_bid, end_dt } = data;

      if (bid && min_bid && end_dt) {
        self.highest_bid = bid;
        self.minimum_allowed_bid = min_bid;
        self.end_dt = new Date(end_dt);
      }
    },
  }));

export type AuctionInstance = Instance<typeof Auction>;
