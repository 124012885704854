import { applySnapshot, types as t } from 'mobx-state-tree';

export const UIStore = t
  .model('UIStore', {
    headerHeight: 0,
    userMenuIsOpen: false,
  })
  .volatile(() => ({
    isFilterOpen: false,
    isOrderingOpen: false,
  }))
  .actions((self) => ({
    setHeaderHeight(height: number) {
      self.headerHeight = height;
    },
    setUserMenuIsOpen(isOpen: boolean) {
      self.userMenuIsOpen = isOpen;
    },
    toggleFilterOpen() {
      self.isFilterOpen = !self.isFilterOpen;
    },
    setOrderingIsOpen(isOpen: boolean) {
      self.isOrderingOpen = isOpen;
    },
    reset() {
      applySnapshot(self, {
        userMenuIsOpen: false,
      });
    },
  }));
