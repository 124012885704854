import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumbs,
  Datepicker,
  Input,
  LinkButton,
  Modal,
  PrimaryButton,
  Select,
  Spinner,
} from '../components';
import { useStore } from '../hooks';
import { Role } from '../types';
import { formatCurrency } from '../utils/formatCurrency';
import { formatDate } from '../utils/formatDate';
import { useDetectClickOutside } from 'react-detect-click-outside';
import classNames from 'classnames';
import {
  AuctionsOrdering,
  AUCTIONS_ORDERING_TEXTS,
} from '../types/AuctionOrdering';
import { BellIcon } from '../icons';
import { AuctionInstance } from '../models';

interface Props {
  page?: 'my' | 'won';
}

export const AuctionsPage: FC<Props> = observer(({ page }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    companyStore: { companyOptions, categoryOptions },
    auctionStore,
    userStore: { me },
    uiStore,
    authStore,
  } = useStore();

  const ref = useDetectClickOutside({
    onTriggered: () => uiStore.setOrderingIsOpen(false),
  });

  const searchParams = new URLSearchParams(location.search);
  const { handleSubmit, register, setValue, reset } = useForm({
    values: {
      number: searchParams.get('number'),
      category: searchParams.get('category'),
      registration_number: searchParams.get('registration_number'),
      chassis_number: searchParams.get('chassis_number'),
      manufacturer: searchParams.get('manufacturer'),
      buyer: searchParams.get('buyer'),
      start_dt: searchParams.get('start_dt'),
      end_dt: searchParams.get('end_dt'),
      is_active: searchParams.get('is_active') || '',
      has_started: searchParams.get('has_started'),
    },
  });

  useEffect(() => {
    if (page === 'won') return;

    const ws = new WebSocket(`${process.env.WEBSOCKET_URL}/ws/auction-list/`);
    ws.onopen = () => {
      // It's not possible to add any auth header in a websocket connection,
      // so when the connection is open we send the token to the server to check if it's valid.
      ws.send(JSON.stringify({ token: authStore.access_token }));
    };
    ws.onmessage = (event) => auctionStore.handleNewBidMessage(event.data);

    return () => {
      if (ws.readyState === WebSocket.OPEN) ws.close();
    };
  }, []);

  const fetchAuctions = (nextPage?: boolean) => {
    switch (page) {
      case 'my':
        return auctionStore.fetchMyAuctions(nextPage);

      case 'won':
        return auctionStore.fetchWonAuctions(nextPage);

      default:
        return auctionStore.fetchAuctions(nextPage);
    }
  };

  const handleFilter = handleSubmit((filters: any) => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(filters).forEach((filter: any) => {
      if (searchParams.has(filter)) {
        searchParams.delete(filter);
      }
      if (filters[filter]) {
        searchParams.append(filter, filters[filter]);
      }
    });

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    fetchAuctions();
  });

  const setOrdering = (newSort: string) => {
    const searchParams = new URLSearchParams(location.search);

    if (auctionsOrdering === newSort) {
      if (auctionsIsAscending) {
        searchParams.delete('ordering');
      } else {
        searchParams.set('ordering', auctionsOrdering);
      }
    } else {
      searchParams.set('ordering', `-${newSort}`);
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);

    uiStore.setOrderingIsOpen(false);
    fetchAuctions();
  };

  const resetOrdering = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('ordering');

    navigate(`${location.pathname}?${searchParams.toString()}`);

    fetchAuctions();
  };

  const toggleAuctionAlert = (auction: AuctionInstance) => {
    if (auction.has_active_alert) {
      auctionStore.deleteAuctionAlert(auction.id);
    } else {
      auctionStore.createAuctionAlert(auction.id);
    }

    fetchAuctions();
  };

  const state = useLocalObservable<{
    isFetchingMore: boolean;
    resetFilter: () => void;
  }>(() => ({
    isFetchingMore: false,
    resetFilter() {
      navigate(`${location.pathname}`, { replace: true });
      reset();
      fetchAuctions();
    },
  }));

  const fetchMore = () => {
    if (state.isFetchingMore) return;

    state.isFetchingMore = true;
    fetchAuctions(true).then(() => (state.isFetchingMore = false));
  };

  useEffect(() => {
    fetchAuctions();
  }, [page]);

  useLayoutEffect(() => {
    auctionStore.handleAuctionsSearchChange(location.search);
  }, [location.search]);

  const { hasMore, auctionsOrdering, auctionsIsAscending } = auctionStore;

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
  ];

  if (page == 'my' || page == 'won') {
    breadcrumbs.push({
      title: 'Auktioner',
      href: '/auctions',
    });
  }

  const pageTitle: string = (() => {
    switch (page) {
      case 'my':
        return 'Mina auktioner';

      case 'won':
        return 'Vunna auktioner';

      default:
        return 'Auktioner';
    }
  })();

  const auctions: AuctionInstance[] | null = (() => {
    switch (page) {
      case 'my':
        return auctionStore.myAuctions;

      case 'won':
        return auctionStore.wonAuctions;

      default:
        return auctionStore.auctions;
    }
  })();

  if (auctions === null || me === null) return null;

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} active={pageTitle} />
      <h1 className="display-2 mt-15">{pageTitle}</h1>
      <div className="pt-2">
        {(me.role >= Role.CaseManager || me.is_superuser) && (
          <div className="bg-white p-15 mb-15">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h2 className="text-blue">Nya auktioner som väntar?</h2>
                <p className="m-0">Det löser du snabbt och enkelt.</p>
              </div>
              <PrimaryButton onClick={() => navigate('/auctions/new')} arrow>
                Lägg till auktion
              </PrimaryButton>
            </div>
          </div>
        )}
        {me.is_superuser && (
          <div className="bg-white p-15 mb-025">
            <div className="row">
              <div className="col">
                <Select
                  label="Bolag"
                  options={[
                    { value: '', title: 'Alla bolag' },
                    ...companyOptions,
                  ]}
                  onChange={(e) => auctionStore.setCompany(e.target.value)}
                  withoutMargin
                />
              </div>
              <div className="col"></div>
            </div>
          </div>
        )}
        <div className="d-flex align-items-center bg-white px-15 py-08 mb-025">
          <div
            role="button"
            className="d-inline-flex align-items-center text-blue"
            onClick={() => uiStore.toggleFilterOpen()}
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-08"
            >
              <rect width="18" height="2" fill="#005AA0" />
              <rect x="3" y="6" width="12" height="2" fill="#005AA0" />
              <rect x="6" y="12" width="6" height="2" fill="#005AA0" />
            </svg>
            Filtrera
          </div>
          <div
            className="auction-ordering-wrapper d-inline-flex align-items-center text-blue ml-15 position-relative"
            ref={ref}
          >
            <div
              onClick={() => uiStore.setOrderingIsOpen(!uiStore.isOrderingOpen)}
              role="button"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.5999 8.01361L14.1859 6.59961L10.1869 10.5996L6.18591 6.59961L4.77191 8.01361L8.77191 12.0146L10.1869 13.4276L11.5999 12.0146L15.5999 8.01361Z"
                  fill="#005AA0"
                  className="mr-08"
                />
              </svg>
              Sortera
            </div>
            {uiStore.isOrderingOpen && (
              <div className="d-flex flex-column bg-white position-absolute auction-order-dropdown">
                {Object.values(AuctionsOrdering).map((ordering) => (
                  <span
                    key={ordering}
                    role="button"
                    onClick={() => setOrdering(ordering)}
                  >
                    {AUCTIONS_ORDERING_TEXTS[ordering]}
                  </span>
                ))}
              </div>
            )}
            {auctionStore.auctionCurrentOrder && (
              <span className="d-flex align-items-center rounded border border-primary ml-15 px-025">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => resetOrdering()}
                  role="button"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 16.875C6.20937 16.875 3.125 13.7906 3.125 10C3.125 6.20937 6.20937 3.125 10 3.125C13.7906 3.125 16.875 6.20937 16.875 10C16.875 13.7906 13.7906 16.875 10 16.875ZM10 1.875C5.52 1.875 1.875 5.52 1.875 10C1.875 14.48 5.52 18.125 10 18.125C14.48 18.125 18.125 14.48 18.125 10C18.125 5.52 14.48 1.875 10 1.875Z"
                    fill="#005AA0"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7277 6.38856L10.0002 9.11606L7.27269 6.38856L6.38894 7.27231L9.11644 9.99981L6.38206 12.7342L7.26581 13.6179L10.0002 10.8836L12.7277 13.6111L13.6114 12.7273L10.8839 9.99981L13.6114 7.27231L12.7277 6.38856Z"
                    fill="#005AA0"
                  />
                </svg>
                <span className="text-blue mx-025">
                  {
                    AUCTIONS_ORDERING_TEXTS[
                      auctionStore.auctionCurrentOrder as AuctionsOrdering
                    ]
                  }
                </span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames({
                    descending: !auctionsIsAscending,
                  })}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5999 8.01361L14.1859 6.59961L10.1869 10.5996L6.18591 6.59961L4.77191 8.01361L8.77191 12.0146L10.1869 13.4276L11.5999 12.0146L15.5999 8.01361Z"
                    fill="#005AA0"
                  />
                </svg>
              </span>
            )}
          </div>
        </div>
        {uiStore.isFilterOpen && (
          <form onSubmit={handleFilter} className="bg-white p-15 mb-025">
            <div className="row">
              {(me.role >= Role.CaseManager || me.is_superuser) && (
                <>
                  <div className="col col-6">
                    <Select
                      label="Status"
                      placeholder="Alla"
                      options={[
                        { value: '', title: 'Alla' },
                        { value: '1', title: 'Aktiv' },
                        { value: 'has_started', title: 'Kommande' },
                        { value: '0', title: 'Avslutad' },
                      ]}
                      {...register('is_active')}
                    />
                  </div>
                  <div className="col col-6">
                    <Select
                      label="Kategori"
                      placeholder="Välj kategori"
                      options={[
                        { value: '', title: 'Välj kategori' },
                        ...categoryOptions,
                      ]}
                      {...register('category')}
                    />
                  </div>
                  <div className="col col-6">
                    <Datepicker
                      label="Startdatum"
                      id="start_dt"
                      setValue={setValue}
                      {...register('start_dt')}
                    />
                  </div>
                  <div className="col col-6">
                    <Datepicker
                      label="Slutdatum"
                      id="end_dt"
                      setValue={setValue}
                      {...register('end_dt')}
                    />
                  </div>
                  <div className="col col-6">
                    <Input label="Köpare" type="text" {...register('buyer')} />
                  </div>
                </>
              )}
              <div className="col col-6">
                <Input
                  label="Fabrikat"
                  type="text"
                  {...register('manufacturer')}
                />
              </div>
              <div className="col col-6">
                <Input
                  label="Reg.nr"
                  type="text"
                  reg
                  {...register('registration_number')}
                />
              </div>
              <div className="col col-6">
                <Input
                  label="Chassi.nr"
                  type="text"
                  {...register('chassis_number')}
                />
              </div>
              <div className="col col-6">
                <Input
                  label="Objektsnummer"
                  type="text"
                  {...register('number')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col d-flex align-items-center text-blue">
                <a
                  role="button"
                  className="text-decoration-underline mr-15"
                  onClick={() => uiStore.toggleFilterOpen()}
                >
                  Stäng
                </a>
                <a
                  role="button"
                  className="text-decoration-underline"
                  onClick={() => state.resetFilter()}
                >
                  Rensa
                </a>
                <PrimaryButton className="ml-auto">Filtrera</PrimaryButton>
              </div>
            </div>
          </form>
        )}
        {auctions.length !== 0 ? (
          <>
            {auctions.map((auction) => (
              <div key={auction.id} className="position-relative">
                {me.role === Role.ServiceStation && page !== 'won' && (
                  <div
                    className={classNames('alert-auction', {
                      active: auction.has_active_alert,
                    })}
                    data-bs-toggle="modal"
                    data-bs-target={
                      auction.has_active_alert
                        ? '#deleteAlertModal'
                        : '#addAlertModal'
                    }
                    onClick={() => toggleAuctionAlert(auction)}
                  >
                    <BellIcon />
                  </div>
                )}
                <Link
                  className="d-flex bg-white cursor-pointer text-decoration-none mb-025"
                  to={`/auctions/${auction.id}`}
                >
                  <div className="position-relative flex-shrink-0">
                    <img
                      src={
                        auction.thumb || require('../assets/placeholder.jpeg')
                      }
                      style={{
                        width: 333,
                        height: 250,
                        objectFit: 'cover',
                      }}
                    />
                    {me.role >= Role.CaseManager && (
                      <div
                        className={classNames(
                          'position-absolute font-weight-bold rounded px-08 m-15',
                          {
                            'bg-orange text-black': auction.is_active,
                            'bg-green text-white':
                              !auction.is_active && auction.has_started,
                            'bg-yellow text-black':
                              !auction.is_active && !auction.has_started,
                          },
                        )}
                        style={{ bottom: 0 }}
                      >
                        {auction.is_active
                          ? 'Aktiv'
                          : auction.has_started
                          ? 'Avslutad'
                          : 'Kommande'}
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column justify-content-between w-100 p-4">
                    <h4>
                      {auction.manufacturer} {auction.model}
                    </h4>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column flex-grow-1 flex-shrink-1 auction-info-col pr-025">
                        <p className="text-gray text-sm mb-025 text-nowrap">
                          {auction.registration_number ? 'Reg.nr' : 'Chassi.nr'}
                        </p>
                        <p className="mb-0 overflow-hidden">
                          {auction.registration_number ||
                            auction.chassis_number}
                        </p>
                      </div>
                      <div className="d-flex flex-column flex-grow-1 flex-shrink-1 auction-info-col px-025">
                        <p className="text-gray text-sm mb-025 text-nowrap">
                          Fabrikat
                        </p>
                        <p className="mb-0 overflow-hidden">
                          {auction.manufacturer}
                        </p>
                      </div>
                      <div className="d-flex flex-column flex-grow-1 flex-shrink-1 auction-info-col px-025">
                        <p className="text-gray text-sm mb-025 text-nowrap">
                          Modell
                        </p>
                        <p className="mb-0 overflow-hidden">
                          {auction.model || '-'}
                        </p>
                      </div>
                      {page !== 'my' && (
                        <div className="d-flex flex-column flex-grow-1 flex-shrink-1 auction-info-col px-025">
                          <p className="text-gray text-sm mb-025 text-nowrap">
                            Uppställningsplats
                          </p>
                          <p className="mb-0 overflow-hidden">
                            {auction.staging_area_name || '-'}
                          </p>
                        </div>
                      )}
                      {page !== 'won' && (
                        <div className="d-flex flex-column flex-grow-1 flex-shrink-1 px-025">
                          <p className="text-gray text-sm mb-025 text-nowrap">
                            Slutar
                          </p>
                          <p className="mb-0 text-nowrap">
                            {(auction.end_dt &&
                              formatDate(auction.end_dt).date) ||
                              '-'}
                          </p>
                        </div>
                      )}
                      {page !== 'my' && page !== 'won' && (
                        <div className="d-flex flex-column flex-grow-1 flex-shrink-1 pl-025">
                          <p className="text-right text-gray text-sm mb-025 text-nowrap">
                            Högsta bud
                          </p>
                          <p
                            className={classNames(
                              'text-right mb-0 text-nowrap',
                              {
                                'text-gray': auction.hidden_bids,
                              },
                            )}
                          >
                            {auction.hidden_bids
                              ? 'Dold budgivning'
                              : formatCurrency(auction.highest_bid || 0)}
                          </p>
                        </div>
                      )}
                      {page === 'my' && (
                        <div className="d-flex flex-row">
                          <div
                            className={classNames(
                              'my-auctions-bid-container d-flex flex-column rounded text-right px-1 py-05 mr-1',
                              {
                                'border-0':
                                  auction.my_bid === null ||
                                  auction.hidden_bids,
                                'border-success':
                                  auction.my_bid === auction.highest_bid &&
                                  auction.my_bid !== null &&
                                  !auction.hidden_bids,
                                'border-danger':
                                  auction.my_bid !== auction.highest_bid &&
                                  auction.my_bid !== null &&
                                  !auction.hidden_bids,
                              },
                            )}
                          >
                            <p className="text-gray text-sm mb-025">Mitt bud</p>
                            <p className="font-weight-bold text-black mb-0">
                              {auction.my_bid
                                ? formatCurrency(auction.my_bid || 0)
                                : '-'}
                            </p>
                          </div>
                          <div
                            className={classNames(
                              'my-auctions-bid-container d-flex flex-column rounded text-right px-1 py-05',
                              {
                                'border-0':
                                  auction.my_bid === null ||
                                  auction.hidden_bids,
                                'border-success':
                                  auction.my_bid === auction.highest_bid &&
                                  auction.my_bid !== null,
                                'bg-success-light':
                                  auction.my_bid === auction.highest_bid &&
                                  auction.my_bid !== null,
                                'border-danger':
                                  auction.my_bid !== auction.highest_bid &&
                                  auction.my_bid !== null &&
                                  !auction.hidden_bids,
                                'bg-danger-light':
                                  auction.my_bid !== auction.highest_bid &&
                                  auction.my_bid !== null &&
                                  !auction.hidden_bids,
                              },
                            )}
                          >
                            <p className="text-gray text-sm mb-025">
                              Ledande bud
                            </p>
                            <p className="font-weight-bold text-black mb-0">
                              {auction.hidden_bids
                                ? 'Dold'
                                : formatCurrency(auction.highest_bid || 0)}
                            </p>
                          </div>
                        </div>
                      )}
                      {page === 'won' && (
                        <>
                          <div className="d-flex flex-column text-right">
                            <p className="text-gray text-sm mb-025">
                              Upphämtas senast
                            </p>
                            <p className="mb-0">
                              {(auction.pickup_date &&
                                formatDate(auction.pickup_date).date) ||
                                '-'}
                            </p>
                          </div>
                          <div className="d-flex flex-column text-right">
                            <p className="text-gray text-sm mb-025">
                              Vinnande bud
                            </p>
                            <p className="mb-0">
                              {formatCurrency(auction.winning_bid?.bid ?? 0)}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
            {hasMore && (
              <div className="bg-white text-center p-1">
                {state.isFetchingMore ? (
                  <Spinner size="tiny" />
                ) : (
                  <span
                    className="load-more-link"
                    role="button"
                    onClick={fetchMore}
                  >
                    Visa fler
                  </span>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="bg-white p-15">
            <h2 className="text-blue">Inga auktioner</h2>
            <p>Det verkar inte finnas några auktioner att visa här.</p>
          </div>
        )}
        {me.role === Role.ServiceStation && (
          <>
            <Modal
              id="addAlertModal"
              title="Bevakning aktiverad"
              footer={<LinkButton data-bs-dismiss="modal">Stäng</LinkButton>}
            >
              <p>
                Du kommer att få notiser via mail för alla nya bud och när
                auktionen avslutas.
              </p>
            </Modal>
            <Modal
              id="deleteAlertModal"
              title="Bevakning inaktiverad"
              footer={<LinkButton data-bs-dismiss="modal">Stäng</LinkButton>}
            >
              <p>Du kommer inte längre få notiser om denna auktion.</p>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
});
