import React, {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useId,
} from 'react';
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode | string;
  error?: string | false;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ label, error, ...rest }, ref) => {
    const id = useId();

    return (
      <fieldset className={classNames('mb-1', { 'has-danger': error })}>
        <div className="custom-control custom-checkbox mr-1">
          <input
            id={id}
            type="checkbox"
            className="custom-control-input"
            ref={ref}
            {...rest}
          />
          <label htmlFor={id} className="custom-control-label">
            {label}
          </label>
        </div>
        {error && <span className="form-control-feedback">{error}</span>}
      </fieldset>
    );
  },
);
