/**
 * Formats a given number to a formatted currency string
 */
export function formatCurrency(n: number): string {
  return new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0,
  }).format(n);
}
