import React, { FC } from 'react';
import classNames from 'classnames';

interface Props {
  size?: 'small' | 'tiny';
}

export const Spinner: FC<Props> = ({ size }) => {
  return (
    <div
      className={classNames({
        'lf-spinner': !size,
        'lf-spinner-sm': size === 'small',
        'lf-spinner-xs': size === 'tiny',
      })}
    >
      <svg viewBox="0 0 105 105" x="0" y="0" role="animation">
        <circle className="static-circle" r="45%" cx="50%" cy="50%"></circle>
        <circle className="lf-spinner-dash" r="45%" cx="50%" cy="50%"></circle>
        <line
          className="lf-spinner-back-line"
          x1="50%"
          y1="50%"
          x2="100%"
          y2="50%"
        ></line>
        <line
          className="lf-spinner-forward-line"
          x1="50%"
          y1="50%"
          x2="100%"
          y2="50%"
        ></line>
      </svg>
    </div>
  );
};
