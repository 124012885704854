import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Breadcrumbs, Checkbox, PrimaryButton } from '../components';
import { useStore } from '../hooks';

type FormData = {
  silent_category_ids: string[];
};

export const ServiceStationSettingsPage = observer(() => {
  const {
    serviceStationStore: {
      my_service_station,
      updateServiceStationSilentCategories,
    },
    companyStore: { categoryOptions },
  } = useStore();

  const state = useLocalObservable(() => ({
    isSubmiting: false,
    submitSuccess: false,
    submitError: false,
  }));

  const { setValue, getValues, handleSubmit } = useForm<FormData>();

  const onSubmit = handleSubmit(async ({ ...data }) => {
    if (state.isSubmiting || my_service_station === null) return;

    state.isSubmiting = true;
    state.submitSuccess = false;
    state.submitError = false;

    try {
      await updateServiceStationSilentCategories(data.silent_category_ids);

      state.submitSuccess = true;
    } catch (error: any) {
      state.submitError = true;
    } finally {
      state.isSubmiting = false;
      window.scrollTo(0, 0);
    }
  });

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
  ];

  useEffect(() => {
    if (my_service_station !== null) {
      setValue(
        'silent_category_ids',
        my_service_station.silent_categories.map((c) => c.id),
      );
    }
  }, [my_service_station]);

  if (my_service_station === null) return null;

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} active="Inställningar" />
      <h1 className="display-2 mt-15">Inställningar</h1>
      <div className="pt-2">
        <form onSubmit={onSubmit}>
          <div className="bg-white p-2">
            {state.submitSuccess && (
              <div className="alert alert-success" role="alert">
                <div className="alert-content">
                  Inställningarna har uppdaterats.
                </div>
              </div>
            )}
            {state.submitError && (
              <div className="alert alert-danger" role="alert">
                <div className="alert-content">
                  Ett fel inträffade när inställningarna skulle uppdateras.
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-blue mt-0 mb-1">
                  Välj kategorier att följa
                </h2>
              </div>
              <div className="col col-12">
                <div className="category-container">
                  {categoryOptions.map((category, index) => (
                    <Checkbox
                      key={index}
                      label={category.title}
                      defaultChecked={
                        my_service_station.silent_categories.filter(
                          (c) => c.id === category.value,
                        ).length == 0
                      }
                      onChange={(e) => {
                        let silent_categories = getValues(
                          'silent_category_ids',
                        );

                        if (e.target.checked) {
                          silent_categories = silent_categories.filter(
                            (c) => c !== category.value,
                          );
                        } else {
                          silent_categories.push(category.value);
                        }

                        setValue('silent_category_ids', silent_categories);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end bg-white p-15 mt-025">
            <PrimaryButton loading={state.isSubmiting} arrow>
              Spara
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
});
